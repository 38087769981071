import React, {Component} from "react";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {
  CELL_TYPES,
  INGREDIENT_USAGE_COLUMNS,
} from "../../features/reporting/reports/reporting-constants";
import ReportingGraphContainer from "../../features/reporting/graphs/reporting-graph-container";
import {setupReduxConnection} from "../../redux";
import ReportingIngredientUsageGraph from "../../features/reporting/graphs/reporting-ingredient-usage-graph";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import SingleReportingTooltip from "../../features/reporting/graphs/tooltips/single-reporting-tooltip";
import SupplyComingSoonPage from "../operations/supply-chain/supply-coming-soon-page";
import {isSupplyChainWhitelisted} from "../../utils/util";

class IngredientUsageReportPage extends Component {
  convertDataToRows = (reportData) => {
    const {NUMBERS: numbers, TOTALS: totals} = reportData;

    const rows = numbers.map((row) => {
      return {
        label: (
          <div className="text-left">
            <div className="font-semibold">{row.INGREDIENT_NAME}</div>

            <div className="text-gray-500">{row.UNIT_NAME}</div>
          </div>
        ),
        numbers: row.TIMESPAN,
        style: CELL_TYPES.REGULAR,
        expandCell: (row) => {
          const nodes = [];
          const links = [];

          if (row.numbers.COGS > 0 || row.numbers.WASTE > 0) {
            nodes.push({name: "Usage"});
          }

          if (row.numbers.COGS > 0) {
            nodes.push({name: "Goods Sold"});
            links.push({source: 0, target: 1, value: row.numbers.COGS});
          }

          if (row.numbers.WASTE > 0) {
            nodes.push({name: "Waste"});

            links.push({source: 0, target: nodes.length - 1, value: row.numbers.WASTE});

            links.push(
              ...Object.values(row.numbers.WASTE_TYPES).map((type, i) => ({
                source: nodes.length - 1,
                target: i + nodes.length,
                value: type.COST,
              }))
            );

            nodes.push(
              ...Object.values(row.numbers.WASTE_TYPES).map((type, i) => ({
                name: type.WASTE_TYPE_NAME,
              }))
            );
          }

          if (nodes.length === 0) {
            return <div />;
          }

          return (
            <div className="flex justify-center items-center">
              <ReportingIngredientUsageGraph
                data={{
                  nodes,
                  links,
                }}
                tooltip={
                  <SingleReportingTooltip
                    formatLabel={(payload) => {
                      return payload[`baseDate`];
                    }}
                    rows={[
                      {
                        label: "Cost",
                        formatValue: ({payload}) => {
                          if (!payload.target) {
                            return "-";
                          }

                          return toDollars(payload.target.value, true);
                        },
                      },
                      {
                        label: "Percentage",
                        formatValue: ({payload}) => {
                          if (!payload.source || !payload.target) {
                            return "-";
                          }

                          return (
                            ((payload.target.value / payload.source.value) * 100).toFixed(
                              2
                            ) + "%"
                          );
                        },
                      },
                    ]}
                  />
                }
              />
            </div>
          );
        },
      };
    });

    rows.push({
      label: "Total",
      numbers: totals.TIMESPAN,
      style: CELL_TYPES.BOLD,
    });

    return rows;
  };

  renderUsageGraph(reportData) {
    const nodes = [];
    const links = [];

    if (reportData.TOTALS.TIMESPAN.COGS > 0 || reportData.TOTALS.TIMESPAN.WASTE > 0) {
      nodes.push({name: "Usage"});
    }

    if (reportData.TOTALS.TIMESPAN.COGS > 0) {
      nodes.push({name: "Goods Sold"});
      links.push({source: 0, target: 1, value: reportData.TOTALS.TIMESPAN.COGS});
    }

    if (reportData.TOTALS.TIMESPAN.WASTE > 0) {
      nodes.push({name: "Waste"});

      links.push({
        source: 0,
        target: nodes.length - 1,
        value: reportData.TOTALS.TIMESPAN.WASTE,
      });

      links.push(
        ...Object.values(reportData.TOTALS.TIMESPAN.WASTE_TYPES)
          .filter((type) => type.COST > 0)
          .map((type, i) => ({
            source: nodes.length - 1,
            target: i + nodes.length,
            value: type.COST,
          }))
      );

      nodes.push(
        ...Object.values(reportData.TOTALS.TIMESPAN.WASTE_TYPES).map((type, i) => ({
          name: type.WASTE_TYPE_NAME,
        }))
      );
    }

    if (nodes.length === 0) {
      return <div />;
    }

    return (
      <div>
        <ReportingIngredientUsageGraph
          data={{
            nodes,
            links,
          }}
          tooltip={
            <SingleReportingTooltip
              formatLabel={(payload) => {
                return payload[`baseDate`];
              }}
              rows={[
                {
                  label: "Cost",
                  formatValue: ({payload}) => {
                    if (!payload.target) {
                      return "-";
                    }

                    return toDollars(payload.target.value, true);
                  },
                },
                {
                  label: "Percentage",
                  formatValue: ({payload}) => {
                    if (!payload.source || !payload.target) {
                      return "-";
                    }

                    return (
                      ((payload.target.value / payload.source.value) * 100).toFixed(2) +
                      "%"
                    );
                  },
                },
              ]}
            />
          }
        />
      </div>
    );
  }

  renderGraph(reportData) {
    return (
      <div>
        <ReportingGraphContainer
          label="Ingredient Waste"
          className="mb-4"
          graph={this.renderUsageGraph(reportData)}
        />
      </div>
    );
  }

  render() {
    if (!isSupplyChainWhitelisted()) {
      return <SupplyComingSoonPage />;
    }

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label="Ingredient Usage Report" />
        </div>

        <FixedColumnReport
          filenamePrefix="cost-of-goods"
          endpoint="report/ingredient-usage"
          constantColumns={INGREDIENT_USAGE_COLUMNS}
          convertDataToRows={this.convertDataToRows}
          expandable={true}
          graph={(reportData) => this.renderGraph(reportData)}
          locationPicker
          firstColLabel="Ingredient Name"
          firstColTooltip="This column lists the names of all ingredients that have been used during the selected time period."
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "reporting"])(IngredientUsageReportPage);
