import React, {Component} from "react";
import {Formik} from "formik";
import {FormInput, Modal, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import * as Yup from "yup";
import LocationsDropdown from "../../../../dropdowns/team/locations-dropdown";
import IngredientDropdown from "../../../../dropdowns/operations/recipes/ingredient-dropdown";
import UnitDropdown from "../../../../dropdowns/operations/recipes/unit-dropdown";
import {setupReduxConnection} from "../../../../redux";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../utils/notification-helper";
import {request} from "../../../../utils/request";
import {parseIdDict} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class RequestTransferModal extends Component {
  state = {ingredient: null};

  open() {
    this.setState({ingredient: null}, () => this.modal.open());
  }

  requestIngredient({ingredient, quantity, unit, supplierLocations}) {
    request("inventory-transfers/items/requests", "POST", {
      INGREDIENTS: [
        {INGREDIENT_ID: ingredient, REQUESTED_QUANTITY: quantity, UNIT_ID: unit},
      ],
      SUPPLIER_LOCATIONS: supplierLocations,
    })
      .then((data) => {
        showSuccessNotification("Successfully submitted request.");
        this.modal && this.modal.close();
      })
      .catch((err) => {
        showErrorNotification(
          "Something went wrong.",
          "Something went wrong when requesting the ingredient. Please try again."
        );
      });
  }

  render() {
    const {companyLocations: locations, location} = this.props.shop;
    const {ingredient} = this.state;
    const locationsDict = parseIdDict(locations);

    return (
      <Modal
        buttonLabel="Request"
        label="Request Transfer Items"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        xlarge
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            ingredient: null,
            quantity: 1,
            unit: null,
            supplierLocations: locations
              .filter((l) => l.ID !== location.ID)
              .map((l) => l.ID),
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={(values) => this.requestIngredient(values)}
        >
          {(formikOptions) => {
            const {setFieldValue} = formikOptions;

            return (
              <form>
                <LocationsDropdown
                  label="Supplier Locations"
                  options={formikOptions}
                  name="supplierLocations"
                  isCompany
                  exclude={[location.ID]}
                  multi
                />

                <IngredientDropdown
                  options={formikOptions}
                  name="ingredient"
                  label="Ingredient"
                  onChangeSoft={({ingredient}) => {
                    setFieldValue("unit", ingredient.UNIT_ID);
                    this.setState({ingredient});
                  }}
                />

                {!!ingredient && (
                  <Table
                    hideBorder
                    white
                    className="mt-2"
                    data={
                      ingredient?.LOCATION_COUNTS.filter(
                        (l) => l.LOCATION_ID !== location.ID
                      ) ?? []
                    }
                    columns={[
                      {
                        value: "LOCATION_ID",
                        label: "Location",
                        format: (locationId) => locationsDict[locationId].NAME,
                      },
                      {
                        value: "DISPLAY_STOCK",
                        label: "Current Stock",
                      },
                      {
                        value: "",
                        label: "Unit",
                        format: () => ingredient?.DISPLAY_UNIT.NAME,
                      },
                    ]}
                  />
                )}

                <FormInput name="quantity" label="Quantity" options={formikOptions} />

                <UnitDropdown
                  label="Unit"
                  options={formikOptions}
                  name="unit"
                  unit={ingredient?.UNIT_ID}
                  ingredient={ingredient}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(RequestTransferModal);
