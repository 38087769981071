import React, {Component} from "react";
import {FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import {showErrorAlert} from "../../../utils/alert-helper";
import {showErrorNotification} from "../../../utils/notification-helper";
import {TEXT_CAMPAIGN_TYPES} from "../../../utils/marketing-constants";

class EmailCampaignModal extends Component {
  state = {campaign: null};

  open(campaign = null) {
    this.setState({campaign}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async createCampaign({name, subject, listId}) {
    let serverCampaign;

    try {
      serverCampaign = await request("marketing/campaigns/email", "POST", {
        SUBJECT: subject,
        NAME: name,
        LIST_ID: listId,
      });
    } catch (err) {
      this.modal.close();

      return showErrorNotification(
        "Create Campaign Failure",
        "Please make sure you have patrons."
      );
    }

    this.props.addState(serverCampaign);
    this.modal.close();
  }

  async saveCampaign({name, subject, listId}) {
    const {campaign} = this.state;

    const serverCampaign = await request(
      "marketing/campaigns/email/" + campaign.ID,
      "PATCH",
      {
        NAME: name,
        SUBJECT: subject,
        LIST_ID: listId,
      }
    );

    this.props.updateState(serverCampaign);
    this.modal.close();
  }

  render() {
    const {campaign} = this.state;
    const {subLists} = this.props;

    const formSublists = subLists.map((list) => ({
      label: list.NAME,
      value: list.ID,
    }));

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        buttonLabel={campaign ? "Save" : "Create"}
        formikOnClick={() => this.formikRef}
        label={campaign ? "Edit Email Campaign" : "Create Email Campaign"}
      >
        <Formik
          onSubmit={
            campaign ? this.saveCampaign.bind(this) : this.createCampaign.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: campaign?.NAME ?? "",
            subject: campaign?.SUBJECT ?? null,
            listId: campaign?.LIST_ID ?? null,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput name="name" label="Name" options={formikOptions} />

                <FormInput options={formikOptions} label="Subject" name="subject" />

                <FormSelect
                  data={formSublists}
                  options={formikOptions}
                  label="Subscriber List"
                  name="listId"
                  tooltip={
                    "Select Default Mail List for all patrons that have opted into e-mail marketing"
                  }
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default EmailCampaignModal;
