import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import RoleDropdown from "../../../dropdowns/team/role-dropdown";
import * as Yup from "yup";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../utils/notification-helper";
import {showErrorAlert} from "../../../utils/alert-helper";

class RemoveRoleModal extends Component {
  state = {role: null};

  open(role = null) {
    this.setState({role}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async removeRole({role: replacementRole}) {
    const {role} = this.state;
    const {hasActiveEmployees = false} = this.props;

    if (!replacementRole && hasActiveEmployees) {
      return showErrorAlert(
        "Error Deleting Role",
        "Please make sure to select a replacement role."
      );
    }

    try {
      await request("employeeroles/roles/" + role.ID, "DELETE", {
        REPLACEMENT_ROLE_ID: replacementRole,
      });

      this.props.updateState();
    } catch ({error}) {
      this.modal.stopLoading();

      if (error === "ROLE_INVALID") {
        return showErrorNotification(
          "Role Not Found",
          "We were not able to find this role. Please refresh the page."
        );
      }

      return showErrorNotification(
        "Error Deleting Role",
        "We encountered an error trying to delete this role. Please refresh the page and try again."
      );
    }

    showSuccessNotification("", "Role Deleted Successfully");

    this.modal.close();
  }

  render() {
    const {role} = this.state;

    return (
      <Modal
        tooltip={{data: "Test"}}
        buttonLabel="Save"
        label="Edit Role"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.removeRole.bind(this)}
          validationSchema={Yup.object({
            role: Yup.number()
              .nullable()
              .test(
                "Select replacement role",
                "Select replacement role",
                (value, ctx) => {
                  if (this.props.hasActiveEmployees) {
                    return !!value;
                  }
                  return true;
                }
              ),
          })}
          initialValues={{
            role: null,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <RoleDropdown
                  exclude={[role?.ID]}
                  label="Replacement Role"
                  tooltip={
                    "If no employees currently have this role, please leave this field blank. If any employees still have this role, please select a replacement role."
                  }
                  options={formikOptions}
                  name="role"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

RemoveRoleModal.propTypes = {
  onSave: PropTypes.func,
};

export default RemoveRoleModal;
