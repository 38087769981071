import React, {Component} from "react";
import {
    Card,
    PageHeadings,
    Tab,
    Table,
    TwoColumnList,
    Loading
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import VendorModal from "../../../../modals/operations/supply-chain/vendors/vendor-modal";
import VendorContactModal from "../../../../modals/operations/supply-chain/vendors/vendor-contact-modal";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import {showLoadingConfirmAlert} from "../../../../utils/alert-helper";
import VendorItemModal from "../../../../modals/operations/supply-chain/vendors/vendor-item-modal";
import {
    PurchaseOrderRequests,
    VendorRequests
} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {setupReduxConnection} from "../../../../redux";

class SupplyChainVendorPage extends Component {
    state = {
        vendor: null,
    };

    componentDidMount() {
        this.syncState();
    }

    async syncState() {
        const {UNIQUE_ID: uniqueId} = this.props.router.params;
        const vendor = await VendorRequests.fetchVendor(uniqueId);
        const vendorContacts = await VendorRequests.fetchVendorContacts(vendor.ID);
        const vendorItems = await VendorRequests.fetchVendorItems(vendor.ID);
        const purchaseOrders = await VendorRequests.fetchPurchaseOrders(vendor.ID);
        this.setState({
            vendor: {
                ...vendor,
                CONTACTS: vendorContacts,
                ITEMS: vendorItems,
                PURCHASE_ORDERS: purchaseOrders
            }
        });
    }

    async archiveVendorContact(contact) {
        const {vendor} = this.state;
        showLoadingConfirmAlert(
            "Remove Contact",
            "Are you sure you would like to remove this contact from the vendor?"
        )
            .then(async (close) => {
                await VendorRequests.deleteVendorContact(vendor.ID, contact.ID);

                await this.syncState();

                return close();
            })
            .catch(() => {
                return;
            });
    }

    render() {
        const {vendor} = this.state;

        if (!vendor) {
            return <Loading/>;
        }

        return (
            <div>
                <VendorModal
                    ref={(e) => (this.vendorModal = e)}
                    syncState={() => this.syncState()}
                />

                <VendorContactModal
                    ref={(e) => (this.vendorContactModal = e)}
                    vendor={vendor}
                    syncState={() => this.syncState()}
                />

                <VendorItemModal
                    ref={(e) => (this.vendorItemModal = e)}
                    vendor={vendor}
                    syncState={() => this.syncState()}
                />

                <PageHeadings
                    label={vendor.NAME}
                    breadcrumbs={[
                        {label: "Operations", url: "/"},
                        {label: "Vendors", url: "/vendors"},
                    ]}
                />

                <TwoColumnList
                    label="Vendor Information"
                    // tooltip={{data: "Store hours will be displayed online"}}
                    data={[
                        {label: "Vendor Name", value: vendor.NAME},
                        {label: "Street", value: vendor.ADDRESS},
                        {label: "City", value: vendor.CITY},
                        {label: "State", value: vendor.STATE},
                        {label: "Zip", value: vendor.ZIPCODE},
                    ]}
                    buttons={[
                        {
                            label: "Edit Information",
                            onClick: () => this.vendorModal.open(vendor),
                        },
                    ]}
                />

                <Tab
                    data={[
                        {id: "contacts", label: "Contacts"},
                        {id: "ingredients", label: "Vendor Items"},
                        {id: "po", label: "Purchase Orders"},
                        {id: "invoice", label: "Invoices"},
                    ]}
                >
                    {(id) => {
                        if (id === "contacts") {
                            return (
                                <Card
                                    label="Vendor Contacts"
                                    description="View the contacts for this vendor"
                                    button={{
                                        label: "Add Contact",
                                        onClick: () => {
                                            this.vendorContactModal.open();
                                        },
                                    }}
                                >
                                    <Table
                                        data={vendor.CONTACTS}
                                        columns={[
                                            {
                                                value: "FIRST_NAME",
                                                label: "Name",
                                                format: (_, row) => {
                                                    return (
                                                        <div>
                                                            <div className="text-sm font-medium text-gray-900">
                                                                {`${row.FIRST_NAME} ${row.LAST_NAME}`}
                                                            </div>

                                                            {row.PHONE ? (
                                                                <div className="text-sm text-gray-500">
                                                                    {getFormattedPhone(row.PHONE)}
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    );
                                                },
                                            },
                                            {
                                                value: "IS_PRIMARY_CONTACT",
                                                label: "Primary Contact",
                                                type: "bool",
                                            },

                                            {value: "EMAIL", label: "Email"},
                                        ]}
                                        actionButtons={[
                                            {
                                                label: "Edit",
                                                onClick: (row) => {
                                                    this.vendorContactModal.open({vendorId: vendor.ID, ...row});
                                                },
                                            },
                                            {
                                                icon: "trash",
                                                onClick: (row) => {
                                                    return this.archiveVendorContact(row);
                                                },
                                            },
                                        ]}
                                    />
                                </Card>
                            );
                        } else if (id === "ingredients") {
                            return (
                                <Card
                                    label="Vendor Items"
                                    description="View all of the items you can purchase from this vendor"
                                    button={{
                                        label: "Create Item",
                                        onClick: () => {
                                            this.vendorItemModal.open();
                                        },
                                    }}
                                >
                                    <Table
                                        data={vendor.ITEMS}
                                        columns={[
                                            {value: "NAME", label: "Name"},
                                            {
                                                value: "PRODUCT_SKU",
                                                label: "SKU",
                                                format: (sku) => {
                                                    return sku ? "#" + sku : "-";
                                                },
                                            },
                                            {value: "INGREDIENT_NAME", label: "Attached Ingredient"},
                                            {
                                                value: "PRICE_PER_CASE",
                                                label: "Price per Case",
                                                type: "dollars",
                                            },
                                        ]}
                                        actionButtons={[
                                            {
                                                label: "Edit",
                                                onClick: (row) => {
                                                    this.vendorItemModal.open(row);
                                                },
                                            },
                                        ]}
                                    />
                                </Card>
                            );
                        } else if (id === "po") {
                            return (
                                <Card
                                    label="Purchase History"
                                    description="See the purchase history from this vendor"
                                    button={{
                                        label: "Create Purchase Order",
                                        onClick: () => {
                                            this.props.router.navigate(
                                                "/purchase-order-create?vendor=" + vendor.ID
                                            );
                                        },
                                    }}
                                >
                                    <Table
                                        data={vendor.PURCHASE_ORDERS}
                                        columns={[
                                            {
                                                value: "PO_NUMBER",
                                                label: "Number",
                                                format: (value) => {
                                                    const stringVal = "" + value;

                                                    return "#" + stringVal.padStart(7 - stringVal.length, "0");
                                                },
                                            },
                                            {value: "STATUS", label: "Status"},
                                            {value: "TOTAL", label: "Total", type: "dollars"},
                                        ]}
                                    />
                                </Card>
                            );
                        } else if (id === "invoices") {
                            return (
                                <Card
                                    label="Invoice History"
                                    description="See the history of all your invoices from this vendor"
                                    button={{
                                        label: "Receive Invoice",
                                        // onClick: () => {
                                        //   this.props.router.navigate(
                                        //     "/purchase-order-create?vendor=" + vendor.ID
                                        //   );
                                        // },
                                    }}
                                >
                                    <Table
                                        data={vendor.INVOICES ?? []}
                                        columns={[
                                            {
                                                value: "INVOICE_NUMBER",
                                                label: "Number",
                                                format: (value) => {
                                                    const stringVal = "" + value;

                                                    return "#" + stringVal.padStart(7 - stringVal.length, "0");
                                                },
                                            },
                                            {value: "STATUS", label: "Status"},
                                            {value: "TOTAL", label: "Total", type: "dollars"},
                                        ]}
                                    />
                                </Card>
                            );
                        }

                        return <></>;
                    }}
                </Tab>
            </div>
        );
    }
}

export default setupReduxConnection(["supply"])(withRouter(SupplyChainVendorPage));
