import React, {Component} from "react";
import {
  FormBoolean,
  FormInput,
  FormTextArea,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import CategoryDropdown from "../../../dropdowns/sales/category-dropdown";
import {request} from "../../../utils/request";
import ImageFormDropZone from "../../../components/image-form-drop-zone";
import ProductTagsDropdown from "../../../dropdowns/sales/product-tags-dropdown";
import * as Yup from "yup";
import ColorDropdown from "../../../features/team/color-dropdown";
import {defaultProductImages} from "../../../pages/sales/menu/products/create-product-page";
import PropTypes from "prop-types";
import Accordion from "../../../components/form-elements/accordion";
import Gallery from "../../../components/gallery";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import LocationsDropdown from "../../../dropdowns/team/locations-dropdown";
import {setupReduxConnection} from "../../../redux";

class ProductDetailsModal extends Component {
  state = {
    product: null,
    isEnable: false,
    expanded: true,
    allLocations: [],
    tags: [],
  };

  open(product = null, isEnable = false) {
    this.setState({product, isEnable, expanded: !!product}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });

    if (!!product && !this.props.initialTags) {
      this.fetchTags(product);
    }
  }

  componentDidMount() {
    request("partner/company/locations", "GET").then((locations) =>
      this.setState({allLocations: locations})
    );
  }

  async fetchTags(product) {
    const productTags = await request("tags/product/" + product.ID, "GET", null);

    return this.setState({tags: productTags});
  }

  createProduct = async ({
    name,
    category,
    description,
    instructions,
    oneClick,
    tax,
    logo,
    pos,
    kiosk,
    mobile,
    third,
    autoComplete,
    taxOverride,
    advancedTime,
    alcoholic,
    tags,
    color,
    minQty,
    maxQty,
    locations,
    price,
    abbreviation,
  }) => {
    let logoLow = defaultProductImages.includes(logo) ? logo : `LOW_${logo}`;

    let productPayload = {
      NAME: name,
      LOGO: logo,
      LOGO_LOW: logoLow,
      CATEGORY_ID: category,
      DESCRIPTION: description,
      INSTRUCTIONS: instructions,
      ENABLED: parseInt(mobile),
      ONE_CLICK: parseInt(oneClick),
      ALCOHOLIC: parseInt(alcoholic),
      POS_ENABLED: parseInt(pos),
      TAX_DISABLED: parseInt(tax),
      KIOSK_ENABLED: parseInt(kiosk),
      THIRD_PARTY_ENABLED: parseInt(third),
      ADVANCED_TIME: advancedTime,
      AUTO_COMPLETE: autoComplete,
      TAX_OVERRIDE: taxOverride,
      ICON_COLOR: color,
      ABBREVIATION: abbreviation,
      MIN_QTY: parseInt(minQty),
      MAX_QTY: parseInt(maxQty),
      PRICE: decimalToDollars(price),
    };

    const productId = await request("products/", "POST", productPayload);

    if (locations.length > 0) {
      const productPayload = {
        LOCATIONS: locations,
        PRODUCTS: [productId],
      };

      await request("sync/products", "POST", productPayload);
    }

    const serverProduct = await request("product/" + productId, "GET", null);
    await this.props.updateProduct(serverProduct);

    this.modal.close();
  };

  saveProduct = async ({
    name,
    category,
    description,
    instructions,
    oneClick,
    tax,
    logo,
    pos,
    kiosk,
    mobile,
    third,
    autoComplete,
    taxOverride,
    advancedTime,
    alcoholic,
    tags,
    color,
    minQty,
    maxQty,
    locations,
    price,
    abbreviation,
  }) => {
    let logoLow = defaultProductImages.includes(logo) ? logo : `LOW_${logo}`;
    const {allLocations} = this.state;

    let productPayload = {
      NAME: name,
      LOGO: logo,
      LOGO_LOW: logoLow,
      CATEGORY_ID: category,
      DESCRIPTION: description,
      INSTRUCTIONS: instructions,
      ENABLED: parseInt(mobile),
      ONE_CLICK: parseInt(oneClick),
      ALCOHOLIC: parseInt(alcoholic),
      POS_ENABLED: parseInt(pos),
      TAX_DISABLED: parseInt(tax),
      KIOSK_ENABLED: parseInt(kiosk),
      THIRD_PARTY_ENABLED: parseInt(third),
      ADVANCED_TIME: advancedTime,
      AUTO_COMPLETE: autoComplete,
      TAX_OVERRIDE: taxOverride,
      ICON_COLOR: color,
      ABBREVIATION: abbreviation,
      MIN_QTY: parseInt(minQty),
      MAX_QTY: parseInt(maxQty),
      PRICE: decimalToDollars(price),
    };

    let {product} = this.state;

    const serverProduct = await request(
      "products/" + product.ID,
      "PATCH",
      productPayload
    );

    await request("tags/product/" + product.ID, "PATCH", {tags});

    await this.props.updateProduct(serverProduct);

    this.modal.close();
  };

  render() {
    const {product, isEnable, tags} = this.state;
    let {initialTags} = this.props;
    const {location} = this.props.shop;

    return (
      <Modal
        buttonLabel={product ? "Save" : "Create"}
        label={product ? "Edit Product" : "Create Product"}
        description={(product ? "Edit" : "Create") + " the configuration of this product"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: product?.NAME,
            category: product?.CATEGORY_ID,
            description: product?.DESCRIPTION,
            instructions: product?.INSTRUCTIONS,
            oneClick: product?.ONE_CLICK ?? "0",
            alcoholic: product?.ALCOHOLIC ?? "0",
            tax: product?.TAX_DISABLED ?? "0",
            pos: product?.POS_ENABLED ?? "1",
            kiosk: product?.KIOSK_ENABLED ?? "1",
            third: product?.THIRD_PARTY_ENABLED ?? "1",
            mobile: product?.ENABLED ?? "1",
            advancedEnabled: product?.ADVANCED_TIME ? "1" : "0",
            advancedTime: product?.ADVANCED_TIME,
            autoComplete: product?.AUTO_COMPLETE ?? "0",
            taxOverride: product?.TAX_OVERRIDE,
            logo: product?.LOGO,
            color: product?.ICON_COLOR,
            abbreviation: product?.ABBREVIATION,
            minQty: product?.MIN_QTY,
            maxQty: product?.MAX_QTY,
            locations:
              product?.LOCATION_PRODUCTS?.filter(
                (l) => l.LOCATION_ID !== location.ID
              ).map((l) => l.LOCATION_ID) ?? [],
            price: product?.PRICE ? toDollars(product.PRICE) : "0.00",
            tags: (initialTags ?? tags)?.map(({TAG_ID}) => TAG_ID),
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Please input a name for your product"),
            category: Yup.string().nullable().required("Please select a valid category"),
            minQty: Yup.number()
              .integer()
              .positive("Minimum quantity must be positive.")
              .nullable()
              .typeError("Min quantity must be a number."),
            maxQty: Yup.number()
              .integer()
              .positive("Maximum quantity must be positive.")
              .nullable()
              .typeError("Min quantity must be a number."),
            description: isEnable
              ? Yup.string().nullable()
              : Yup.string()
                  .nullable()
                  .test(
                    "description-length-check",
                    "Description must be less than 1024 characters",
                    (val) => !val || val.length < 1024
                  ),
            price: Yup.string()
              .nullable()
              .test("Price Valid", "Please enter a number.", (val) => {
                return !isNaN(decimalToDollars(val));
              }),
            advancedTime: Yup.number()
              .nullable()
              .typeError("Please enter a number.")
              .test(
                "Advanced Time Required",
                "Please enter the number of days of advanced notice required.",
                (val, ctx) => {
                  return ctx.parent.advancedEnabled === "0" || val;
                }
              ),
          })}
          onSubmit={product ? this.saveProduct : this.createProduct}
        >
          {(formikOptions) => {
            const {handleSubmit, values, setFieldValue} = formikOptions;
            const {name} = values;

            return (
              <form onSubmit={handleSubmit}>
                {!isEnable && (
                  <>
                    <FormInput label="Name" name="name" options={formikOptions} />

                    <CategoryDropdown name="category" options={formikOptions} />

                    <FormInput label="Price" name="price" options={formikOptions} />

                    <FormTextArea
                      label={`Description`}
                      name="description"
                      options={formikOptions}
                    />

                    <LocationsDropdown
                      label="Synced Locations"
                      options={formikOptions}
                      placeholder="None"
                      name="locations"
                      isCompany
                      exclude={[location.ID]}
                      tooltip="The product will be synced to these locations. You cannot remove a synced location. Deselecting a synced location will not sync it to the location on save."
                      multi
                    />

                    <ImageFormDropZone
                      name="logo"
                      label="Product Image"
                      options={formikOptions}
                    />

                    <Accordion label="Preset Image" className="mt-4">
                      <Gallery
                        isModal
                        name="logo"
                        options={formikOptions}
                        data={defaultProductImages.map((item) => {
                          return {
                            id: item,
                            source: item,
                          };
                        })}
                      />
                    </Accordion>
                  </>
                )}

                <Accordion label="Platforms" className="mt-4" defaultExpanded={isEnable}>
                  <FormBoolean
                    label="Point of Sale Enabled"
                    options={formikOptions}
                    name="pos"
                    tooltip="Will this product show up on the point of sale"
                    flex
                  />

                  <FormBoolean
                    label="Mobile Enabled"
                    options={formikOptions}
                    name="mobile"
                    tooltip="Can customers order this product on the mobile ordering app and website"
                    flex
                  />

                  <FormBoolean
                    label="Kiosk Enabled"
                    options={formikOptions}
                    tooltip="Can customers order this product on kiosks"
                    name="kiosk"
                  />

                  <FormBoolean
                    label="Third Party Enabled"
                    options={formikOptions}
                    tooltip="Will this product show up on third party order websites"
                    name="third"
                  />
                </Accordion>

                {!isEnable && (
                  <>
                    <Accordion label="Tax Settings" className="mt-4">
                      <FormBoolean
                        label="Tax Disabled"
                        options={formikOptions}
                        name="tax"
                        tooltip="Is tax disabled for this product"
                        flex
                      />

                      <FormBoolean
                        label="Tax Override"
                        options={formikOptions}
                        tooltip="If this is set to yes, then taxes will be applied to this product even if it is ordered with a tax disabled order type. If taxes are explicitly toggled off with the tax toggle on the POS, then taxes will not be charged."
                        name="taxOverride"
                        flex
                      />
                    </Accordion>

                    <Accordion label="POS Settings" className="mt-4">
                      <FormBoolean
                        label="One Click"
                        options={formikOptions}
                        tooltip="Will clicking on this item on the POS add it directly to the ticket without opening the modifiers screen"
                        name="oneClick"
                      />

                      <FormBoolean
                        label="Auto complete product"
                        options={formikOptions}
                        tooltip="If a product has this field set to yes, then this product will be automatically completed on purchase. This setting only applies to orders placed on the register."
                        name="autoComplete"
                      />

                      <ColorDropdown
                        removable={true}
                        name={"color"}
                        label="Icon Color for the POS"
                        options={formikOptions}
                        tooltip={
                          "Optionally select an icon color to display on the register screen of the POS. The icon will have the selected background color and a two letter abbreviation of the product."
                        }
                      />

                      <FormInput
                        name="abbreviation"
                        label="Abbreviation for the POS"
                        options={formikOptions}
                        tooltip="Set the abbreviation for the product icon on the POS."
                        maxLength={4}
                      />
                    </Accordion>

                    <Accordion label="Mobile Settings" className="mt-4">
                      <FormBoolean
                        label="Require advanced notice"
                        options={formikOptions}
                        tooltip="Does this product need advanced notice when ordering"
                        name="advancedEnabled"
                        onChangeSoft={({value}) => {
                          if (value === "0") {
                            setFieldValue("advancedTime", 0);
                          }
                        }}
                      />

                      {values.advancedEnabled === "1" && (
                        <FormInput
                          label="Days in Advanced Notice"
                          options={formikOptions}
                          tooltip="How many days in advance must notice be given to order this product"
                          name="advancedTime"
                        />
                      )}

                      <FormInput
                        label="Minimum Quantity"
                        options={formikOptions}
                        tooltip="The minimum quantity that must be ordered for mobile and web orders. This does not impact POS orders. Remove this field for no minimum."
                        placeholder="2"
                        name="minQty"
                      />

                      <FormInput
                        label="Maximum Quantity"
                        options={formikOptions}
                        tooltip="The maximum quantity that can be ordered for a single mobile or web order. This does not impact POS orders. Default is 50."
                        placeholder="15"
                        name="maxQty"
                      />

                      <FormTextArea
                        label="Preparation Instructions"
                        name="instructions"
                        options={formikOptions}
                        tooltip={{
                          label: "Preparation Instructions",
                          data: "Preparation Instructions on products currently appear on printed Make Tickets printed with the Legacy Printing mode",
                        }}
                      />
                    </Accordion>

                    <Accordion label="Miscellaneous" className="mt-4">
                      <FormBoolean
                        label="Is Alcohol"
                        options={formikOptions}
                        tooltip="Is this product, or does this product contain alcohol"
                        name="alcoholic"
                      />

                      {!!product?.ID && (
                        <ProductTagsDropdown
                          label="Product Tags"
                          name="tags"
                          options={formikOptions}
                        />
                      )}
                    </Accordion>
                  </>
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

ProductDetailsModal.propTypes = {
  isEnable: PropTypes.bool,
};

export default setupReduxConnection(["shop"])(ProductDetailsModal);
