import React, {Component} from "react";
import {Card, Table, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import MobileOrderingModal from "../../../modals/shop/shop-settings/mobile-ordering-modal";
import {request} from "../../../utils/request";
import WeightedQueueModal from "../../../modals/shop/shop-settings/weighted-queue-modal";
import {updateStateDelegator} from "../../../utils/util";

const WEIGHT_TYPES = {
  DOLLAR: "Dollar Amount",
  PRODUCT: "Number of Products",
  CATEGORY: "Products in Category",
};

const WEIGHT_RULE_TYPES = {
  ADD: "Add Minutes",
  PREVENT: "Prevent Checkout",
};

class MobileOrderingTab extends Component {
  state = {weights: null};

  componentDidMount() {
    request("ordering/weights", "GET").then((weights) => {
      this.setState({weights});
    });
  }

  render() {
    const {
      MOBILE_ORDERS_TEMP_DISABLED,
      ASAP_ENABLED,
      SCHEDULING_ENABLED,
      ORDER_ESTIMATE,
      DELIVERY_ESTIMATE,
      MOBILE_HIDE_PRICE_PREVIEW,
      REQUIRE_MOBILE_LOGIN,
      SCHEDULED_ORDER_DISCLAIMER,
      MOBILE_CHECKOUT_DISCLAIMER,
      ORDER_TRACKER_ENABLED,
      RECEIPT_CUSTOMER_MESSAGE,
      DISPLAY_RECEIPT_MESSAGE_BOTTOM,
      PRODUCT_NOTES_ENABLED,
      TICKET_NOTES_ENABLED,
    } = this.props.shop.settings;

    const {HIDE_ALL_MOBILE_ORDERING} = this.props.shop.companySettings;

    const {MIN_ORDER} = this.props.shop.location;
    const {weights} = this.state;

    if (weights) {
      weights.sort((a, b) => a.WEIGHT - b.WEIGHT);
    }

    const seq = weights ? Math.max(...weights?.map((item) => item.WEIGHT), 0) : 0;

    return (
      <div>
        <MobileOrderingModal ref={(e) => (this.mobileOrderingModal = e)} />
        <WeightedQueueModal
          weights={weights}
          weightSeq={seq + 1}
          ref={(e) => (this.weightedQueueModel = e)}
          addState={(weight) => this.setState({weights: [...weights, weight]})}
          updateState={(id, weight) => {
            this.setState({
              prizes: updateStateDelegator(weights, id, weight),
            });
          }}
        />

        <TwoColumnList
          label="Mobile Ordering"
          description="Configure website and mobile app ordering for your shop"
          data={[
            {
              label: "Disable Mobile Ordering (Temporarily)",
              value: MOBILE_ORDERS_TEMP_DISABLED,
              type: "bool",
              tooltip:
                "Disables orders from being placed on the ordering website and mobile app.",
            },
            {
              label: "Require Mobile Ordering Login",
              value: REQUIRE_MOBILE_LOGIN,
              type: "bool",
              tooltip:
                "Requires customers on the order website to login before placing an order.",
            },
            {
              label: "Allow Scheduled Orders",
              value: SCHEDULING_ENABLED,
              type: "bool",
              tooltip:
                "Allow your customers to place order on the website and mobile app for pickup at a future time.",
            },
            {
              label: "Allow ASAP Orders",
              value: ASAP_ENABLED,
              type: "bool",
              tooltip:
                'Allow your customer sto place orders on the website and mobile app for "as soon as possible."',
            },
            {
              label: "Allow Product Notes",
              value: PRODUCT_NOTES_ENABLED,
              type: "bool",
              tooltip:
                "Allow your customers to add notes to products on mobile and web ordering.",
            },
            {
              label: "Allow Ticket Notes",
              value: TICKET_NOTES_ENABLED,
              type: "bool",
              tooltip:
                "Allow your customers to add notes to tickets on mobile and web ordering.",
            },
            {
              label: "Minimum Order",
              value: toDollars(MIN_ORDER, true),
              tooltip: "Set the minimum order amount that can be processed",
            },
            {
              label: "Hide Price Previews",
              value: MOBILE_HIDE_PRICE_PREVIEW,
              type: "bool",
            },
            {
              label: "Show Order Tracker",
              value: ORDER_TRACKER_ENABLED,
              type: "bool",
            },
            {
              label: "Order Estimate",
              value: `${ORDER_ESTIMATE} Minutes`,
              tooltip: "Estimate in minutes that is added onto mobile ordered items.",
            },
            {
              label: "Delivery Estimate",
              value: `${DELIVERY_ESTIMATE} Minutes`,
              tooltip: "Estimate in minutes that is added onto delivery items.",
            },
            {
              label: "Display Receipt Message at Bottom of Receipt",
              value: DISPLAY_RECEIPT_MESSAGE_BOTTOM,
              type: "bool",
              tooltip:
                "Enabling this setting will display the custom message at the end of the receipt, rather than at the beginning.",
            },
            {
              label: "Receipt Custom Message",
              value:
                RECEIPT_CUSTOMER_MESSAGE.length === 0 ? "None" : RECEIPT_CUSTOMER_MESSAGE,
              tooltip: "A custom message to display on the receipt screen.",
              span: true,
            },
            {
              label: "Scheduled Order Disclaimer",
              value:
                SCHEDULED_ORDER_DISCLAIMER.length === 0
                  ? "None"
                  : SCHEDULED_ORDER_DISCLAIMER,
              tooltip:
                "A disclaimer that will show if a patron tries to schedule order for a day in advanced.",
              span: true,
            },
            {
              label: "Mobile Checkout Disclaimer",
              value: MOBILE_CHECKOUT_DISCLAIMER.length === 0 ? "None" : MOBILE_CHECKOUT_DISCLAIMER,
              tooltip:
                "A disclaimer that will show on mobile ordering platforms before a patron submits their order in checkout.",
              span: true,
            },
            {
              label: "Hide All Mobile Ordering",
              value: HIDE_ALL_MOBILE_ORDERING,
              hint: "Company Setting",
              type: "bool",
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.mobileOrderingModal.open(),
            },
          ]}
        />

        <Card
          label="Weighted Queue Time"
          description="If an item or dollar amount threshold is met on a web or mobile order, prevent the order or add time to the minimum scheduled time"
          button={{
            label: "Add Threshold",
            onClick: () => this.weightedQueueModel.open(),
          }}
        >
          <Table
            data={weights}
            columns={[
              {
                width: 1,
                value: "TYPE",
                label: "Type",
                format: (type) => WEIGHT_TYPES[type],
              },
              {
                value: "QUANTITY",
                label: "Content",
                format: (_, weight) => {
                  if (weight.TYPE === "DOLLAR") {
                    return toDollars(weight.QUANTITY);
                  }

                  if (weight.TYPE === "CATEGORY") {
                    if (weight.TOGGLE.CATEGORIES.length === 0) {
                      return "All Categories";
                    }

                    return weight.TOGGLE.CATEGORIES.map((item) => item.NAME).join(", ");
                  }

                  if (weight.TYPE === "PRODUCT") {
                    if (weight.TOGGLE.PRODUCTS.length === 0) {
                      return "All Products";
                    }

                    return weight.TOGGLE.PRODUCTS.map((item) => item.NAME).join(", ");
                  }
                },
              },
              {
                value: "WEIGHT_TYPE",
                label: "Weight Rule",
                format: (rule) => WEIGHT_RULE_TYPES[rule],
              },
              {
                value: "AMOUNT",
                label: "Rule Content",
                format: (_, weight) => {
                  if (weight.WEIGHT_TYPE === "ADD") {
                    return weight.AMOUNT + " minutes";
                  }

                  if (weight.WEIGHT_TYPE === "PREVENT") {
                    return weight.NOTES;
                  }
                },
              },
              {width: 1, value: "WEIGHT", label: "Weight"},
            ]}
            actionButtons={[
              {
                label: "Edit",
                onClick: (item) => this.weightedQueueModel.open(item),
              },
            ]}
          />
        </Card>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(MobileOrderingTab);
