import React, {Component} from "react";
import {Outlet} from "react-router-dom";
import PageLayout from "../page/page-layout";
import SideNav from "../../components/side-nav";
import {PERMISSIONS} from "../../utils/constants";
import {faUsers} from "@fortawesome/pro-light-svg-icons";
import GrayBadge from "../../components/badges/gray-badge";

export const OPERATION_ROUTES = [
  {
    live: true,
    id: "cash",
    name: "Cash Management",
    href: "/cash",
    permissions: [PERMISSIONS.OPERATIONS.CASH_FULL.id],
    children: [
      {
        live: true,
        name: "Cash Drawers",
        href: "/drawers",
        paths: ["/drawers", "/drawer"],
        permissions: [],
      },
      {
        live: true,
        name: "Tip Buckets",
        href: "/buckets",
        paths: ["/buckets", "/bucket"],
        permissions: [],
      },
      {
        live: true,
        name: "Store Banks",
        href: "/banks",
        paths: ["/banks", "/bank"],
        permissions: [],
      },
    ],
  },
  {
    live: true,
    id: "ingredients",
    name: "Ingredients",
    href: "/ingredients",
    permissions: [PERMISSIONS.OPERATIONS.INGREDIENTS_FULL.id],
    children: [
      {
        live: true,
        name: "Ingredients",
        href: "/ingredients",
        paths: ["/ingredients", "/ingredient"],
        permissions: [],
      },
      {
        live: true,
        name: "Units",
        href: "/units",
        paths: ["/units", "/unit"],
        permissions: [],
      },
      {
        live: true,
        name: "Stocking Units",
        href: "/stockings",
        paths: ["/stockings", "/stocking"],
        permissions: [],
      },
    ],
  },
  {
    live: true,
    id: "labels",
    name: "Labels",
    href: "/labels",
    permissions: [PERMISSIONS.OPERATIONS.LABELS_FULL.id],
    children: [
      {
        live: true,
        name: "Labels",
        href: "/labels",
        paths: ["/labels", "/label"],
        permissions: [],
      },
      {
        live: true,
        name: "Label Categories",
        href: "/lategories",
        paths: ["/lategories", "/lategory"],
        permissions: [],
      },
    ],
  },
  {
    live: true,
    id: "checklists",
    name: "Checklists",
    href: "/checklists",
    permissions: [PERMISSIONS.OPERATIONS.CHECKLISTS_FULL.id],
    paths: ["/checklists", "/checklist"],
  },
  {
    live: true,
    id: "invoicing",
    name: "Invoicing",
    href: "/invoicing",
    permissions: [PERMISSIONS.OPERATIONS.INVOICES_FULL.id],
    children: [
      {
        live: true,
        name: "Invoice List",
        href: "/invoices",
        paths: ["/invoices", "/invoice"],
        permissions: [PERMISSIONS.OPERATIONS.INVOICES_FULL.id],
      },
      {
        live: true,
        icon: faUsers,
        searchable: true,
        name: "Contacts",
        href: "/contacts",
        paths: ["/contacts", "/contact"],
        permissions: [PERMISSIONS.OPERATIONS.INVOICES_FULL.id],
      },
    ],
  },
  {
    live: true,
    id: "billing-accounts",
    name: "Billing Accounts",
    href: "/billing-accounts",
    permissions: [PERMISSIONS.OPERATIONS.INVENTORY_FULL.id],
  },
  {
    live: true,
    id: "inventory",
    name: "Inventory",
    href: "/inventory",
    permissions: [PERMISSIONS.OPERATIONS.INVENTORY_FULL.id],
    children: [
      {
        live: true,
        name: "Logs",
        href: "/inventory/logs",
        permissions: [],
      },
    ],
  },
  {
    live: true,
    id: "chat",
    name: "Customer Chats",
    href: "/chats",
    paths: ["/chats", "/chat"],
    permissions: [PERMISSIONS.OPERATIONS.SUPPORT_TEXTS_FULL.id],
  },
  {
    live: true,
    id: "supply",
    name: (
      <div className={"flex flex-row items-center"}>
        <div>Supply Chain</div>

        <GrayBadge darkGrey className={"ml-3"}>
          Beta
        </GrayBadge>
      </div>
    ),
    href: "/supply",
    paths: ["/supply"],
    children: [
      {
        live: true,
        name: "Inventory",
        href: "/recipes",
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        name: "Supply Chain Units",
        href: "/supply-chain-units",
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        id: "vendors",
        name: "Vendors",
        href: "/vendors",
        paths: ["/vendors", "/vendor"],
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        id: "purchase-orders",
        name: "Purchase Orders",
        href: "/purchase-orders",
        paths: ["/purchase-orders", "/purchase-order", "/purchase-order-create"],
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        id: "transfers",
        name: "Transfers",
        href: "/transfers",
        paths: ["/transfer", "/transfers"],
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      {
        live: true,
        id: "waste",
        name: "Waste Management",
        href: "/wastes",
        paths: ["/wastes", "/waste"],
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
      // {
      //   live: true,
      //   id: "usage",
      //   name: "Recipe Usage",
      //   href: "/usage",
      //   paths: ["/usage"],
      //   permissions: [PERMISSIONS.OPERATIONS.RECIPES_FULL.id],
      // },
      {
        live: true,
        id: "counts",
        name: "Inventory Counts",
        href: "/counts",
        paths: ["/counts", "/count"],
        permissions: [PERMISSIONS.OPERATIONS.SUPPLY_CHAIN_FULL.id],
      },
    ],
  },
];

class MarketingSideNav extends Component {
  render() {
    return (
      <div className="p-2 md:p-6 height-screen">
        <PageLayout className="mt-0">
          <div className="lg:col-start-1 lg:col-span-2">
            <SideNav
              title="Operations"
              permissions={[PERMISSIONS.OPERATIONS.FULL.id]}
              navigation={OPERATION_ROUTES}
            />
          </div>

          <div className="lg:col-start-3 lg:col-span-10">
            <Outlet />
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default MarketingSideNav;
