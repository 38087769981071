import React, {Component} from "react";
import {
  Modal,
  FormSelect,
  FormTextArea,
  FormInput,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {FieldArray, Formik} from "formik";
import moment from "moment";
import {request} from "../../../utils/request";
import {
  showConfirmAlert,
  showErrorAlert,
  showLoadingConfirmAlert,
} from "../../../utils/alert-helper";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import FormRow from "../../../components/form-row";
import * as Yup from "yup";
import FormCheckbox from "../../../components/form-elements/form-checkbox";
import {isTimeOffPartialDay} from "../../../utils/time-off-helper";

class TimeOffModal extends Component {
  state = {employeeId: null, timeOff: null, conflictDate: null};

  open(employeeId, timeOff = null) {
    this.setState({employeeId, timeOff}, () => this.modal.open());
  }

  checkTimeRangeHasConflict(start, end) {
    const {blackoutDates} = this.props;

    if (!start || !end) {
      return;
    }

    for (let date of blackoutDates) {
      if (start <= date.DATE_END && date.DATE_START <= end) {
        return this.setState({conflictDate: date});
      }
    }

    return this.setState({conflictDate: null});
  }

  populateTimeOffDays(start, end) {
    let daysArr = [];

    for (
      let currDate = moment(start);
      currDate.valueOf() <= moment(end).valueOf();
      currDate.add(1, "days")
    ) {
      daysArr.push({AMOUNT: 8, CONTENT: moment(currDate).format("MM/DD/YY")});
    }

    return this.formikRef?.setFieldValue("days", daysArr);
  }

  handleSubmit = async (values) => {
    const {employeeId, timeOff, conflictDate} = this.state;
    const {policy, notes, start, end, days} = values;

    const payload = {
      EMPLOYEE_ID: employeeId,
      DAYS: days,
      NOTES: notes,
      POLICY_ID: policy,
      DATE_START: start,
      DATE_END: end,
    };

    if (conflictDate) {
      await showLoadingConfirmAlert(
        "Time Off Date Conflict",
        "Time off dates overlap with existing blackout dates. Are you sure you want to create this time off request?"
      )
        .then((close) => {
          close();
          this.modal.close();
        })
        .catch(() => {
          return this.modal.close();
        });
    }

    try {
      let serverTimeOff;

      if (timeOff) {
        serverTimeOff = await request("timeoff/requests/" + timeOff.ID, "PATCH", payload);
      } else {
        serverTimeOff = await request("timeoff/requests/", "POST", payload);
      }

      if (serverTimeOff) {
        this.props.updateTimeOff(serverTimeOff);
      }

      return this.modal.close();
    } catch (err) {
      const {error} = err;

      if (error === "INSUFFICIENT_TIME_OFF_HOURS") {
        alert(
          "Employee has insufficient time off balance. Please adjust the time off policy and try again."
        );
      } else {
        alert("Error submitting time off request");
      }
    }
  };

  handleDelete = () => {
    const {timeOff} = this.state;

    showConfirmAlert(
      "Delete Time Off Request",
      `Are you sure you want to delete this time off request?`,
      "Confirm"
    )
      .then(async () => {
        try {
          const serverTimeOff = await request("timeoff/requests/" + timeOff.ID, "DELETE");

          if (serverTimeOff) {
            this.props.deleteTimeOff(serverTimeOff.ID);
          }
        } catch (err) {
          showErrorAlert(
            "Error Deleting This Request",
            "Please refresh the page and try again."
          );
        }

        this.modal.close();
      })
      .catch(() => this.modal.close());
  };

  renderDayForm(days, day, index, setFieldValue) {
    return (
      <div className={"flex-1"}>
        <FormInput
          label={moment(day.CONTENT, "MM/DD/YY").format("M/DD")}
          name={`days.${index}.AMOUNT`}
          placeholder={day.AMOUNT ? `${day.AMOUNT} Hours` : "8 Hours"}
          mask={[/[0-8]/]}
          onChange={(event) => {
            if (event.target.value === "") {
              day.AMOUNT = 8;
            } else if (parseInt(event.target.value)) {
              day.AMOUNT = parseInt(event.target.value);
            }
          }}
          flex
          hint={"optional"}
        />

        <FormDateTimeSelect
          hideDate
          label={"Start Time"}
          value={
            day.DATE_START ??
            moment(day.CONTENT, "MM/DD/YY").startOf("day").add(9, "hours").valueOf()
          }
          flex
          onChange={(newValue) => {
            const daysClone = JSON.parse(JSON.stringify(days));

            daysClone[index].DATE_START = newValue;

            setFieldValue("days", daysClone);
          }}
        />
      </div>
    );
  }

  renderDays(days, setFieldValue) {
    return (
      <div>
        {days.map((row, index) => {
          if (index % 2 !== 0) return;

          return (
            <FormRow>
              {this.renderDayForm(days, row, index, setFieldValue)}

              <div className="flex-1">
                {index + 1 < days.length &&
                  this.renderDayForm(days, days[index + 1], index + 1, setFieldValue)}
              </div>
            </FormRow>
          );
        })}
      </div>
    );
  }

  render() {
    const {timeOff, days, conflictDate} = this.state;
    const {policies} = this.props;
    let isPartialDay = false;

    const policyArr = policies?.map((_policy) => {
      return {label: _policy.POLICY_NAME, value: _policy.ID};
    });

    if (timeOff && timeOff.DAYS) {
      isPartialDay = isTimeOffPartialDay(timeOff.DAYS);
    }

    return (
      <Modal
        buttonLabel={timeOff ? "Edit Time Off" : "Add Time Off"}
        deleteLabel={timeOff ? "Delete" : undefined}
        label={timeOff ? "Edit" : "Add"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        deleteOnClick={timeOff ? this.handleDelete : undefined}
        large
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            policy: timeOff?.EMPLOYEE_POLICY_ID ?? null,
            start: timeOff?.DATE_START ?? moment().startOf("day").valueOf(),
            end: timeOff?.DATE_END ?? moment().startOf("day").valueOf(),
            notes: timeOff?.NOTES ?? "",
            days: timeOff?.DAYS ?? [],
            partialDays: isPartialDay,
          }}
          validationSchema={Yup.object({
            policy: Yup.number().nullable().required("Policy selection is required."),
            start: Yup.number().required("Date start is required."),
            end: Yup.number()
              .required("Date end is required.")
              .test(
                "Valid Time Off Range",
                "Please enter a valid date range",
                (val, ctx) => {
                  return val >= ctx.parent.start;
                }
              ),
            days: Yup.array()
              .required("Days required.")
              .test(
                "Valid Time Off Days",
                "Day values should be numbers between 0 and 8",
                (val, ctx) => {
                  for (const day of val) {
                    if (day > 8 || day < 0) {
                      return false;
                    }
                  }

                  return true;
                }
              ),
          })}
        >
          {(formikOptions) => {
            const {values, handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  label="Time Off Policy"
                  name="policy"
                  data={policyArr}
                  options={formikOptions}
                />

                <FormRow>
                  <FormDateTimeSelect
                    name="start"
                    label="From"
                    options={formikOptions}
                    buttonText={(epoch) => moment(epoch).format("dddd, M/D")}
                    hideTime={true}
                    flex
                    onChangeSoft={(dateStart) => {
                      if (values.end) {
                        this.populateTimeOffDays(dateStart, values.end);
                      }

                      this.checkTimeRangeHasConflict(dateStart, values.end);
                    }}
                  />

                  <FormDateTimeSelect
                    name="end"
                    label="To"
                    options={formikOptions}
                    buttonText={(epoch) => moment(epoch).format("dddd, M/D")}
                    hideTime={true}
                    flex
                    onChangeSoft={(dateEnd) => {
                      if (values.start) {
                        this.populateTimeOffDays(values.start, dateEnd);
                      }

                      this.checkTimeRangeHasConflict(values.start, dateEnd);
                    }}
                  />
                </FormRow>

                <div className="flex flex-row items-center">
                  <FormCheckbox
                    className="mt-0"
                    name={"partialDays"}
                    options={formikOptions}
                  />

                  <div className="ml-1 text-sm font-medium text-gray-700 mt-3">
                    Partial Days
                  </div>
                </div>

                {conflictDate && (
                  <div className="mt-2 mb-1 text-red-600 italic text-sm">{`Warning: Blackout time off period set from ${moment(
                    conflictDate.DATE_START
                  ).format("MMMM D")} to ${moment(conflictDate.DATE_END).format(
                    "MMMM D"
                  )}.`}</div>
                )}

                {values.partialDays && values.days && (
                  <FieldArray
                    name={"days"}
                    options={formikOptions}
                    render={() => (
                      <div>
                        {this.renderDays(values.days, formikOptions.setFieldValue)}
                      </div>
                    )}
                  />
                )}

                <FormTextArea
                  label={"Notes"}
                  name={"notes"}
                  options={formikOptions}
                  hint={"optional"}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default TimeOffModal;
