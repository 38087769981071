import React, {Component} from "react";
import {
  Filter,
  Card,
  Loading,
  PageHeadings,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import SuccessBadge from "../../../../components/badges/success-badge";
import SubscriptionListModal from "../../../../modals/marketing/subscription-lists/subscription-list-modal";
import {showLoadingConfirmAlert} from "../../../../utils/alert-helper";
import {showErrorNotification} from "../../../../utils/notification-helper";
import moment from "moment";
import SubscriptionListDetailsModal from "../../../../modals/marketing/subscription-lists/subscription-list-details-modal";
import SubscriptionListTable from "../../../../features/marketing/subscription-list-table";
import {SegmentRequests} from "../../../../utils/request-helpers/segments/segment-requests";
import {MarketingRequests} from "../../../../utils/request-helpers/marketing/marketing-requests";

class SubscriptionListPage extends Component {
  state = {isLoading: true, list: null, defaultList: null};

  componentDidMount() {
    this.setState({isLoading: true});
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    this.setState({isLoading: true});

    const list = await MarketingRequests.fetchList(id);
    const defaultList = await MarketingRequests.fetchDefaultList();

    this.setState({list, isLoading: false, defaultList});
  }

  async refreshSegment() {
    const {list} = this.state;

    try {
      const close = await showLoadingConfirmAlert(
        "Refresh Segment",
        "Are you sure you want to refresh this customer segment? This process may require a few minutes to complete."
      );

      try {
        await SegmentRequests.runSegment(list.SEGMENT.ID);
      } catch (err) {
        close();
        showErrorNotification("", "Error calculating segment. Please try again.");
      }

      close();
    } catch (err) {
      return;
    }

    this.setState({isLoading: true});

    this.syncState();
  }

  async deleteSubscriptionList() {
    const {list} = this.state;

    await MarketingRequests.deleteList(list.ID);

    this.props.router.navigate("/subscriber-lists");
  }

  renderDefault() {
    const {list} = this.state;

    return (
      <>
        <SubscriptionListModal
          ref={(e) => (this.subscriptionListModal = e)}
          onSubmit={(selectedList) =>
            this.props.router.navigate("/subscriber-list/" + selectedList)
          }
        />

        <PageHeadings
          label={
            <div className={"flex flex-row items-center"}>
              <div>{list.NAME}</div>
              <SuccessBadge className="ml-2 mt-2 h-1/2">Default</SuccessBadge>
            </div>
          }
          description={`View and manage this subscription list`}
        />

        <Card
          label="All Subscribers"
          description="View and manage all of the patrons that have subscribed to text marketing"
        >
          <SubscriptionListTable
            className={"-ml-4"}
            list={list}
            actionButtons={[
              {
                label: "View Patron",
                onClick: (row) => this.props.router.navigate("/patron/" + row.ID),
              },
            ]}
          />
        </Card>
      </>
    );
  }

  renderBody() {
    const {list, defaultList} = this.state;

    const notAddedPatrons = defaultList?.Patrons?.filter(
      (patron) => !list?.Patrons?.find((_patron) => _patron.ID === patron.ID)
    );

    const actionButtons = [
      {
        label: "View Patron",
        onClick: (row) => this.props.router.navigate("/patron/" + row.PATRON_ID),
      },
      {
        label: "Add to list",
        onClick: async (data) => {
          await MarketingRequests.addPatronToList(list.ID, [data.PATRON_ID]);

          await this.syncState();
        },
      },
    ];

    return (
      <>
        <PageHeadings
          label={list.NAME}
          description={`View and manage this subscription list`}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Delete List",
                      onClick: () => this.deleteSubscriptionList(),
                    },
                  ],
                },
              ],
            },
          ]}
        />

        {this.renderSubscriberList(list)}

        <Card
          label="All Subscribers"
          description="View all the subscribed patrons and add them to this list"
        >
          <SubscriptionListTable
            data={notAddedPatrons}
            list={list}
            selectable
            actionButtons={actionButtons}
          />
        </Card>
      </>
    );
  }

  renderSegmentList() {
    const {list} = this.state;
    const {SEGMENT: segment} = list;

    const data = [
      {label: "Name", value: list.NAME},
      {
        label: "Description",
        value: segment.DESCRIPTION,
        tooltip:
          "Description of this customer segment. Edit this segment to change the parameters.",
      },
      {
        label: "Use Case",
        value: list.EMAIL_ONLY === 1 ? "Email" : "Text",
        tooltip:
          "Determines which patrons can be added to this subscription list, and which campaign type can use this list",
      },
      {
        label: "Last Updated",
        value: moment(segment.DATE_UPDATED).format("M/D h:mm A"),
        tooltip:
          "Date the details of this segment were last changed. This is not affected by running the segment, only by changes to the segment details itself.",
      },
    ];

    const tooltip = {
      data: "Customer segments will be calculated on campaign send. Run this segment manually to check recipient details.",
      label: "Customer Segment",
    };

    const headingButtons = [
      {
        type: "dropdown",
        label: "Actions",
        sections: [
          {
            items: [
              {
                label: "Delete List",
                onClick: () => this.deleteSubscriptionList(),
              },
            ],
          },
        ],
      },
    ];

    return (
      <>
        <SubscriptionListDetailsModal
          onSubmit={() => this.setState({isLoading: true}, () => this.syncState())}
          ref={(e) => (this.subscriptionModal = e)}
        />
        <PageHeadings
          label={list.NAME}
          description={`View and manage this subscription list`}
          buttons={headingButtons}
        />
        <TwoColumnList
          buttons={[
            {
              label: "Edit",
              type: "gray",
              onClick: async () => this.subscriptionModal.open(list),
            },
          ]}
          label="Customer Segment"
          description="View information about this customer segment"
          tooltip={tooltip}
          data={data}
        />
        {this.renderSubscriberList(list)}
      </>
    );
  }

  renderSubscriberList(list) {
    const {SEGMENT: segment} = list;

    const actionButtons = [
      {
        label: "View Patron",
        onClick: (row) => this.props.router.navigate("/patron/" + row.ID),
      },
    ];

    const button = {
      label: "Refresh",
      onClick: async () => await this.refreshSegment(),
    };

    return (
      <Card
        label="Subscriber List"
        description={`View patrons attached to this subscription list. ${
          segment &&
          "This list was last refreshed on " +
            moment(segment.DATE_RAN).format("M/D/YY, h:mm A")
        }`}
        button={button}
      >
        <SubscriptionListTable list={list} actionButtons={actionButtons} />
      </Card>
    );
  }

  render() {
    const {isLoading, list} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    if (list.IS_DEFAULT) {
      return this.renderDefault();
    }

    if (list.SEGMENT) {
      return this.renderSegmentList();
    }

    return this.renderBody();
  }
}

export default withRouter(SubscriptionListPage);
