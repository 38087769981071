import React, {Component} from "react";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import GiftCardSettingsModal from "../../../modals/shop/shop-settings/gift-card-settings-modal";
import GiftCardFormatModal from "../../../modals/marketing/gift-cards/gift-card-format-modal";
import GiftCardCompanySettingsModal from "../../../modals/shop/shop-settings/gift-card-company-settings-modal";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import GiftCardUploadImagesModal from "../../../modals/shop/shop-settings/gift-card-upload-images-modal";
import GiftCardReorderImagesModal from "../../../modals/shop/shop-settings/gift-card-reorder-images-modal";

class GiftCardsTab extends Component {
  render() {
    let {
      PROMPT_GIFT_CARD_ATTACH,
      GIFT_CARD_PAN_MIN_LENGTH,
      GIFT_CARD_PAN_MAX_LENGTH,
      GIFT_CARD_PAN_CHARACTERS,
      GIFT_CARD_POS_UPDATE,
      GIFT_CARD_TIPPING,
    } = this.props.shop.settings;

    const {
      COMPANY_ALT_ADDRESS,
      COMPANY_ALT_ZIP,
      COMPANY_ALT_CITY,
      COMPANY_ALT_STATE,
      AUTO_GENERATE_PAN,
      GIFT_CARD_EMAIL_SUBJECT,
      GIFT_CARD_TEXT,
      GIFT_CARD_TEXT_ACCOUNT,
    } = this.props.shop.companySettings;

    const {giftCardImages} = this.props.shop;

    return (
      <div>
        <GiftCardSettingsModal ref={(e) => (this.giftCardModal = e)} />

        <GiftCardFormatModal ref={(e) => (this.giftCardFormatModal = e)} />

        <GiftCardCompanySettingsModal ref={(e) => (this.giftCardCompanyModal = e)} />

        <GiftCardUploadImagesModal ref={(e) => (this.giftCardImagesModal = e)} />

        <GiftCardReorderImagesModal ref={(e) => (this.giftCardReorderModal = e)} />

        <TwoColumnList
          label="Gift Card Settings"
          description="Configure the general gift card settings"
          data={[
            {
              label: "Gift Card Email Subject",
              value: GIFT_CARD_EMAIL_SUBJECT,
              hint: "Company Setting",
            },
            {
              label: "Gift Card Text (Customer Name is Unknown)",
              value: GIFT_CARD_TEXT,
              hint: "Company Setting",
              tooltip: {
                data: [
                  {
                    label: "Gift Card Text Overview",
                    data: "The text that a customer will receive when a gift card is created for them.",
                  },
                  {
                    label: "Text Tags",
                    data: "The following tabs contain tags that can be used when editing your gift card text.",
                  },
                  {
                    label: "{{NOTES}}",
                    data: "The notes attached to this gift card.",
                  },
                  {
                    label: "{{BALANCE}}",
                    data: "The balance in dollars of the gift card.",
                  },
                  {
                    label: "{{URL}}",
                    data: "The url that can be used to view the balance of this gift card.",
                  },
                  {
                    label: "{{LOCATION_NAME}}",
                    data: "The name of the current location.",
                  },
                  {
                    label: "{{COMPANY_NAME}}",
                    data: "The name of the company attached to this location.",
                  },
                ],
              },
            },
            {
              label: "Gift Card Text (Customer Name is Attached)",
              value: GIFT_CARD_TEXT_ACCOUNT,
              hint: "Company Setting",
              tooltip: {
                data: [
                  {
                    label: "Gift Card Text Overview",
                    data: "The text that a customer will receive when a gift card is created for them.",
                  },
                  {
                    label: "Text Tags",
                    data: "The following tabs contain tags that can be used when editing your gift card text.",
                  },
                  {
                    label: "{{NAME}}",
                    data: "The name of the customer.",
                  },
                  {
                    label: "{{NOTES}}",
                    data: "The notes attached to this gift card.",
                  },
                  {
                    label: "{{BALANCE}}",
                    data: "The balance in dollars of the gift card.",
                  },
                  {
                    label: "{{URL}}",
                    data: "The url that can be used to view the balance of this gift card.",
                  },
                  {
                    label: "{{LOCATION_NAME}}",
                    data: "The name of the current location.",
                  },
                  {
                    label: "{{COMPANY_NAME}}",
                    data: "The name of the company attached to this location.",
                  },
                ],
              },
            },
            {
              label: "Generate Digital Gift Card PAN",
              value: AUTO_GENERATE_PAN,
              type: "boolean",
              hint: "Company Setting",
              tooltip: {
                label: "Generate Digital Gift Card PAN",
                data:
                  "If this setting is turned on, a barcode scanable identifier will be generated with every gift" +
                  "card purchased on the web or mobile app. For POS purchases, an ID will be generated if there is no" +
                  "gift card number entered.",
              },
            },
            {
              label: "Prompt to Attach Gift Cards",
              value: PROMPT_GIFT_CARD_ATTACH,
              type: "bool",
              tooltip:
                "If enabled, selecting a gift card during checkout while a patron is attached will prompt you to link the gift card to that patron.",
            },
            {
              label: "Allow Balance Updates on the Point of Sale",
              value: GIFT_CARD_POS_UPDATE,
              type: "bool",
              tooltip:
                "If enabled, users can update the balance of gift cards on the point of sale.",
            },
            {
              label: "Gift Card Tipping",
              value: GIFT_CARD_TIPPING,
              type: "bool",
              tooltip: "Should tipping be prompted on the point of sale for gift cards.",
            },
          ]}
          buttons={[
            {
              label: "Edit Settings",
              onClick: () => this.giftCardModal.open(),
            },
          ]}
        />

        <TwoColumnList
          label="Gift Card Format Settings"
          description="Set an optional format to catch errors in gift card numbers"
          buttons={[
            {
              label: "Edit Format",
              onClick: () => {
                this.giftCardFormatModal.open();
              },
            },
          ]}
          data={[
            {
              label: "Gift Card Number Minimum Length",
              value: GIFT_CARD_PAN_MIN_LENGTH ?? "None",
              tooltip: {
                label: "Minimum Length",
                data:
                  "This settings allows you to set the minimum length for a gift card number. Setting this value will" +
                  " prevent you from creating new or reloading old gift cards with a gift card number that is" +
                  " shorter than his number.",
              },
              hint: "Optional",
            },
            {
              label: "Gift Card Number Maximum Length",
              value: GIFT_CARD_PAN_MAX_LENGTH ?? "None",
              hint: "Optional",
              tooltip: {
                label: "Maximum Length",
                data:
                  "This settings allows you to set the maximum length for a gift card number. Setting this value will" +
                  " prevent you from creating new or reloading old gift cards with a gift card number that is" +
                  " longer than his number.",
              },
            },
            {
              label: "Gift Card Number Characters",
              value: GIFT_CARD_PAN_CHARACTERS ?? "Any",
              hint: "Optional",
              tooltip: {
                label: "Gift Card Characters",
                data:
                  "Set the allowed characters for a gift card number. This field is case sensitive. (e.g." +
                  " 1234567890 or abcdefghijklmnopqrstuvwxyz)",
              },
            },
          ]}
        />

        <TwoColumnList
          label="Gift Receipt Settings"
          description="Settings for receipts of gift card purchases"
          buttons={[
            {
              label: "Edit Settings",
              onClick: () => {
                this.giftCardCompanyModal.open();
              },
            },
          ]}
          data={[
            {
              label: "Gift Card Purchase Address",
              value: COMPANY_ALT_ADDRESS ?? "-",
              hint: "Company Setting",
              tooltip: {
                label: "Gift Card Purchase Address",
                data: "This is the address that will be listed on all of your gift card receipts. If none is inputted then your first location's address will be listed for gift card purchases.",
              },
            },
            {
              label: "Gift Card Purchase City",
              value: COMPANY_ALT_CITY ?? "-",
              hint: "Company Setting",
            },
            {
              label: "Gift Card Purchase State",
              value: COMPANY_ALT_STATE ?? "-",
              hint: "Company Setting",
            },
            {
              label: "Gift Card Purchase Zipcode",
              value: COMPANY_ALT_ZIP ?? "-",
              hint: "Company Setting",
            },
          ]}
        />

        <TwoColumnList
          label="Custom Gift Card Images"
          description="Manage Custom Gift Card Images"
          hint="Company Setting"
          buttons={[
            {
              theme: "primary",
              label: "Edit Images",
              type: "list",
              onChange: ({id}) => {
                if (id === "upload") {
                  this.giftCardImagesModal.open();
                }

                if (id === "reorder") {
                  this.giftCardReorderModal.open();
                }
              },
              items: [
                {
                  id: "upload",
                  label: "Upload Images",
                  description:
                    "Upload custom gift card images to be displayed on all of your platforms.",
                },
                {
                  id: "reorder",
                  label: "Customize",
                  description:
                    "Enable or disable uploaded images and set the order in which gift cards appear.",
                },
              ],
            },
          ]}
          data={[
            ...giftCardImages
              .filter((image) => image.ENABLED)
              .map(({LOGO}, n) => {
                return {
                  label: "Option " + (n + 1),
                  value: (
                    <img
                      width="262"
                      height="150"
                      src={getObjectImage({LOGO}, "LOGO")}
                    ></img>
                  ),
                };
              }),
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(GiftCardsTab);
