import React, {Component} from "react";
import {withRouter} from "../../../../utils/navigation";
import {
  Card,
  Filter,
  Loading,
  PageHeadings,
  Tab,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import CardAlert from "../../../../features/card-alert";
import moment from "moment";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {
  PURCHASE_ORDER_STATUS_DISPLAYS,
  PURCHASE_ORDER_STATUSES,
} from "../../../../features/operations/supply-chain/purchase-order/purchase-order-constants";
import SupplyComingSoonPage from "../supply-coming-soon-page";
import {setupReduxConnection} from "../../../../redux";
import {PurchaseOrderRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {isSupplyChainWhitelisted} from "../../../../utils/util";

class PurchaseOrdersPage extends Component {
  state = {purchaseOrders: null};

  componentDidMount() {
    this.syncData();
  }

  async syncData() {
    let purchaseOrders = await PurchaseOrderRequests.fetchPurchaseOrders();

    purchaseOrders = purchaseOrders.filter((po) => {
      return !po.DATE_ARCHIVED;
    });

    this.setState({purchaseOrders});
  }

  fetchCreatePurchaseOrderButton() {
    return {
      label: "Create Purchase Order",
      onClick: () => this.props.router.navigate("/purchase-order-create"),
    };
  }

  renderPageHeadings() {
    return (
      <PageHeadings
        label="Purchase Order Dashboard"
        description="View and update purchase orders sent to your vendors"
        buttons={[this.fetchCreatePurchaseOrderButton()]}
      />
    );
  }

  fetchOutstandingPurchaseOrders() {
    const {purchaseOrders} = this.state;

    return purchaseOrders
      .filter((order) => {
        return order.STATUS === "SENT";
      })
      .reduce((accum, curr) => {
        return accum + curr.TOTAL;
      }, 0);
  }

  renderOpenOrdersCard() {
    return (
      <CardAlert
        label="Open Orders"
        icon="mail-bulk"
        value={toDollars(this.fetchOutstandingPurchaseOrders(), true)}
        subtext={"of outstanding orders"}
        hideView
        tooltip={{
          label: "Outstanding Order Value",
          data: "The total value of all the purchase orders that have been sent but not delivered.",
        }}
      />
    );
  }

  checkReceivedThisWeek(purchaseOrder) {
    return (
      purchaseOrder.DATE_RECEIVED &&
      moment()
        .startOf("day")
        .diff(moment(purchaseOrder.DATE_RECEIVED).startOf("day"), "weeks") < 1
    );
  }

  fetchReceivedOrders() {
    const {purchaseOrders} = this.state;

    return purchaseOrders
      .filter((order) => {
        return this.checkReceivedThisWeek(order);
      })
      .reduce((accum, curr) => {
        return accum + curr.TOTAL;
      }, 0);
  }

  renderReceivedOrdersCard() {
    return (
      <CardAlert
        label="Received this Week"
        icon="house-return"
        value={toDollars(this.fetchReceivedOrders(), true)}
        subtext={"of received orders this week"}
        hideView
        tooltip={{
          label: "Received Order Amount",
          data: "The total amount of orders that have been received in the past 7 days.",
        }}
      />
    );
  }

  checkIfOrderIsOutstanding(purchaseOrder) {
    return (
      purchaseOrder.DELIVERY_DATE &&
      moment(purchaseOrder.DELIVERY_DATE).valueOf() < moment().valueOf()
    );
  }

  fetchOrdersToReceive() {
    const {purchaseOrders} = this.state;

    return purchaseOrders.filter((purchaseOrder) =>
      this.checkIfOrderIsOutstanding(purchaseOrder)
    );
  }

  renderOutstandingOrdersCard() {
    return (
      <CardAlert
        label="Orders to Receive"
        icon="delete-right"
        value={this.fetchOrdersToReceive().length}
        subtext={"number of orders to be received"}
        hideView
        tooltip={{
          label: "Orders to Receive",
          data: "The total number of orders where the delivery date has passed but have not been marked as received on Dripos.",
        }}
        viewText={"Receive Orders"}
      />
    );
  }

  renderTopBanner() {
    return (
      <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-3">
        {this.renderOpenOrdersCard()}
        {this.renderReceivedOrdersCard()}
        {this.renderOutstandingOrdersCard()}
      </div>
    );
  }

  fetchVendorNameColumn() {
    return {value: "VENDOR_NAME", label: "TO"};
  }

  fetchPoNumberColumn() {
    return {
      value: "PO_NUMBER",
      label: "Number",
      format: (value) => {
        const stringVal = "" + value;

        return "#" + stringVal.padStart(7 - stringVal.length, "0");
      },
    };
  }

  fetchStatusColumn() {
    return {
      value: "STATUS",
      label: "Status",
      format: (status) => PURCHASE_ORDER_STATUS_DISPLAYS[status],
    };
  }

  fetchItemsColumn() {
    return {
      value: "ITEMS",
      label: "Items",
      format: (items) => items.length,
      sortable: false,
    };
  }

  fetchTotalColumn() {
    return {value: "TOTAL", label: "Total", type: "dollars"};
  }

  fetchDeliveryDateColumn() {
    return {
      value: "DELIVERY_DATE",
      label: "Delivery Date",
      empty: "-",
      format: (date) => {
        return moment(date).format("MMM DD, YYYY");
      },
    };
  }

  fetchDateCreatedColumn() {
    return {
      value: "DATE_CREATED",
      label: "Date Created",
      type: "datetime",
    };
  }

  fetchPurchaseOrderTableColumns() {
    return [
      this.fetchVendorNameColumn(),
      this.fetchPoNumberColumn(),
      this.fetchStatusColumn(),
      this.fetchItemsColumn(),
      this.fetchTotalColumn(),
      this.fetchDeliveryDateColumn(),
      this.fetchDateCreatedColumn(),
    ];
  }

  fetchViewPurchaseOrderActionButton() {
    return {
      label: "View",
      onClick: (row) => {
        this.props.router.navigate("/purchase-order/" + row.UNIQUE_ID);
      },
    };
  }

  fetchPurchaseOrderTableActionButtons() {
    return [this.fetchViewPurchaseOrderActionButton()];
  }

  renderPurchaseOrderTableBody(filters, search) {
    const {purchaseOrders} = this.state;

    return (
      <Table
        data={purchaseOrders}
        filters={filters}
        sortable
        columns={this.fetchPurchaseOrderTableColumns()}
        actionButtons={this.fetchPurchaseOrderTableActionButtons()}
        pagination
      />
    );
  }

  fetchPurchaseOrderStatusFilter() {
    return {
      id: "Status",
      label: "Status",
      options: Object.entries(PURCHASE_ORDER_STATUSES).map(([key, value]) => {
        return {id: value, label: key};
      }),
      onFilter: (options, data) => {
        return data.filter(
          (item) => options.includes(item.STATUS) || options.length === 0
        );
      },
    };
  }

  fetchPurchaseOrderTableFilters() {
    return [this.fetchPurchaseOrderStatusFilter()];
  }

  renderPurchaseOrderTable() {
    return (
      <Filter data={this.fetchPurchaseOrderTableFilters()}>
        {(filters, search) => {
          return this.renderPurchaseOrderTableBody(filters, search);
        }}
      </Filter>
    );
  }

  renderPurchaseOrdersTab() {
    return (
      <Card label="Purchase Orders" description={"View all of your purchase orders"}>
        {this.renderPurchaseOrderTable()}
      </Card>
    );
  }

  renderCurrentTab(tab) {
    if (tab === "purchase-orders") {
      return this.renderPurchaseOrdersTab();
    }
  }

  renderTab() {
    return (
      <Tab data={[{label: "Purchase Orders", id: "purchase-orders"}]}>
        {(tab) => {
          return this.renderCurrentTab(tab);
        }}
      </Tab>
    );
  }

  render() {
    const {purchaseOrders} = this.state;

    if (!isSupplyChainWhitelisted()) {
      return <SupplyComingSoonPage />;
    } else if (!purchaseOrders) {
      return <Loading />;
    }

    return (
      <>
        {this.renderPageHeadings()}
        {this.renderTopBanner()}
        {this.renderTab()}
      </>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(PurchaseOrdersPage));
