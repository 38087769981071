import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import UnitDropdown from "../../../../dropdowns/operations/recipes/unit-dropdown";
import * as Yup from "yup";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../utils/notification-helper";
import {
  ConversionRequests,
  UnitRequests,
} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {getStore} from "../../../../redux";
import {removeUnit, updateIngredient, updateUnits} from "../../../../redux/supply";
import PropTypes from "prop-types";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class RecipeUnitModal extends Component {
  state = {unit: null};

  open(unit = null) {
    this.setState({unit}, () => this.modal.open());
  }

  async saveUnit(values) {
    const {name, defaultCost} = values;
    const payload = {NAME: name, DEFAULT_COST: decimalToDollars(defaultCost)};
    const {unit} = this.state;

    await UnitRequests.updateUnit(unit.ID, payload);
    await this.updateResources();

    this.modal.close();
  }

  fetchUnitPayload(values) {
    const {ingredient} = this.props;
    const {name, abbreviation, defaultCost} = values;

    return {
      NAME: name,
      ABBREVIATION: abbreviation,
      INGREDIENT_ID: ingredient.ID,
      DEFAULT_COST: decimalToDollars(defaultCost),
    };
  }

  async _createUnit(values) {
    const payload = this.fetchUnitPayload(values);
    return UnitRequests.createUnit(payload);
  }

  fetchConversionPayload(values, serverUnit) {
    const {unit, factor} = values;

    return {
      TO_UNIT: unit,
      FROM_UNIT: serverUnit.ID,
      FACTOR: factor,
    };
  }
  async createConversions(values, serverUnit) {
    const conversionPayload = this.fetchConversionPayload(values, serverUnit);
    await ConversionRequests.createConversion(conversionPayload);
  }

  async updateResources() {
    const {ingredient} = this.props;
    await getStore().dispatch(updateUnits());

    if (ingredient) {
      await getStore().dispatch(updateIngredient(ingredient.ID));
    }
  }

  async createUnit(values) {
    const serverUnit = await this._createUnit(values);
    await this.createConversions(values, serverUnit);
    await this.updateResources();
    this.modal.close();
  }

  removeUnitFromRedux() {
    const {unit} = this.state;
    const {ingredient} = this.props;

    getStore().dispatch(removeUnit(unit.ID));

    if (ingredient) {
      getStore().dispatch(updateIngredient(ingredient.ID));
    }
  }

  onDeleteSuccess() {
    const {unit} = this.state;
    this.removeUnitFromRedux();

    showSuccessNotification(`The ${unit.NAME} unit was deleted successfully`);
  }

  async requestDeleteUnit() {
    const {unit} = this.state;

    await UnitRequests.deleteUnit(unit.ID);
  }

  showInUseError() {
    return showErrorNotification(
      "Could not archive ingredient.",
      "This unit is used as a measurement unit for one or more ingredients and cannot be deleted."
    );
  }

  showGenericError() {
    return showErrorNotification(
      "Something went wrong.",
      "Something went wrong when archiving this unit. Please try again."
    );
  }

  onDeleteFailure(err) {
    if (err.error === "HAS_INGREDIENTS") {
      this.showInUseError();
    } else {
      this.showGenericError();
    }
  }

  async deleteUnit() {
    try {
      await this.requestDeleteUnit();
      this.onDeleteSuccess();
    } catch (err) {
      this.onDeleteFailure(err);
    } finally {
      this.modal.close();
    }
  }

  renderNameInput(formikOptions) {
    return (
      <FormInput
        label="Name"
        name="name"
        options={formikOptions}
        tooltip="The name of the unit that you are creating. This name will be used to identify quantities when creating transfers and vendor items."
      />
    );
  }

  renderConversionFactorInput(formikOptions) {
    const {ingredient} = this.props;
    const {unit} = this.state;

    return (
      <FormInput
        label="Conversion Factor"
        name="factor"
        disabled={ingredient.UNIT_ID === unit?.ID}
        options={formikOptions}
        tooltip="The number the selected conversion unit that makes up one of these units. For example, if this unit is a 10 pack of gallons of milk, the conversion unit would be gallons and the conversion factor would be 10."
      />
    );
  }

  renderDefaultCostInput(formikOptions) {
    const {unit} = this.state;

    return (
      <FormInput
        label="Default Cost"
        name="defaultCost"
        disabled={unit && !unit.COMPANY_ID}
        options={formikOptions}
        tooltip="The default cost that will display when quick stocking an ingredient using this unit. This feature is not available for template units such as gallons, pounds, etc."
      />
    );
  }

  renderConversionUnitInput(formikOptions) {
    const {ingredient} = this.props;
    const {unit} = this.state;

    return (
      <UnitDropdown
        label="Conversion Unit"
        name="unit"
        ingredient={ingredient}
        disabled={ingredient.UNIT_ID === unit?.ID}
        options={formikOptions}
        tooltip="The unit to which we would like to convert. For example, if this unit is a 10 pack of gallons of milk, the conversion unit would be gallons and the conversion factor would be 10."
        unit={ingredient.UNIT_ID}
      />
    );
  }

  renderFormBody(formikOptions) {
    const {handleSubmit} = formikOptions;

    return (
      <form onSubmit={handleSubmit}>
        {this.renderNameInput(formikOptions)}
        {this.renderDefaultCostInput(formikOptions)}
        {this.renderConversionFactorInput(formikOptions)}
        {this.renderConversionUnitInput(formikOptions)}
      </form>
    );
  }

  fetchValidationSchema() {
    const {unit} = this.state;

    return Yup.object().shape({
      name: Yup.string().nullable().required("Please enter a name for this unit."),
      factor: Yup.number()
        .nullable()
        .required("Please select a conversion factor for this unit."),
      unit: Yup.number().nullable().required("Please select a conversion unit."),
      defaultCost: Yup.number()
        .nullable()
        .test("Default Cost is Required", "Please enter a default cost", (value) => {
          return (unit && unit.COMPANY_ID) || value != null;
        }),
    });
  }

  fetchInitialValues() {
    const {unit} = this.state;
    const {ingredient} = this.props;

    return {
      name: unit?.NAME,
      factor: unit?.CONVERSIONS.find((c) => c.TO_UNIT === ingredient.UNIT_ID)?.RATE ?? 1,
      unit: ingredient.UNIT_ID,
      defaultCost: unit ? toDollars(unit.DEFAULT_COST) : "0",
    };
  }

  renderForm() {
    const {unit} = this.state;

    return (
      <Formik
        onSubmit={(values) => (unit ? this.saveUnit(values) : this.createUnit(values))}
        enableReinitialize
        innerRef={(e) => (this.formikRef = e)}
        initialValues={this.fetchInitialValues()}
        validationSchema={this.fetchValidationSchema()}
      >
        {(formikOptions) => {
          return this.renderFormBody(formikOptions);
        }}
      </Formik>
    );
  }

  render() {
    const {unit} = this.state;
    const {ingredient} = this.props;

    return (
      <Modal
        buttonLabel={unit ? "Save" : "Add"}
        label={unit ? "Edit Unit" : "Create Unit"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        deleteLabel={ingredient.UNIT_ID !== unit?.ID ? "Delete" : ""}
        deleteOnClick={() => this.deleteUnit()}
      >
        {this.renderForm()}
      </Modal>
    );
  }
}

RecipeUnitModal.propTypes = {
  syncState: PropTypes.func.isRequired,
  ingredient: PropTypes.object,
};

export default RecipeUnitModal;
