import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import * as Yup from "yup";
import ComboBox from "../../../components/combobox";
import {setupReduxConnection} from "../../../redux";
import {SupplyChainUnitRequests} from "../../../utils/request-helpers/supply-chain/supply-chain-requests";

class SupplyChainUnitModal extends Component {
  state = {toUnit: null, units: []};

  async componentDidMount() {
    const units = await SupplyChainUnitRequests.fetchSupplyChainUnits();
    const processedUnits = units.map((unit) => {
      return {
        id: unit?.UNIQUE_ID,
        label: unit?.NAME,
        content: unit,
      };
    });

    this.setState({units: processedUnits});
  }

  open(unit = null) {
    this.setState({unit}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  SupplyChainDropDownForUnit = ({formikOptions}) => {
    const {setFieldValue} = formikOptions;
    const {units} = this.state;

    const dataDictionary = Object.fromEntries(units.map((item) => [item.id, item]));

    return (
      <ComboBox
        label="Units"
        data={units}
        value={formikOptions.values.toUnit}
        onChange={(selectedId) => {
          setFieldValue("toUnit", selectedId);
          this.setState({toUnit: dataDictionary[selectedId]});
        }}
      />
    );
  };

  async createIngredientUnit({name, conversion}) {
    const {syncState} = this.props;

    const unitToCreate = {
      NAME: name,
      TO_UNIT: this.state.toUnit.content,
      CONVERSION_RATIO: parseFloat(conversion),
    };

    await SupplyChainUnitRequests.createSupplyChainUnit(unitToCreate);

    await syncState();
    this.modal.close();
  }

  async saveIngredientUnit({name, conversion}) {
    const {unit} = this.state;
    const {syncState} = this.props;

    const unitToUpdate = {
      NAME: name,
      UNIT: unit,
      TO_UNIT: this.state.toUnit.content,
      CONVERSION_RATIO: parseFloat(conversion),
    };

    await SupplyChainUnitRequests.updateSupplyChainUnit(unit.FROM_UNIT_ID, unitToUpdate);

    await syncState();
    this.modal.close();
  }

  render() {
    const {unit} = this.state;

    const validationSchema = Yup.object({
      name: Yup.string().required("Unit name is required"),
      conversion: Yup.string().required("Conversion rate is required"),
    });

    const initialValues = {
      name: unit?.FROM_UNIT_NAME ?? "",
      toUnit: unit?.TO_UNIQUE_ID ?? unit?.SI_UNIT_ID,
      conversion: unit?.SI_UNIT_CONVERSION_RATIO ?? unit?.CONVERSION_RATIO,
    };

    return (
      <Modal
        buttonLabel={unit ? "Save" : "Add"}
        label={unit ? "Edit Supply Chain Unit" : "Save Supply Chain Unit"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={
            unit
              ? this.saveIngredientUnit.bind(this)
              : this.createIngredientUnit.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  options={formikOptions}
                  placeholder="Ounces"
                  label="Unit Name"
                  name="name"
                />

                <this.SupplyChainDropDownForUnit formikOptions={formikOptions} />

                <FormInput
                  options={formikOptions}
                  placeholder="2.5"
                  label="Conversion Rate"
                  name="conversion"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(SupplyChainUnitModal);
