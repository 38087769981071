import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import RolesDropdown from "../../../dropdowns/team/roles-dropdown";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";

class EmployeeRoleModal extends Component {
  state = {role: null};

  open(role = null) {
    this.setState({role}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createRole({role}) {
    const {employee} = this.props;

    const serverEmployeeRole = await request(
      "employeeroles/employee/" + employee.ID,
      "POST",
      {
        ROLE_ID: role,
      }
    );

    const serverRole = this.roleRef.fetchRole();

    this.props.addState({
      Roles: serverRole,
      Employee_Roles: serverEmployeeRole,
    });

    this.modal.close();
  }

  async deleteRole() {
    const {role} = this.state;
    const {employee} = this.props;

    showLoadingConfirmAlert(
      "Delete Role",
      "Deleting this role will convert all upcoming employee shifts with this role into open shifts. Are you sure you want to continue?"
    )
      .then(async (close) => {
        try {
          await request("employeeroles/employee/" + employee.ID, "DELETE", {
            EMPLOYEE_ROLE_ID: role.EMPLOYEE_ROLE_ID,
          });

          this.props.removeState(role);
        } catch (err) {
          if (err.code === 213) {
            alert("Every employee must have at least one role.");

            return this.modal.close();
          }
        }

        close();
      })
      .catch((err) => {});

    this.modal.close();
  }

  render() {
    const {roles} = this.props;
    const {role} = this.state;

    const exclude = roles && !role ? roles.map((item) => item.Roles.ID) : [];

    return (
      <Modal
        tooltip={{
          data: `${
            role
              ? "Delete this role from this employee"
              : "Add a new role to this employee"
          }`,
        }}
        buttonLabel={role ? null : "Add"}
        deleteLabel={role ? "Remove" : null}
        label={role ? "Delete Employee Role" : "Add Employee Role"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        deleteOnClick={() => this.deleteRole()}
      >
        <Formik
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={this.createRole.bind(this)}
          initialValues={{
            role: role ? role.ID : null,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <RolesDropdown
                  name="role"
                  options={formikOptions}
                  exclude={exclude}
                  disabled={!!role}
                  role={role}
                  ref={(e) => (this.roleRef = e)}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

EmployeeRoleModal.propTypes = {
  onSave: PropTypes.func,
};

export default EmployeeRoleModal;
