import React, {Component} from "react";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {
  CELL_TYPES,
  COGS_COLUMNS,
} from "../../features/reporting/reports/reporting-constants";
import ReportingGraphContainer from "../../features/reporting/graphs/reporting-graph-container";
import ReportingSalesGraph, {
  REPORTING_GRAPH_TYPES,
} from "../../features/reporting/graphs/reporting-sales-graph";
import {setupReduxConnection} from "../../redux";
import SingleReportingTooltip from "../../features/reporting/graphs/tooltips/single-reporting-tooltip";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ReportingProductProfitabilityGraph from "../../features/reporting/graphs/reporting-product-profitability-graph";
import ReportingProductIngredientUsageGraph from "../../features/reporting/graphs/reporting-product-ingredient-usage-graph";
import {isSupplyChainWhitelisted} from "../../utils/util";
import SupplyComingSoonPage from "../operations/supply-chain/supply-coming-soon-page";

class CogsReportPage extends Component {
  convertDataToRows = (reportData) => {
    const {NUMBERS: numbers, TOTALS: totals} = reportData;

    const rows = numbers.map((row) => {
      return {
        label: (
          <div className="text-left">
            <div className="font-semibold">{row.NAME}</div>
          </div>
        ),
        numbers: row.TIMESPAN,
        style: CELL_TYPES.REGULAR,
        expandCell: (row) => {
          return (
            <ReportingProductIngredientUsageGraph
              data={Object.values(row.numbers.INGREDIENTS)
                .filter((ingredient) => ingredient.INGREDIENT_TYPE === "STANDARD")
                .map((ingredient) => {
                  return {name: ingredient.INGREDIENT_NAME, value: ingredient.COST};
                })}
            />
          );
        },
      };
    });

    rows.push({
      label: "Total",
      numbers: totals.TIMESPAN,
      style: CELL_TYPES.BOLD,
    });

    return rows;
  };

  renderCogsGraph(reportData) {
    return (
      <div>
        <ReportingSalesGraph
          height={150}
          displayAsPercentage={true}
          datasets={[
            {
              id: "base",
              rawData: reportData.TOTALS,
              dataKey: "GROSS_MARGIN_PERCENTAGE",
              countKey: "NET_SALES",
              extraKeys: [
                {
                  keyValue: "COGS",
                  keyName: "costOfGoods",
                },
              ],
              stroke: "#4e46e5",
              strokeWidth: 2,
              fill: "#FFFFFF00",
              fillOpacity: 0.7,
              stopNow: true,
              type: "monotone",
            },
          ]}
          tooltip={
            <SingleReportingTooltip
              formatLabel={(payload) => {
                return payload[`baseDate`];
              }}
              rows={[
                {
                  label: "Sales",
                  formatValue: (payload) => {
                    return toDollars(payload[`baseCount`], true);
                  },
                },
                {
                  label: "Cost of Goods",
                  formatValue: (payload) => {
                    return toDollars(payload[`costOfGoods`], true);
                  },
                },
                {
                  label: "Gross Margin",
                  formatValue: (payload) => (payload[`baseValue`] * 100).toFixed(2) + "%",
                },
              ]}
            />
          }
          type={REPORTING_GRAPH_TYPES.AREA.id}
          dataKey={"GROSS_MARGIN"}
          countKey={"PAYMENT_COUNT"}
          ignoreWeekdayLabels={true}
        />
      </div>
    );
  }

  renderProductUsageGraph(reportData) {
    return (
      <ReportingProductProfitabilityGraph
        data={Object.values(reportData.TOTALS.TIMESPAN.GROUPED_LINE_ITEMS)}
      />
    );
  }

  renderGraph(reportData) {
    const {lastStartEpoch, lastEndEpoch} = this.props.reporting;

    return (
      <div>
        <ReportingGraphContainer
          className="mb-4"
          graph={this.renderCogsGraph(reportData)}
        />

        <ReportingGraphContainer
          className="mb-4"
          graph={this.renderProductUsageGraph(reportData)}
        />
      </div>
    );
  }

  render() {
    if (!isSupplyChainWhitelisted()) {
      return <SupplyComingSoonPage />;
    }

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label="Cost of Goods Sold Report" />
        </div>

        <FixedColumnReport
          filenamePrefix="cost-of-goods"
          endpoint="report/cogs"
          constantColumns={COGS_COLUMNS}
          convertDataToRows={this.convertDataToRows}
          expandable
          graph={(reportData) => this.renderGraph(reportData)}
          locationPicker
          firstColLabel="Ingredient Name"
          firstColTooltip="This column lists the names of all ingredients that have been used during the selected time period."
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "reporting"])(CogsReportPage);
