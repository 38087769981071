import {request} from "../../request";

export const TeamsRequests = {
  fetchBlackoutDates: async () => {
    return request("timeoff/blackout/", "GET");
  },
  fetchBreakOptions: async () => {
    return request("team/break-option/company", "GET");
  },
};
