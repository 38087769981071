import React, {Component} from "react";
import {RUN_MODES} from "../../../../redux/payroll";
import {setupReduxConnection} from "../../../../redux";
import {withRouter} from "../../../../utils/navigation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DangerBadge from "../../../../components/badges/danger-badge";
import {showConfirmAlert, showErrorAlert} from "../../../../utils/alert-helper";
import LoadingSpinner from "../../../../components/loading-spinner";
import {reopenPayroll} from "../../../../utils/payroll-helper";
import {
  Card,
  Loading,
  PageHeadings,
} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment-timezone";

class PayrollDashboardPage extends Component {
  state = {reopening: false};

  componentDidMount() {
    let {PAYROLL_MAIN} = this.props.shop.location;

    if (!PAYROLL_MAIN) {
      this.props.router.navigate("/employees");
    }

    this.refreshPayroll();
  }

  refreshPayroll() {
    this.props.loadPayroll();
  }

  async reopenPayrun() {
    let {currentPayrun} = this.props.payroll;

    this.setState({reopening: true});

    try {
      await reopenPayroll(currentPayrun);
    } catch (e) {
      showErrorAlert(
        "Cannot Reopen",
        "This payroll cannot be reopened please reach support to make changes.",
        "Ok"
      );
    }
    this.refreshPayroll();
  }

  checkIsCompanyNotBlocked() {
    let {company} = this.props.payroll;

    if (company?.onboard?.status === "blocking") {
      showErrorAlert(
        "Payroll Setup Incomplete",
        "You're not able to run payroll because we're missing some information. Click on 'Tax and Authorization Settings' on the Settings page to complete the necessary steps."
      );

      return false;
    }
    return true;
  }

  dateString(number) {
    if (number === 1) {
      return "tomorrow";
    } else {
      return `in ${number} days`;
    }
  }

  renderRunPayroll() {
    let {reopening} = this.state;
    let {currentPayrun} = this.props.payroll;

    if (!currentPayrun) {
      return (
        <div className="flex flex-col items-center py-10">
          <span className="text-xl font-bold">No Pay Schedule Setup</span>
          <span className="mt-3 text-gray-700">
            Dripos will add your pay schedule after your initial consultation.
          </span>
        </div>
      );
    }

    let periodStr, paydayStr;

    let {
      PAYDAY,
      REALIZED_PAYDAY,
      PERIOD_START,
      PERIOD_END,
      APPROVAL_DEADLINE,
      PAYROLL_STATUS,
    } = currentPayrun;

    let daysUntilPeriodClose = moment(PERIOD_END)
      .startOf("day")
      .diff(moment().startOf("day").valueOf(), "days");

    periodStr =
      moment(PERIOD_START).format("MMM D") +
      " - " +
      moment(PERIOD_END).format("MMM D, YYYY");

    let date = `${moment(APPROVAL_DEADLINE).format("h:mm A")} on ${moment(
      APPROVAL_DEADLINE
    ).format("ddd, MMM Do")}`;

    if (PAYROLL_STATUS === "late") {
      date = <DangerBadge className={"text-sm mr-12"}>Late</DangerBadge>;
    }

    if (
      PAYROLL_STATUS === "period_open" ||
      PAYROLL_STATUS === "needs_run" ||
      PAYROLL_STATUS === "late"
    ) {
      return (
        <div className="py-10 flex justify-center items-center">
          <div className="space-y-5">
            <div className="space-x-7">
              <span className="font-semibold text-2xl">Regular Payroll</span>
              <span className="font-medium text-gray-700">{periodStr}</span>
            </div>

            <div className="flex-grow h-px bg-gray-200"></div>

            <div className="flex flex-row justify-between">
              {[
                {
                  label: "Run by",
                  date,
                },
                {label: "Payday", date: moment(PAYDAY).format("ddd, MMM Do")},
              ].map((item) => (
                <div>
                  <div className="text-gray-700 text-sm font-medium">
                    {item.label}
                  </div>
                  <div className="text-black text-md font-medium">
                    {item.date}
                  </div>
                </div>
              ))}
            </div>

            {PAYROLL_STATUS === "period_open" && (
              <div className="text-indigo-500 font-medium">
                Your pay period ends {this.dateString(daysUntilPeriodClose)}
              </div>
            )}

            {PAYROLL_STATUS === "needs_run" && (
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-bold rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  if (this.checkIsCompanyNotBlocked()) {
                    this.props.setRunMode(RUN_MODES.REGULAR);
                    this.props.router.navigate("/payroll/run");
                  }
                }}
              >
                {"Run Payroll"}
              </button>
            )}

            {PAYROLL_STATUS === "late" && (
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-bold rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  if (this.checkIsCompanyNotBlocked()) {
                    this.props.setRunMode(RUN_MODES.MISSED);
                    this.props.router.navigate("/payroll/run");
                  }
                }}
              >
                {"Run Payroll"}
              </button>
            )}
          </div>
        </div>
      );
    }

    if (
      PAYROLL_STATUS === "approved" ||
      PAYROLL_STATUS === "processing" ||
      PAYROLL_STATUS === "paid"
    ) {
      let label = "Your employees are getting paid on time!";

      if (PAYDAY !== REALIZED_PAYDAY) {
        label = "Your employees are getting paid late";
      }

      let description = `They'll be paid on ${moment(REALIZED_PAYDAY).format(
        "dddd, MMM D"
      )}. We'll
      automatically notify you when it's time to run your next payroll.`;

      if (PAYROLL_STATUS === "paid") {
        label = "Your employees have been paid!";
        description =
          "We'll automatically notify you when it's time to run your next payroll.";
      }

      return (
        <div className="flex flex-col justify-center items-center py-10 space-y-4">
          <FontAwesomeIcon
            icon="square-check"
            className="mx-auto h-12 w-12 text-green-500"
          />

          <div className="text-center space-y-1">
            <div className="font-bold text-xl">{label}</div>

            <div className="text-md font-semibold text-gray-500">
              {description}
            </div>
          </div>

          {PAYROLL_STATUS === "approved" && (
            <div>
              {!reopening && (
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base font-bold rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => {
                    showConfirmAlert(
                      "Reopen Payrun",
                      `Are you sure you want to reopen this payrun? You'll need to run payroll again to pay your employees`
                    ).then((res) => this.reopenPayrun());
                  }}
                >
                  {"Reopen Payrun"}
                </button>
              )}
              {reopening && <LoadingSpinner />}
            </div>
          )}
        </div>
      );
    }
  }

  renderOtherOptionCard(icon, title, description, action, onClick) {
    return (
      <div className="" onClick={() => onClick()}>
        <Card className={"relative p-4 h-48 cursor-pointer"}>
          <FontAwesomeIcon
            icon={icon}
            className="h-8 w-8 text-indigo-500 py-2"
          />

          <div className={"font-semibold text-lg"}>{title}</div>
          <div className="text-sm">{description}</div>

          <div className="absolute bottom-4 text-sm font-semibold text-indigo-500 underline">
            {action}
          </div>
        </Card>
      </div>
    );
  }

  render() {
    let {loaded} = this.props.payroll;

    if (!loaded) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings label="Run Payroll" />

        <Card>
          <div>{this.renderRunPayroll()}</div>
        </Card>

        <div className="pt-4">
          <div className="mt-4 text-xl font-bold">Other Payroll Options</div>

          <div className="grid grid-cols-1  md:grid-cols-2 xl:grid-cols-4 gap-4">
            {this.renderOtherOptionCard(
              "gift",
              "Bonus Payroll",
              "Reward team members for their hard work",
              "Run Bonus Payroll",
              () => {
                if (this.checkIsCompanyNotBlocked()) {
                  this.props.updateRunMode(RUN_MODES.BONUS);
                  this.props.router.navigate("/payroll/run");
                }
              }
            )}

            {this.renderOtherOptionCard(
              "square-dollar",
              "Off-Cycle",
              "Run a payroll outside of your regular pay schedule",
              "Run Off-Cycle Payroll",
              () => {
                if (this.checkIsCompanyNotBlocked()) {
                  this.props.updateRunMode(RUN_MODES.OFF_CYCLE);
                  this.props.router.navigate("/payroll/run");
                }
              }
            )}

            {this.renderOtherOptionCard(
              "users",
              "Pay Contractors",
              "Make payments to your contractors",
              "Create Payment",
              () => {
                if (this.checkIsCompanyNotBlocked()) {
                  this.props.updateRunMode(RUN_MODES.CONTRACTORS);
                  this.props.router.navigate("/payroll/run");
                }
              }
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["shop", "payroll"])(
  withRouter(PayrollDashboardPage)
);
