import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {withRouter} from "../../../utils/navigation";

class RecipeExpandableComponent extends Component {
  render() {
    const {stock, ingredient} = this.props;

    return (
      <div className="mx-10 my-3 mb-10 text-sm border">
        <Table
          hideBorder
          data={JSON.parse(
            JSON.stringify(stock.CHILDREN.filter((child) => !child.SOURCE_OBJECT_ID))
          )}
          columns={[
            {
              value: "NAME",
              label: "Name",
              format: (val, row) => {
                return (
                  <div
                    className="text-md font-semibold text-indigo-500 hover:text-indigo-700 hover:cursor-pointer"
                    onClick={() =>
                      this.props.router.navigate(
                        "/recipes/ingredient/" + row.INGREDIENT_UNIQUE_ID
                      )
                    }
                  >
                    {val}{" "}
                  </div>
                );
              },
            },
            {
              label: "Quantity",
              value: "QUANTITY",
              format: (q, row) => {
                return q + " " + row.UNIT_NAME;
              },
            },
            {
              label: "Cost",
              value: "COST",
              format: (d, row) => {
                return toDollars(d * row.QUANTITY, true);
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default withRouter(RecipeExpandableComponent);
