import {request} from "../../request";

export const MarketingRequests = {
  fetchList: async (uniqueId) => {
    return request("text/marketing/list", "GET", {UNIQUE_ID: uniqueId});
  },
  fetchDefaultList: async () => {
    return request("text/marketing/list", "GET");
  },
  deleteList: async (listId) => {
    return request("text/marketing/list/" + listId, "DELETE", null);
  },
  addPatronToList: async (listId, patrons) => {
    return request("text/marketing/list/patrons/", "POST", {
      LIST_ID: listId,
      PATRONS: patrons,
    });
  },
  getMarketingLists: async () => {
    return request("text/marketing/lists", "GET");
  }
};
