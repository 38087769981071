import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class InventoryCountTable extends Component {
  getColumns(options) {
    const {values} = options;
    const {maxUnits} = this.props;

    return [
      {
        value: "NAME",
        label: "Name",
        width: 200,
      },
      {
        value: "_",
        label: "Total",
        width: 100,
        format: (_, row) => {
          return toDollars(
            row.VENDOR_ITEMS.reduce((accum, item) => {
              const data = values.items[item.ID] ?? 0;
              return data * item.PRICE_PER_CASE + accum;
            }, 0),
            true
          );
        },
      },
      {
        value: "CURRENT_STOCK",
        label: "Expected Quantity",
        width: 150,
      },
      {
        value: "_",
        label: "Quantity",
        width: 150,
        format: (_, row) => {
          return row.VENDOR_ITEMS.reduce((accum, item) => {
            const data = values.items[item.ID] ?? 0;
            let factor = 1;

            if (item.UNIT_ID !== row.UNIT_ID) {
              const conversion = row.UNIT.CONVERSIONS.find(
                (conversion) => conversion.TO_UNIT === item.UNIT_ID
              );

              factor = 1 / conversion.RATE;
            }

            return data * item.CASE_SIZE * factor + accum;
          }, 0);
        },
      },
      ...Array(maxUnits)
        .fill()
        .map((_, index) => {
          return {
            value: "_",
            label: "Unit " + (index + 1),
            width: 150,
            format: (_, row) => {
              const column = row.VENDOR_ITEMS[index];

              if (!column) {
                return <div />;
              }

              return (
                <div className="flex flex-row w-96 items-center">
                  <input
                    type="text"
                    id={"unit_" + column.ID}
                    value={values.items[column.ID]}
                    data-event-off="mouseleave"
                    data-event="mouseenter click"
                    className="flex py-2 mr-3 w-24 text-sm focus:ring-2 border-neutral-border focus:border-0 focus:ring-indigo-500 rounded-md"
                    onChange={(e) => {
                      options.setFieldValue("items", {
                        ...options.values.items,
                        [column.ID]: e.target.value,
                      });
                      // options.setFieldValue(column.ID, e.target.value);
                    }}
                    placeholder="Quantity"
                    onFocus={function autoSelect(e) {
                      e.target.select();
                    }}
                  />

                  <div className="text-nowrap whitespace-nowrap mr-2">{column.NAME}</div>
                </div>
              );
            },
          };
        }),
    ];
  }

  render() {
    const {counts = [], options} = this.props;

    return (
      <Table
        data={counts}
        columns={this.getColumns(options)}
        searchFields={["NAME"]}
        {...this.props}
      />
    );
  }
}

export default InventoryCountTable;
