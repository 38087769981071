import React, {Component} from "react";
import {
  FormInput,
  FormSelect,
  FormTextArea,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  decimalToDollars,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import Banner from "../../../components/banner";
import {setupReduxConnection} from "../../../redux";
import {PROMOTION_TYPES} from "@frostbyte-technologies/frostbyte-tickets/src/helpers/promotion-helper";
import PromotionForm from "../../../forms/marketing/promotions/promotion-form";
import {TEXT_CAMPAIGN_TYPES} from "../../../utils/marketing-constants";

class TextCampaignModal extends Component {
  state = {campaign: null, addCoupon: false, type: TEXT_CAMPAIGN_TYPES.ONE_OFF};

  open(campaign = null, type = TEXT_CAMPAIGN_TYPES.ONE_OFF) {
    this.setState({campaign, addCoupon: !!campaign?.PROMOTION_ID, type}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async saveCampaign(values) {
    const {campaign, addCoupon} = this.state;
    const {campaignName: name, content, campaignType: type, listId} = values;

    if (!addCoupon && !!campaign.PROMOTION_ID) {
      await this.deletePromotion(campaign.PROMOTION_ID);
      delete campaign.PROMOTION_ID;
    }

    if (addCoupon) {
      if (campaign.PROMOTION_ID) {
        await this.updatePromotion(campaign.PROMOTION_ID, values);
      } else {
        const promo = await this.createPromotion(values);
        campaign.PROMOTION_ID = promo.ID;
      }
    }

    const serverCampaign = await request(
      "text/marketing/campaign/" + campaign.ID,
      "PATCH",
      {
        NAME: name,
        CONTENT: content + "\n{{UNSUBSCRIBE}}",
        TYPE: type,
        LIST_ID: listId,
        PROMOTION_ID: campaign.PROMOTION_ID,
        DATE_SCHEDULED: campaign?.DATE_SCHEDULED,
      }
    );

    this.props.updateState(serverCampaign);
    this.modal.close();
  }

  async deletePromotion(id) {
    return request("promotions/" + id, "DELETE");
  }

  async updatePromotion(id, values) {
    const {
      name,
      duration,
      start,
      end,
      days,
      type,
      products,
      categories,
      discount,
      company,
      flat,
      percentage,
      oncePer,
    } = values;

    let promotionPayload = {
      NAME: name,

      DATE_START: duration === "range" ? start : null,
      DATE_END: duration === "range" ? end : null,
      DAYS_VALID: duration === "days" ? days : null,

      TYPE: type,
      IS_COMPANY_WIDE: company === "1",
      ENABLED: true,
      ONCE_PER_ORDER: oncePer === "1",

      DISCOUNT: {
        FLAT_FEE: discount === "flat" ? decimalToDollars(flat) : null,
        PERCENTAGE: discount === "percentage" ? percentage : null,
      },
    };

    if (type === PROMOTION_TYPES.PRODUCT) {
      promotionPayload.PRODUCTS = products;
    }

    if (type === PROMOTION_TYPES.CATEGORY) {
      promotionPayload.CATEGORIES = categories;
    }

    return request("promotions/" + id, "PATCH", promotionPayload);
  }

  async createPromotion(values) {
    const {
      name,
      duration,
      start,
      end,
      days,
      type,
      products,
      categories,
      discount,
      company,
      flat,
      percentage,
      oncePer,
    } = values;

    let promotionPayload = {
      NAME: name,

      DATE_START: duration === "range" ? start : null,
      DATE_END: duration === "range" ? end : null,
      DAYS_VALID: duration === "days" ? days : null,

      TYPE: type,
      IS_COMPANY_WIDE: company === "1",
      ENABLED: true,
      ONCE_PER_ORDER: oncePer === "1",

      DISCOUNT: {
        FLAT_FEE: discount === "flat" ? decimalToDollars(flat) : null,
        PERCENTAGE: discount === "percentage" ? percentage : null,
      },
    };

    if (type === PROMOTION_TYPES.PRODUCT) {
      promotionPayload.PRODUCTS = products;
    }

    if (type === PROMOTION_TYPES.CATEGORY) {
      promotionPayload.CATEGORIES = categories;
    }

    return request("promotions", "POST", promotionPayload);
  }

  async createCampaign(values) {
    const {campaignName: name, content, campaignType: type, listId} = values;
    const {addCoupon} = this.state;
    let promo;

    if (addCoupon) {
      promo = await this.createPromotion(values);
    }

    const serverCampaign = await request("text/marketing/campaign/", "POST", {
      CONTENT: content + "\n{{UNSUBSCRIBE}}",
      TYPE: type,
      NAME: name,
      LIST_ID: listId,
      PROMOTION_ID: addCoupon ? promo.ID : null,
    });

    this.props.updateState(serverCampaign);
    this.modal.close();
  }

  insertText(newText, wholeText = "") {
    const selectionStart = this.formTextArea.textAreaRef.selectionStart;
    const selectionEnd = this.formTextArea.textAreaRef.selectionEnd;

    return (
      wholeText.substring(0, selectionStart) +
      newText +
      wholeText.substring(selectionEnd, wholeText.length)
    );
  }

  render() {
    const {campaign, addCoupon, type} = this.state;
    let promotion = campaign?.PROMOTION;
    const {subscriptionLists} = this.props;

    let defaultDiscount = "flat";
    if (promotion?.DISCOUNT?.PERCENTAGE) {
      defaultDiscount = "percentage";
    }

    const isMac = window.navigator?.oscpu?.toLowerCase()?.includes("mac");

    return (
      <Modal
        buttonLabel="Save Draft"
        xlarge
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        label={
          campaign
            ? `Update ${
                campaign.TYPE === TEXT_CAMPAIGN_TYPES.BIRTHDAY
                  ? "Birthday"
                  : campaign.TYPE === TEXT_CAMPAIGN_TYPES.WELCOME
                  ? "Welcome"
                  : campaign.TYPE === TEXT_CAMPAIGN_TYPES.ONE_OFF
                  ? "One Off"
                  : "Text"
              } Campaign`
            : `Create ${
                type === TEXT_CAMPAIGN_TYPES.BIRTHDAY
                  ? "Birthday"
                  : type === TEXT_CAMPAIGN_TYPES.WELCOME
                  ? "Welcome"
                  : type === TEXT_CAMPAIGN_TYPES.ONE_OFF
                  ? "One Off"
                  : "Text"
              } Campaign`
        }
      >
        <Formik
          onSubmit={
            campaign ? this.saveCampaign.bind(this) : this.createCampaign.bind(this)
          }
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            campaignType: Yup.string().required("Type is required"),
            content: Yup.string()
              .required("Content is required")
              .nullable()
              .test(
                "content-code",
                'If you have a coupon, the text content must contain "{{CODE}}" where the coupon code should be' +
                  " displayed in the text.",
                (value, _) => !addCoupon || value?.includes("{{CODE}}")
              ),
            listId:
              type === TEXT_CAMPAIGN_TYPES.WELCOME ||
              campaign?.TYPE === TEXT_CAMPAIGN_TYPES.WELCOME
                ? Yup.string().nullable()
                : Yup.string().nullable().required("Subscriber List is required"),
            duration: Yup.string()
              .nullable()
              .test("dur-req", "Duration required.", (value, _) => !addCoupon || !!value),
            start: Yup.string()
              .nullable()
              .test(
                "start-req",
                "Start is required if duration is not Forever.",
                (value, _) =>
                  !addCoupon || this.formikRef?.values?.duration !== "range" || !!value
              ),
            end: Yup.string().nullable(),
            days: Yup.number().integer().nullable(),
            discount: Yup.string()
              .nullable()
              .test(
                "disc-type",
                "Discount type is required.",
                (value, _) => !addCoupon || !!value
              ),
            flat: Yup.number()
              .nullable()
              .test(
                "flat-req",
                "Flat Amount is required.",
                (value, ctx) =>
                  !addCoupon ||
                  ctx.parent.discount !== "flat" ||
                  (value !== null && value !== undefined && value !== "")
              ),
            percentage: Yup.number()
              .nullable()
              .test(
                "pct-req",
                "Percentage is required.",
                (value, ctx) =>
                  !addCoupon ||
                  ctx.parent.discount !== "percentage" ||
                  (value !== null && value !== undefined && value !== "")
              ),
            products: Yup.array()
              .nullable()
              .test(
                "product-req",
                "You must select at least one product.",
                (value, ctx) =>
                  !addCoupon ||
                  ctx.parent.type !== PROMOTION_TYPES.PRODUCT ||
                  (value !== null && value !== undefined && value.length > 0)
              ),
            categories: Yup.array()
              .nullable()
              .test(
                "cat-req",
                "You must select at least one category.",
                (value, ctx) =>
                  !addCoupon ||
                  ctx.parent.type !== PROMOTION_TYPES.CATEGORY ||
                  (value !== null && value !== undefined && value.length > 0)
              ),
          })}
          initialValues={{
            campaignName: campaign?.NAME ?? "",
            campaignType: campaign?.TYPE ?? type ?? "",
            content: campaign?.CONTENT
              ? campaign?.CONTENT.endsWith("\n{{UNSUBSCRIBE}}")
                ? campaign.CONTENT.split("\n{{UNSUBSCRIBE}}")[0]
                : campaign.CONTENT
              : "",
            listId: campaign?.LIST_ID,
            name: promotion?.NAME ?? campaign?.NAME ?? "",
            company: promotion?.IS_COMPANY_WIDE ? "1" : "0",
            duration: !promotion
              ? null
              : promotion?.DATE_START
              ? "range"
              : promotion?.DAYS_VALID !== null && promotion?.DAYS_VALID >= 0
              ? "days"
              : "forever",
            start: promotion?.DATE_START,
            end: promotion?.DATE_END,
            oncePer: promotion?.ONCE_PER_ORDER ? "1" : "0",
            products: promotion?.PRODUCTS?.map((_promotion) => _promotion.ID) ?? [],
            categories: promotion?.CATEGORIES?.map((_promotion) => _promotion.ID) ?? [],
            discount: defaultDiscount,
            type: promotion?.TYPE ?? PROMOTION_TYPES.TICKET,
            flat: toDollars(promotion?.DISCOUNT?.FLAT_FEE || 0),
            percentage: promotion?.DISCOUNT?.PERCENTAGE,
            days: campaign?.PROMOTION?.DAYS_VALID, // TODO do days
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  options={formikOptions}
                  label="Name"
                  name="campaignName"
                  onChangeSoft={(val) => {
                    formikOptions.setFieldValue("name", val + " (Campaign Coupon)");
                  }}
                />

                <FormSelect
                  options={formikOptions}
                  label="Campaign Type"
                  name="campaignType"
                  className={
                    (type === null || type === undefined) && !campaign ? "" : "hidden"
                  }
                  data={[
                    {value: TEXT_CAMPAIGN_TYPES.ONE_OFF, label: "One Off"},
                    {value: TEXT_CAMPAIGN_TYPES.BIRTHDAY, label: "Birthday"},
                    {value: TEXT_CAMPAIGN_TYPES.WELCOME, label: "Welcome"},
                  ]}
                />

                <FormSelect
                  data={subscriptionLists.map((list) => ({
                    label: list.NAME,
                    value: list.ID,
                  }))}
                  className={
                    type === TEXT_CAMPAIGN_TYPES.WELCOME ||
                    campaign?.TYPE === TEXT_CAMPAIGN_TYPES.WELCOME
                      ? "hidden"
                      : ""
                  }
                  options={formikOptions}
                  label="Subscriber List"
                  name="listId"
                />

                <FormTextArea
                  options={formikOptions}
                  label="Content"
                  name="content"
                  hint={isMac ? "Emojis: ⌘+Ctrl+Space" : "Emojis: Windows+Period"}
                  ref={(e) => (this.formTextArea = e)}
                  placeholder={
                    campaign?.TYPE === TEXT_CAMPAIGN_TYPES.WELCOME ||
                    type === TEXT_CAMPAIGN_TYPES.WELCOME
                      ? "Hi {{PATRON}}! Thank you for signing up for our text marketing! Use the code ABC123 for a free Coffee!"
                      : campaign?.TYPE === TEXT_CAMPAIGN_TYPES.BIRTHDAY ||
                        type === TEXT_CAMPAIGN_TYPES.BIRTHDAY
                      ? "Happy Birthday! Here is one on us: use code ABC123 for a free Birthday Coffee."
                      : "Hi {{PATRON}}! Use the code ABC123 for a free Coffee today! We hope to see you soon!"
                  }
                  tooltip={{
                    data: [
                      {
                        label: "Content",
                        data: "This is the text content that will be sent to your subscribers.",
                      },
                      {
                        label: "Text Tags",
                        data: "The following tabs contain tags that can be used in your campaign content and will be replaced when sent out.",
                      },
                      {
                        label: "{{PATRON}}",
                        data: (
                          <div>
                            {"{{PATRON}}"} in the text content will be replaced with the
                            Patron\'s name if available. Otherwise it will be replaced
                            with "You"
                            <button
                              onClick={() =>
                                this.formikRef.setFieldValue(
                                  "content",
                                  this.insertText("{{PATRON}}", values.content)
                                )
                              }
                              className="mt-2 text-indigo-600 font-semibold hover:text-indigo-800 hover:font-bold"
                            >
                              Add to Content
                            </button>
                          </div>
                        ),
                      },
                      {
                        label: "{{CODE}}",
                        data: (
                          <div>
                            {"{{CODE}}"} in the text content will be replaced with a
                            unique coupon code for each patron. In order for this to work,
                            you must press "+ Add Coupon" button and fill out the coupon
                            information. To show the expiration of the coupon, use the{" "}
                            {"{{EXPIRATION}}"} tag.
                            <button
                              onClick={() =>
                                this.formikRef.setFieldValue(
                                  "content",
                                  this.insertText(
                                    "{{CODE}} {{EXPIRATION}}",
                                    values.content
                                  )
                                )
                              }
                              className="mt-2 text-indigo-600 font-semibold hover:text-indigo-800 hover:font-bold"
                            >
                              Add to Content
                            </button>
                          </div>
                        ),
                      },
                    ],
                  }}
                />

                <div>
                  {addCoupon && (
                    <button
                      onClick={() => {
                        this.formikRef.setFieldValue(
                          "content",
                          this.insertText("{{CODE}} {{EXPIRATION}}", values.content)
                        );
                      }}
                      className="mt-2 mr-4 text-indigo-600 font-semibold hover:text-indigo-800 hover:font-semibold"
                      type="button"
                    >
                      + Coupon Code to Content
                    </button>
                  )}
                  <button
                    onClick={() =>
                      this.formikRef.setFieldValue(
                        "content",
                        this.insertText("{{PATRON}}", values.content)
                      )
                    }
                    className="mt-2 text-indigo-600 font-semibold hover:text-indigo-800 hover:font-semibold"
                    type="button"
                  >
                    + Patron Name to Content
                  </button>
                </div>

                {addCoupon ? (
                  <>
                    <div className="flex flex-col">
                      <div className="flex flex-col mt-6 xxxs:space-y-2 sm:flex-row sm:justify-between sm:space-x-4">
                        <div className="text-sm font-medium">Coupon Options</div>

                        <FontAwesomeIcon
                          className="pr-3 mr-3 hover:cursor-pointer text-left align-bottom"
                          icon={"times"}
                          color={"red"}
                          size={"lg"}
                          onClick={() => {
                            this.setState({addCoupon: false});
                          }}
                        />
                      </div>

                      <Banner
                        className="mt-4"
                        label="Coupons created with text campaigns will generate a unique code for each customer the text campaign is sent to. The customer can enter the code on the mobile app, order website, or point of sale during checkout."
                      />
                    </div>

                    <PromotionForm
                      ref={(e) => (this.formikPromoRef = e)}
                      handleSubmit={handleSubmit}
                      promotion={campaign?.PROMOTION}
                      options={formikOptions}
                      noSubmit
                      disableName
                      fromTextCampaign
                      hideRange={values?.campaignType === 1}
                    />
                  </>
                ) : (
                  <div
                    className="mt-4 text-indigo-600 font-semibold cursor-pointer text-right"
                    onClick={() => {
                      this.setState({addCoupon: true});
                    }}
                  >
                    + Add Coupon
                  </div>
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(TextCampaignModal);
