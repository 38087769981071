import React, {Component} from "react";
import {withRouter} from "../../../../utils/navigation";
import {
  Card,
  FormDate,
  FormInput,
  FormTextArea,
  PageHeadings,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import * as Yup from "yup";
import FormRow from "../../../../components/form-row";
import Wizard from "../../../../components/wizard";
import LocationsDropdown from "../../../../dropdowns/team/locations-dropdown";
import {setupReduxConnection} from "../../../../redux";
import TransferItemsForm from "./transfer-items-form";
import {showErrorNotification} from "../../../../utils/notification-helper";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class CreateTransferPage extends Component {
  state = {items: [], receiverLocation: null};

  componentDidMount() {
    if (this.props.router.location.state) {
      const {transfer} = this.props.router.location.state;

      const items = transfer.REQUESTS.filter((r) => r.STATUS === "ACCEPTED").map(
        (item) => ({...item, TOTAL: this.calculateCost(item)})
      );

      const receiverLocation = transfer.ID;
      this.setState({items, receiverLocation});
    }
  }

  calculateStockCost(stock, quantity) {
    return Math.round(
      Math.min(quantity + stock.CURRENT_QUANTITY, stock.CURRENT_QUANTITY) * stock.COST
    );
  }

  findCost(item) {
    let stockRemaining = item.REQUESTED_QUANTITY - item.FULFILLED_QUANTITY;

    return item.INGREDIENT.STOCKS.reduceRight((accum, stock) => {
      if (stockRemaining <= 0) {
        return accum;
      }

      stockRemaining -= stock.CURRENT_QUANTITY;
      return accum + this.calculateStockCost(stock, stockRemaining);
    }, 0);
  }

  calculateCost(item) {
    if (!item || !item.INGREDIENT?.STOCKS) {
      return toDollars(0, true);
    }

    return this.findCost(item);
  }

  transformTransferItems(items) {
    return items.map((item) => {
      return {
        QUANTITY: item.QUANTITY,
        UNIT_ID: item.UNIT_ID,
        TOTAL: item.TOTAL,
        INGREDIENT_ID: item.INGREDIENT_ID,
        NAME: item.NAME,
        ITEM_REQUEST_ID: item.ITEM_REQUEST_ID,
        PRODUCT_SKU: item.PRODUCT_SKU,
      };
    });
  }

  fetchCreateTransferPayload(values) {
    const {transferLocation, transferItems, notes, date, number} = values;

    return {
      ITEMS: this.transformTransferItems(transferItems),
      RECEIVER_LOCATION_ID: transferLocation,
      NOTES: notes,
      DELIVERY_DATE: date,
      TRANSFER_NUMBER: number,
    };
  }

  onCreateTransferError(error) {
    showErrorNotification(
      "Something went wrong.",
      "Something went wrong creating the transfer. Please try again."
    );
  }

  onCreateTransferSuccess(transfer) {
    this.props.router.navigate("/transfer/" + transfer.UNIQUE_ID);
  }

  async sendCreateTransferRequest(payload) {
    try {
      const transfer = await request("inventory-transfers", "POST", payload);
      this.onCreateTransferSuccess(transfer);
    } catch (e) {
      this.onCreateTransferError(e);
    }
  }

  async createTransfer(values) {
    const createTransferPayload = this.fetchCreateTransferPayload(values);
    return this.sendCreateTransferRequest(createTransferPayload);
  }

  renderPageHeadings() {
    return (
      <PageHeadings
        className="py-4"
        label="Create Transfer"
        description="Select the products you would like to transfer"
      />
    );
  }

  fetchCreateTransferInitialValues() {
    const {items, receiverLocation} = this.state;

    return {
      transferLocation: receiverLocation,
      transferItems: items
        ? items.map((item) => {
            return {
              NAME: item.INGREDIENT.NAME,
              INGREDIENT_ID: item.INGREDIENT_ID,
              UNIT_ID: item.INGREDIENT.UNIT_ID,
              QUANTITY: item.REQUESTED_QUANTITY - item.FULFILLED_QUANTITY,
              INGREDIENT: item.INGREDIENT,
              ITEM_REQUEST_ID: item.ID,
              TOTAL: item.TOTAL,
            };
          })
        : [],
      notes: "",
      number: 1,
      date: null,
    };
  }

  fetchCreateTransferStepValidationSchema() {
    return Yup.object({
      transferLocation: Yup.number().nullable().required("Please select a location."),
      transferItems: Yup.array(),
      notes: Yup.string(),
      number: Yup.number().nullable().required("Please enter the order number."),
      date: Yup.number(),
    });
  }

  fetchLocationDropdown(formikOptions) {
    const {location} = this.props.shop;

    return (
      <LocationsDropdown
        isCompany
        options={formikOptions}
        name="transferLocation"
        label="Transfer Location"
        tooltip="The location to which you are transferring the items."
        exclude={[location.ID]}
      />
    );
  }

  fetchTransferNumberInput(formikOptions) {
    return (
      <FormInput name="number" label={"Transfer Number"} options={formikOptions} flex />
    );
  }

  fetchTransferDateInput(formikOptions) {
    return (
      <FormDate name="delivery" label="Delivery Date" options={formikOptions} flex />
    );
  }

  fetchTransferNotesInput(formikOptions) {
    return (
      <FormTextArea
        name="notes"
        label={"Notes"}
        hint={"Optional"}
        options={formikOptions}
      />
    );
  }

  renderTransferInformationCard(formikOptions) {
    return (
      <Card label="Transfer Information" className="p-4">
        {this.fetchLocationDropdown(formikOptions)}

        <FormRow>
          {this.fetchTransferNumberInput(formikOptions)}
          {this.fetchTransferDateInput(formikOptions)}
        </FormRow>

        {this.fetchTransferNotesInput(formikOptions)}
      </Card>
    );
  }

  addItem(idx, item, formikOptions) {
    const {values, setFieldValue} = formikOptions;
    const {transferItems} = values;

    transferItems[idx] = {
      ...transferItems[idx],
      ...item,
    };

    setFieldValue("transferItems", transferItems);
  }

  removeItem(idx, formikOptions) {
    const {values, setFieldValue} = formikOptions;
    const {transferItems} = values;

    transferItems.splice(idx, 1);
    setFieldValue("transferItems", transferItems);
  }

  renderTransferItemsForm(formikOptions) {
    const {values} = formikOptions;
    const {transferItems} = values;

    return (
      <TransferItemsForm
        transferItems={transferItems}
        addItem={(idx, item) => this.addItem(idx, item, formikOptions)}
        removeItem={(idx) => this.removeItem(idx, formikOptions)}
      />
    );
  }

  createItem(formikOptions) {
    const {values, setFieldValue} = formikOptions;
    const {transferItems} = values;

    const items = [
      ...transferItems,
      {
        NAME: "New Item",
        QUANTITY: 0,
        PRICE_PER_CASE: 0,
        TOTAL: 0,
        ID: null,
      },
    ];

    setFieldValue("transferItems", items);
  }

  fetchAddItemButton(formikOptions) {
    return {
      label: "Add Item",
      onClick: () => this.createItem(formikOptions),
    };
  }

  fetchItemCardButtons(formikOptions) {
    return [this.fetchAddItemButton(formikOptions)];
  }

  renderTransferItemsCard(formikOptions) {
    return (
      <Card label="Current Transfer" buttons={this.fetchItemCardButtons(formikOptions)}>
        {this.renderTransferItemsForm(formikOptions)}
      </Card>
    );
  }

  renderCreateTransferStep(formikOptions) {
    const {handleSubmit} = formikOptions;

    return (
      <form onSubmit={handleSubmit}>
        {this.renderTransferInformationCard(formikOptions)}
        {this.renderTransferItemsCard(formikOptions)}
      </form>
    );
  }

  fetchCreateTransferStep() {
    return {
      id: 0,
      name: "Create Transfer",
      description: "Enter transfer details and items.",
      initialValues: this.fetchCreateTransferInitialValues(),
      validationSchema: this.fetchCreateTransferStepValidationSchema(),
      render: (formikOptions) => {
        return this.renderCreateTransferStep(formikOptions);
      },
    };
  }

  fetchWizardSteps() {
    return [this.fetchCreateTransferStep()];
  }

  renderFormik() {
    return (
      <Wizard
        submitLabel="Create Transfer"
        ref={(e) => (this.wizardRef = e)}
        onSubmit={this.createTransfer.bind(this)}
        onQuit={() => this.props.router.navigate("/purchase-orders")}
        flexHeader
        steps={this.fetchWizardSteps()}
      />
    );
  }

  renderFooter() {
    return <div className="h-96" />;
  }

  render() {
    return (
      <div>
        {this.renderPageHeadings()}
        {this.renderFormik()}
        {this.renderFooter()}
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(CreateTransferPage));
