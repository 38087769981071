import React, {Component} from "react";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {Card, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import LoadingSpinner from "../../../components/loading-spinner";
import moment from "moment-timezone";
import {capitalize} from "lodash";
import {showConfirmAlert} from "../../../utils/alert-helper";

class TaxWithholdingPage extends Component {
  state = {isLoading: true, withheldMonths: null, pastPayouts: []};

  async componentDidMount() {
    await this.refresh();
  }

  refresh = async () => {
    let withheldMonths = await request("tax-withholding", "GET", null);

    let pastPayouts = await request("tax-withholding/payouts", "GET");

    this.setState({isLoading: false, withheldMonths, pastPayouts});
  };

  executeMonth = async ({AMOUNT_TAX, MONTH}) => {
    showConfirmAlert(
      `Are you sure you want to payout ${moment(MONTH).format(
        "MMMM YYYY"
      )}'s tax withholdings?`,
      `This will send ${toDollars(
        AMOUNT_TAX,
        true
      )} to your bank account used for payouts.`
    )
      .then(async () => {
        await request("tax-withholding", "POST", {MONTH});
      })
      .catch((e) => {});
  };

  renderCurrentWithholdings() {
    const {withheldMonths} = this.state;

    return (
      <Card label={"Current Tax Withholdings"}>
        <Table
          columns={[
            {
              label: "Month",
              value: "MONTH",
              format: (value) => moment(value).format("MMMM YYYY"),
            },
            {
              label: "Tax Withheld",
              value: "AMOUNT_TAX",
              format: (value) => toDollars(value, true),
            },
            {
              label: "",
              width: 1,
              format: (value, row) => {
                if (moment(row.MONTH).valueOf() >= moment().startOf("month").valueOf()) {
                  return (
                    <div>
                      Available {moment(row.MONTH).add(1, "month").format("MMMM")} 1st
                    </div>
                  );
                }
                return (
                  <button
                    onClick={() => this.executeMonth(row)}
                    type="button"
                    className="m-auto space-x-4 inline-flex items-center px-3 py-1 border border-transparent text-xs font-semibold rounded-md shadow-sm text-white bg-indigo-600"
                  >
                    Payout
                  </button>
                );
              },
            },
          ]}
          data={withheldMonths}
        />
      </Card>
    );
  }

  render() {
    let {isLoading, pastPayouts, withheldMonths} = this.state;

    return (
      <div>
        <PageHeadings label="Tax Withholding" />

        {isLoading && <LoadingSpinner />}

        {!isLoading && (
          <div>
            {this.renderCurrentWithholdings()}

            <Card label="Past Tax Withholding Payouts">
              <Table
                pagination={true}
                columns={[
                  {
                    label: "Month",
                    value: "DATE_CAPTURED_START",
                    format: (value) => moment(value).format("MMM YYYY"),
                  },
                  {
                    label: "Status",
                    key: "STATUS",
                    format: (value, row) =>
                      row.DATE_ARRIVED < moment().valueOf() ? "Paid" : "Pending",
                  },
                  {
                    label: "Amount",
                    value: "AMOUNT",
                    format: (value) => toDollars(value, true),
                  },
                ]}
                data={pastPayouts}
              />
            </Card>
          </div>
        )}
      </div>
    );
  }
}

export default TaxWithholdingPage;
