import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import IngredientDropdown from "../../../../dropdowns/operations/recipes/ingredient-dropdown";
import UnitDropdown from "../../../../dropdowns/operations/recipes/unit-dropdown";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";

class TransferItemsForm extends Component {
  calculateCost(item) {
    if (!item || !item.INGREDIENT?.STOCKS) {
      return 0;
    }

    const conversion = item.INGREDIENT.UNIT.CONVERSIONS.find(
      (c) => c.TO_UNIT === item.UNIT_ID
    ) || {RATE: 1};

    let stockRemaining = item.QUANTITY / conversion.RATE;
    item.RAW_QUANTITY = stockRemaining;

    return item.INGREDIENT.STOCKS.reduceRight((accum, stock) => {
      if (stockRemaining <= 0) {
        return accum;
      }

      stockRemaining -= stock.CURRENT_QUANTITY;

      return (
        Math.round(
          Math.min(stockRemaining + stock.CURRENT_QUANTITY, stock.CURRENT_QUANTITY) *
            stock.COST
        ) + accum
      );
    }, 0);
  }

  calculateQuantity(item) {
    if (!item || !item.INGREDIENT?.STOCKS) {
      return 0;
    }

    const conversion = item.INGREDIENT.UNIT.CONVERSIONS.find(
      (c) => c.TO_UNIT === item.UNIT_ID
    ) || {RATE: 1};

    return item.QUANTITY / conversion.RATE;
  }

  onChangeIngredient(id, row, item) {
    const {transferItems} = this.props;
    const {ingredient} = item;
    const idx = transferItems.findIndex((_item) => _item.tid === row.tid);

    this.props.addItem(idx, {
      NAME: ingredient.NAME,
      INGREDIENT_ID: ingredient.ID,
      INGREDIENT: ingredient,
      UNIT_ID: ingredient.UNIT_ID,
      QUANTITY: 1,
      TOTAL: this.calculateCost({
        NAME: ingredient.NAME,
        INGREDIENT_ID: ingredient.ID,
        INGREDIENT: ingredient,
        UNIT_ID: ingredient.UNIT_ID,
        QUANTITY: 1,
      }),
    });
  }

  renderIngredientDropdown(id, row) {
    return (
      <IngredientDropdown
        fixed
        compact
        value={row.INGREDIENT_ID}
        onChange={(_, item) => this.onChangeIngredient(id, row, item)}
        ignoreMargin
        placeholder="Input an ingredient"
      />
    );
  }

  fetchIngredientColumn() {
    return {
      label: "Name",
      value: "INGREDIENT_ID",
      format: (id, row) => {
        return this.renderIngredientDropdown(id, row);
      },
    };
  }

  onChangeQuantity(idx, val) {
    const {transferItems} = this.props;

    this.props.addItem(idx, {
      QUANTITY: val ? parseFloat(val) : 0,
      RAW_QUANTITY: this.calculateQuantity({
        ...transferItems[idx],
        QUANTITY: val ? parseFloat(val) : 0,
      }),
      TOTAL: this.calculateCost({
        ...transferItems[idx],
        QUANTITY: val ? parseFloat(val) : 0,
      }),
    });
  }

  fetchQuantityColumn() {
    return {
      label: "Quantity",
      value: "QUANTITY",
      editable: true,
      onChange: (idx, val) => this.onChangeQuantity(idx, val),
    };
  }

  fetchCurrentStock(row) {
    const unit = row.UNIT_ID;

    if (unit !== row.INGREDIENT?.UNIT_ID) {
      const conversion = row.INGREDIENT.UNIT.CONVERSIONS?.find((_conversion) => {
        return _conversion.TO_UNIT === unit;
      });

      return (row.INGREDIENT?.CURRENT_STOCK * conversion?.RATE).toFixed(2);
    }

    return row.INGREDIENT?.CURRENT_STOCK;
  }

  fetchCurrentStockColumn() {
    return {
      label: "Your Current Stock",
      value: "",
      format: (_, row) => this.fetchCurrentStock(row),
    };
  }

  onUnitChange(id, row, item) {
    const {transferItems} = this.props;
    const idx = transferItems.findIndex((_item) => _item.tid === row.tid);

    this.props.addItem(idx, {
      UNIT_ID: item.id,
      UNIT: item.unit,
      TOTAL: this.calculateCost({
        ...transferItems[idx],
        UNIT_ID: item.id,
        UNIT: item.unit,
      }),
    });
  }

  fetchUnitDropdown(id, row) {
    return (
      <UnitDropdown
        fixed
        compact
        value={row.UNIT_ID}
        onChange={(_, item) => this.onUnitChange(id, row, item)}
        ignoreMargin
        ingredient={row.INGREDIENT}
        unit={row.INGREDIENT?.UNIT_ID}
        placeholder="Input a unit"
      />
    );
  }

  fetchUnitColumn() {
    const {transferItems} = this.props;

    return {
      label: "Unit",
      value: "UNIT_ID",
      format: (id, row) => {
        return this.fetchUnitDropdown(id, row);
      },
    };
  }

  fetchCostColumn() {
    return {
      label: "Cost",
      value: "TOTAL",
      format: (val) => toDollars(val, true),
    };
  }

  fetchColumns() {
    return [
      this.fetchIngredientColumn(),
      this.fetchQuantityColumn(),
      this.fetchCurrentStockColumn(),
      this.fetchUnitColumn(),
      this.fetchCostColumn(),
    ];
  }

  fetchDeleteButton() {
    return {
      label: "Delete",
      onClick: (_, idx) => this.props.removeItem(idx),
    };
  }

  fetchActionButtons() {
    return [this.fetchDeleteButton()];
  }

  render() {
    let {transferItems} = this.props;

    return (
      <Table
        data={transferItems}
        actionButtons={this.fetchActionButtons()}
        columns={this.fetchColumns()}
      />
    );
  }
}

TransferItemsForm.propTypes = {
  transferItems: PropTypes.arrayOf(
    PropTypes.shape({
      NAME: PropTypes.string.isRequired,
      QUANTITY: PropTypes.number.isRequired,
      PRICE_PER_CASE: PropTypes.number.isRequired,
      ID: PropTypes.number,
    })
  ).isRequired,
};

export default TransferItemsForm;
