import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import VendorItemsDropdown from "../../../../dropdowns/operations/recipes/vendor-items-dropdown";

class PurchaseOrderItemsForm extends Component {
  fetchNameColumn() {
    const {vendor, vendorItems} = this.props;

    return {
      label: "Vendor Item",
      value: "ID",
      format: (id, row) => {
        return (
          <VendorItemsDropdown
            key={"box_" + row.ID}
            fixed
            compact
            ignoreMargin
            unique
            extraHeight={0}
            vendorId={vendor}
            placeholder="Input a vendor item"
            value={row.UNIQUE_ID}
            onChange={(_, item) => {
              const idx = vendorItems.findIndex((_item) => _item.tid === row.tid);
              this.props.addItem(idx, item.item);
            }}
            style={{
              marginTop: 0,
              minWidth: 100,
              flex: 1,
              borderWidth: 0,
            }}
          />
        );
      },
    };
  }

  fetchQuantityColumn() {
    return {
      label: "Quantity",
      value: "QUANTITY",
      editable: true,
      onChange: (idx, val) => {
        this.props.addItem(idx, {QUANTITY: parseInt(val)});
      },
    };
  }

  fetchTotalColumn() {
    return {
      label: "Total Cost",
      value: "PRICE_PER_CASE",
      format: (value, row) => {
        return toDollars(value * row.QUANTITY, true);
      },
    };
  }

  fetchColumns() {
    return [this.fetchNameColumn(), this.fetchQuantityColumn(), this.fetchTotalColumn()];
  }

  fetchRemoveButton() {
    return {
      label: <FontAwesomeIcon icon="fa-solid fa-trash" />,
      onClick: (vendorItem) => {
        return this.props.removeItem(vendorItem);
      },
    };
  }

  fetchActionButtons() {
    return [this.fetchRemoveButton()];
  }

  render() {
    const {vendorItems} = this.props;

    return (
      <Table
        columns={this.fetchColumns()}
        actionButtons={this.fetchActionButtons()}
        data={vendorItems}
      />
    );
  }
}

export default PurchaseOrderItemsForm;
