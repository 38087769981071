import React, {Component} from "react";
import {Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../../redux";
import {updateStateDelegator} from "../../../../utils/util";
import moment from "moment/moment";
import SupplyChainUnitModal from "../../../../modals/operations/ingredients/supply-chain-unit-modal";
import {SupplyChainUnitRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";

class SupplyChainUnitsPage extends Component {
  state = {
    isLoading: true,
    ingredients: [],
    units: [],
  };

  componentDidMount() {
    this.syncState();
  }

  syncState = async () => {
    const supplyChainUnits = await SupplyChainUnitRequests.fetchSupplyChainUnitConversions()

    this.setState({isLoading: false, units: supplyChainUnits});

  };

  deleteUnit = async (row) => {
    this.setState({isLoading: true});

    const unitToDelete = {
      FROM_UNIT_ID: row.FROM_UNIT_ID,
      TO_UNIT_ID: row.TO_UNIT_ID,
    };

    await SupplyChainUnitRequests.deleteSupplyChainUnit(unitToDelete);

    await this.syncState();
  };

  render() {
    const {isLoading, units} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label="Supply Chain Units"
          description="Custom units for stock conversions"
          button={{
            label: "Create a Supply Chain Unit",
            onClick: () => this.unitModal.open(),
          }}
        />

        <SupplyChainUnitModal
          ref={(e) => (this.unitModal = e)}
          units={units}
          addState={(unit) => this.setState({units: [unit, ...units]})}
          updateState={(unit) => {
            this.setState({
              units: updateStateDelegator(units, unit.ID, unit),
            });
          }}
          syncState={this.syncState}
        />

        <Table
          pagination
          className="mt-4"
          data={units}
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "Edit",
              onClick: (row) => this.unitModal.open(row),
            },
            {
              icon: "trash",
              onClick: (row) => this.deleteUnit(row),
            },
          ]}
          columns={[
            {
              value: "FROM_UNIT_NAME",
              label: "Name",
            },
            {
              value: "CONVERSION_RATIO",
              label: "Conversion",
              format: (_, row) => row.CONVERSION_RATIO + " (" + row.TO_UNIT_NAME + ")",
            },
            {
              width: 1,
              label: "Created",
              value: "DATE_CREATED",
              format: (value) => moment(value).format("MM/DD/YY"),
            },
            {
              width: 1,
              label: "Updated",
              value: "DATE_UPDATED",
              format: (value) => moment(value).format("MM/DD/YY"),
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["supply", "shop"])(SupplyChainUnitsPage);
