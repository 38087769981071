import React, {Component} from "react";
import {Outlet} from "react-router-dom";
import PageLayout from "../page/page-layout";
import SideNav from "../../components/side-nav";
import {setupReduxConnection} from "../../redux";
import {PERMISSIONS} from "../../utils/constants";
import {PAYROLL_ONBOARDING_STATUSES} from "../../utils/payroll-constants";

class FinanceSideNav extends Component {
  render() {
    const {TYPE, FA_ID} = this.props.shop.location;

    const {payrollGroup, accountingGroup} = this.props.shop;
    const {
      ACCOUNTING_WHITELISTED,
      CASH_ACCOUNT_WHITELISTED,
      PAYROLL_WHITELISTED,
      TAX_WITHHOLDING,
    } = this.props.shop.settings;

    const isAccountingWhitelisted = ACCOUNTING_WHITELISTED === "1";
    const isAccountingOnboarded = accountingGroup?.STATUS === "LIVE";

    const isPayrollWhitelisted = PAYROLL_WHITELISTED === "1";
    const isPayrollWorkplaceCreated =
      PAYROLL_ONBOARDING_STATUSES[payrollGroup?.STATUS] >=
      PAYROLL_ONBOARDING_STATUSES["WORKPLACES_CREATED"];
    const runsPayroll = payrollGroup.RUNS_PAYROLL;

    let cashMenu = {
      live: true,
      id: "payouts",
      name: "Payouts",
      href: "/payouts",
      permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      children: [
        {
          live: true,
          name: "Settings",
          href: "/payouts/settings",
          paths: ["/payouts/settings"],
          permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
        },
      ],
    };

    if (TAX_WITHHOLDING === "1") {
      cashMenu.children.unshift({
        live: true,
        name: "Tax Withholding",
        href: "/payouts/tax-withholding",
        paths: ["/payouts/tax-withholding"],
        permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      });
    }

    let accountingMenu = {
      live: true,
      id: "menu",
      name: "Accounting",
      href: "/accounting",
      permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      children: [
        {
          live: true,
          name: "Transactions",
          href: "/accounting/transactions",
          paths: ["/accounting/transactions"],
          permissions: [],
        },
        {
          live: true,
          name: "Chart of Accounts",
          href: "/accounting/accounts",
          paths: ["/accounting/accounts"],
          permissions: [],
        },
        {
          live: true,
          name: "Balance Sheet",
          href: "/accounting/balance-sheet",
          paths: ["/accounting/balance-sheet"],
          permissions: [],
        },
        {
          live: true,
          name: "Profit & Loss",
          href: "/accounting/income-statement",
          paths: ["/accounting/income-sheet"],
          permissions: [],
        },
        // {
        //   live: true,
        //   name: "Cash Flow Report",
        //   href: "/accounting/cash-flow",
        //   paths: ["/accounting/cash-flow"],
        //   permissions: [],
        // },
        {
          live: true,
          name: "Vendors",
          href: "/accounting/vendors",
          paths: ["/accounting/vendors"],
        },
        {
          live: true,
          name: "Bills",
          href: "/accounting/bills",
          paths: ["/accounting/bills"],
        },
        {
          live: true,
          name: "Automated Entries",
          href: "/accounting/auto",
          paths: ["/accounting/auto"],
          permissions: [],
        },
        {
          live: true,
          name: "Bank Connections",
          href: "/accounting/bank-connections",
          paths: ["/accounting/bank-connections"],
          permissions: [],
        },
        // {
        //   live: true,
        //   name: "Settings",
        //   href: "/accounting/settings",
        //   paths: ["/accounting/settings"],
        //   permissions: [],
        // },
      ],
    };

    if (!isAccountingWhitelisted || !isAccountingOnboarded) {
      accountingMenu = {
        live: true,
        id: "menu",
        name: "Accounting",
        href: "/accounting",
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      };
    }

    let cashAccountMenu = {
      live: false,
      id: "menu",
      name: "Cash Account",
      href: "/cash-account",
      permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
      children: [
        {
          live: true,
          name: "Cards",
          href: "/cash-account/cards",
          paths: ["/cash-account/cards"],
          permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
        },
      ],
    };

    let payrollMenu = {
      live: true,
      id: "menu",
      name: "Payroll",
      href: "/payroll",
      permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      children: [
        {
          live: true,
          name: "Employees",
          href: "/payroll/employees",
          paths: ["/payroll/employees"],
          permissions: [],
        },
        {
          live: true,
          name: "Contractors",
          href: "/payroll/contractors",
          paths: ["/payroll/contractors"],
          permissions: [],
        },
        {
          live: true,
          name: "Benefits",
          href: "/payroll/benefits",
          paths: ["/payroll/benefits"],
          permissions: [],
        },
        {
          live: true,
          name: "History",
          href: "/payrolls",
          paths: ["/payrolls"],
          permissions: [],
        },
        {
          live: true,
          name: "Company Tax Documents",
          href: "payroll/company-tax-documents",
          paths: ["/payroll/company-tax-documents"],
          permissions: [],
        },
        {
          live: true,
          name: "Employee Tax Documents",
          href: "payroll/employee-tax-documents",
          paths: ["/payroll/employee-tax-documents"],
          permissions: [],
        },
        {
          live: true,
          name: "Contractor Tax Documents",
          href: "payroll/contractor-tax-documents",
          paths: ["/payroll/contractor-tax-documents"],
          permissions: [],
        },
        {
          live: true,
          name: "Payroll Journal",
          href: "payroll/journal",
          paths: ["/payroll/journal"],
          permissions: [],
        },
        {
          live: true,
          name: "Settings",
          href: "payroll/settings",
          paths: ["/payroll/settings"],
          permissions: [],
        },
      ],
    };

    if (!isPayrollWhitelisted || !isPayrollWorkplaceCreated || !runsPayroll) {
      payrollMenu = {
        live: true,
        id: "menu",
        name: "Payroll",
        href: "/payroll",
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id],
      };
    }

    let loansMenu = {
      live: true,
      id: "capital",
      name: "Dripos Capital",
      href: "/capital",
      permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
    };

    let billingMenu = {
      live: true,
      id: "billing",
      name: "Billing",
      href: "/billing",
      permissions: [PERMISSIONS.FINANCE.BANKING_FULL.id],
    };

    let title = "Finance";
    let optionsArr = [
      payrollMenu,
      accountingMenu,
      cashAccountMenu,
      cashMenu,
      billingMenu,
      loansMenu,
    ];

    if (TYPE === "PAYROLL") {
      title = "Payroll";
      optionsArr = [payrollMenu];
    }

    return (
      <div className="p-2 md:p-6 height-screen">
        <PageLayout className="mt-0">
          <div className="lg:col-start-1 lg:col-span-2">
            <SideNav
              title={title}
              permissions={[PERMISSIONS.FINANCE.FULL.id]}
              navigation={optionsArr}
            />
          </div>

          <div className="lg:col-start-3 lg:col-span-10">
            <Outlet />
          </div>
        </PageLayout>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(FinanceSideNav);
