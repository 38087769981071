import React, {Component} from "react";
import {Card, Table, Filter} from "@frostbyte-technologies/frostbyte-tailwind";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import PropTypes from "prop-types";
import {
  formatShiftString,
  tradeRequestStatusToBadge,
} from "../../../../utils/team-helper";
import TradeRequestModal from "../../../../modals/team/trade-request-modal";
import {request} from "../../../../utils/request";
import {
  TRADE_REQUEST_TYPES,
} from "../../../../utils/team-constants";
import {setupReduxConnection} from "../../../../redux";
import {CheckIcon, XIcon} from "@heroicons/react/solid";
import {showErrorAlert} from "../../../../utils/alert-helper";
import {renderNoPermission} from "../../../../utils/approval-center-helper";

class TradeRequestTab extends Component {
  blessRequest = async (
    tradeId,
    isAccepted,
    reviewerNotes,
    shiftId,
    swapShiftId = null
  ) => {
    let {FULL_NAME} = this.props.user.account;
    let {trades, updateTrades} = this.props;

    const blessPayload = {
      REQUEST_ID: tradeId,
      ACCEPT: isAccepted,
      NOTES: reviewerNotes,
    };

    try {
      await request("scheduling/request/bless", "POST", blessPayload);
    } catch (err) {
      if (err.error === "SHIFT_INVALID") {
        return showErrorAlert(
          "Shift Not Found",
          "One or more of the shifts you are trying to swap could not be found",
          "Ok"
        );
      }

      if (err.error === "EMPLOYEE_ROLE_INVALID") {
        return showErrorAlert(
          "Role Not Found",
          "One or more employees does not have the role you are trying to swap",
          "Ok"
        );
      }

      return showErrorAlert(
        "Unknown Error",
        "Something went wrong. Could not approve trade!",
        "Ok"
      );
    }

    const submitPayload = {
      UP_FOR_GRABS: 0,
    };

    try {
      await request(`scheduling/v2/shift/${shiftId}`, "PATCH", submitPayload);
    } catch (err) {
      showErrorAlert("Update Shift Error", "Couldn't pickup shift!", "Ok");

      return this.close();
    }

    const tradeIndex = trades.findIndex((item) => item.ID === tradeId);

    trades[tradeIndex].REVIEWER_NAME = FULL_NAME;
    trades[tradeIndex].REVIEWER_NOTES = reviewerNotes;

    if (isAccepted) {
      trades[tradeIndex].STATUS = TRADE_REQUEST_TYPES.reviewer_approved.value;
      trades = this.denyAdjacentTrades(trades, shiftId, swapShiftId);
    } else if (tradeIndex !== -1) {
      trades[tradeIndex].STATUS = TRADE_REQUEST_TYPES.reviewer_denied.value;
    }

    updateTrades(trades, shiftId, swapShiftId);
  };

  denyAdjacentTrades(trades, shiftId, swapShiftId) {
    return trades.map((_trade) => {
      if (
        _trade.SHIFT_ID === shiftId ||
        _trade.SWAP_SHIFT_ID === shiftId ||
        _trade.SHIFT_ID === swapShiftId ||
        _trade.SWAP_SHIFT_ID === swapShiftId
      ) {
        if (_trade.STATUS === "PND" || _trade.STATUS === "RVW") {
          _trade.STATUS = "DND";
          _trade.NOTES =
            "One or more of the shifts in this request have been updated. Please submit another request if you wish to swap";
        }
      }

      return _trade;
    });
  }

  render() {
    const {trades, locations, hasPermissions} = this.props;
    const {location} = this.props.shop;

    if (!hasPermissions) {
      return renderNoPermission();
    }

    return (
      <Card
        label="Trade Requests"
        description="Requests from employees to trade shifts"
      >
        <TradeRequestModal
          ref={(e) => (this.tradeRequestModal = e)}
          blessRequest={this.blessRequest}
        />

        <Filter
          className="mt-0"
          defaultFilters={[
            {filter: "status", label: "Ready For Review", id: "RVW"},
            {filter: "location", id: location.ID},
          ]}
          data={[
            {
              id: "location",
              label: "Location",
              onFilter: (filter, data) =>
                data.filter((item) => {
                  return filter.includes(item.LOCATION_ID);
                }),
              options: locations ?? [],
            },
            {
              id: "status",
              label: "Trade Status",
              onFilter: (filter, data) =>
                data.filter((item) => {
                  return filter.includes(item.STATUS);
                }),
              options: [
                {id: "RVW", label: "Ready For Review"},
                {id: "ACP", label: "Reviewer Approved"},
                {id: "DND", label: "Reviewer Denied"},
                {id: "PND", label: "Employee Pending"},
                {id: "RJD", label: "Employee Rejected"},
              ],
            },
          ]}
        >
          {(filters) => (
            <Table
              pagination
              data={trades}
              filters={filters}
              actionButtons={[
                {
                  label: "View Details",
                  onClick: (row) => this.tradeRequestModal.open(row),
                },
              ]}
              columns={[
                {
                  value: "NAME",
                  label: "Created By",
                  format: (value, row) => {
                    return (
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img
                            src={getObjectImage(
                              row,
                              "TRADER_LOGO",
                              "appicon.png"
                            )}
                            className="h-10 w-10 rounded-full"
                            alt=""
                          />
                        </div>

                        <div className="ml-4 text-sm font-medium text-gray-900">
                          {row.TRADER_NAME}
                        </div>
                      </div>
                    );
                  },
                },
                {
                  value: "NAME",
                  label: "To Trade With",
                  format: (value, row) => {
                    return (
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img
                            src={getObjectImage(
                              row,
                              "TARGET_LOGO",
                              "appicon.png"
                            )}
                            className="h-10 w-10 rounded-full"
                            alt=""
                          />
                        </div>

                        <div className="ml-4 text-sm font-medium text-gray-900">
                          {row.TARGET_NAME}
                        </div>
                      </div>
                    );
                  },
                },
                {
                  value: "SHIFT_DATE_START",
                  label: "Shift",
                  mobile: "lg:table-cell",
                  format: (value, row) => {
                    return (
                      <div className="text-sm text-gray-500">
                        {formatShiftString(
                          row.SHIFT_DATE_START,
                          row.SHIFT_DATE_END
                        )}
                      </div>
                    );
                  },
                },
                {
                  value: "SHIFT_DATE_START",
                  label: "Swap Shift",
                  mobile: "lg:table-cell",
                  format: (value, row) => {
                    return (
                      <div className="text-sm text-gray-500">
                        {row.SWAP_SHIFT_DATE_START &&
                          formatShiftString(
                            row.SWAP_SHIFT_DATE_START,
                            row.SWAP_SHIFT_DATE_END
                          )}
                      </div>
                    );
                  },
                },
                {
                  value: "STATUS",
                  label: "Status",
                  mobile: "lg:table-cell",
                  format: (value) => {
                    return tradeRequestStatusToBadge(value);
                  },
                },
                {
                  value: "STATUS",
                  mobile: "lg:table-cell",
                  width: 1,
                  format: (value, row) => {
                    return (
                      <div>
                        {row.STATUS ===
                          TRADE_REQUEST_TYPES.ready_for_review.value && (
                          <div className="flex flex-row">
                            <div
                              className="text-green-600 hover:text-green-900 cursor-pointer font-medium mr-7"
                              onClick={async () => {
                                await this.blessRequest(
                                  row.ID,
                                  true,
                                  "",
                                  row.SHIFT_ID,
                                  row.SWAP_SHIFT_ID
                                );
                              }}
                            >
                              <CheckIcon className="h-6 w-6" />
                            </div>

                            <div
                              className="text-red-600 hover:text-red-900 cursor-pointer font-medium"
                              onClick={async () => {
                                await this.blessRequest(row.ID, false, "");
                              }}
                            >
                              <XIcon className="h-6 w-6" />
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  },
                },
              ]}
            />
          )}
        </Filter>
      </Card>
    );
  }
}

TradeRequestTab.propTypes = {
  trades: PropTypes.array.isRequired,
};

export default setupReduxConnection(["user"])(TradeRequestTab);
