import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

import {
  Card,
  Loading,
  PageHeadings,
  Tab,
  Table,
  TwoColumnList,
  Filter,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import EmployeeRoleModal from "../../../modals/team/employee/employee-role-modal";
import RolesTable from "../../../tables/team/roles-table";
import moment from "moment-timezone";
import {
  randomString,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import Banner from "../../../components/banner";
import TerminateEmployeeModal from "../../../modals/team/employee/terminate-employee-modal";
import ReinstateEmployeeModal from "../../../modals/team/employee/reinstate-employee-modal";
import PayRateModal from "../../../modals/team/pay-rate-modal";
import ShiftModal from "../../../modals/scheduling/shift-modal";
import TimeCardModal from "../../../modals/team/time-card-modal";
import {setupReduxConnection} from "../../../redux";
import EmployeeDetailsModal from "../../../modals/team/employee/employee-details-modal";
import {
  COMPENSATION_TYPES,
  OPEN_SHIFT_APPROVAL_TYPES,
  UNAVAILABILITY_TYPES,
} from "../../../utils/team-constants";
import EmployeeTable from "../../../tables/team/employee-table";
import EmployeePolicyModal from "../../../modals/team/policies/employee-policy-modal";
import {sortArrayByKey, updateStateDelegator} from "../../../utils/util";
import TimeOffModal from "../../../modals/team/employee/time-off-modal";
import {fetchRecurrenceString, timeOffStatusToBadge} from "../../../utils/team-helper";
import ShiftPreferencesModal from "../../../modals/team/employee/shift-preferences-modal";
import {PERMISSIONS} from "../../../utils/constants";
import EmployeeFileModal from "../../../modals/team/employee/employee-file-modal";
import {saveAs} from "file-saver";
import EmployeeNotesModal from "../../../modals/team/employee/employee-notes-modal";
import ShowTextModal from "../../../modals/general/show-text-modal";
import {fetchEmployeeHomeLocationsString} from "../../../utils/employee-helper";
import {getHoursPastMidnight} from "../../../utils/time-helper";
import EmployeeTerminationsModal from "../../../modals/team/employee/employee-terminations-modal";
import DangerBadge from "../../../components/badges/danger-badge";
import TimeCardsTable from "../../../features/team/time-cards-table";
import EmployeeFolderModal from "../../../modals/team/employee/employee-folder-modal";
import EmployeeFileManageModal from "../../../modals/team/employee/employee-file-manage-modal";
import {EmployeeRequests} from "../../../utils/request-helpers/employees/employee-requests";
import {TeamsRequests} from "../../../utils/request-helpers/teams/teams-requests";
import EmployeeFilesTable from "../../../tables/team/employee-files-table";

class EmployeePage extends Component {
  state = {
    employee: null,
    rates: null,
    roles: null,
    shifts: null,
    breakOptions: [],
    unavailability: null,
    prefs: null,
    timeOff: null,
    terminations: [],
    filter: null,
    reports: null,
    policies: null,
    files: null,
    blackoutDates: [],
    folders: [],
    locations: [],
  };

  componentDidMount() {
    this.syncState();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.router?.params?.ID !== this.props?.router?.params?.ID) {
      this.setState({employee: null}, () => {
        this.syncState();
      });
    }
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    const employee = await EmployeeRequests.fetchEmployee(id);

    const [
      rates,
      policies,
      blackoutDates,
      roles,
      reports,
      shifts = [],
      breakOptions,
      terminations = [],
      unavailabilityAndPrefs = [],
      timeOff = [],
      notes,
      files,
      folders,
      locations,
    ] = await Promise.all([
      EmployeeRequests.fetchEmployeePayRates(employee.ID),
      EmployeeRequests.fetchEmployeeTimeOffPolicies(employee.ID),
      TeamsRequests.fetchBlackoutDates(),
      EmployeeRequests.fetchEmployeeRoles(employee.ID),
      EmployeeRequests.fetchEmployeeReports(employee.ID),
      EmployeeRequests.fetchEmployeeSchedule(employee.ID),
      TeamsRequests.fetchBreakOptions(employee.ID),
      EmployeeRequests.fetchEmployeeTermination(employee.ID),
      EmployeeRequests.fetchEmployeeUnavailabilities(employee.ID),
      EmployeeRequests.fetchEmployeeTimeOffRequests(employee.ID),
      EmployeeRequests.fetchEmployeeNotes(employee.ID),
      EmployeeRequests.fetchEmployeeFiles(employee.ID),
      EmployeeRequests.fetchEmployeeFolders(employee.ID),
      EmployeeRequests.fetchEmployeeFranchiseLocations(),
    ]);

    const sortedShifts = sortArrayByKey(shifts, "DATE_START");

    const unavailabilities = unavailabilityAndPrefs.filter(
      (item) => item.TYPE === UNAVAILABILITY_TYPES.UNAVAILABILITY
    );

    const sortedUnavailabilities = sortArrayByKey(unavailabilities, "ISO_DAY", false);

    const prefs = unavailabilityAndPrefs.filter(
      (item) => item.TYPE === UNAVAILABILITY_TYPES.PREFERENCE
    );

    const sortedPrefs = sortArrayByKey(prefs, "ISO_DAY", false);

    const sortedTimeOff = sortArrayByKey(timeOff, "DATE_START");

    this.setState({
      employee,
      rates,
      policies,
      blackoutDates,
      roles,
      reports,
      shifts: sortedShifts,
      breakOptions,
      terminations,
      unavailability: sortedUnavailabilities,
      prefs: sortedPrefs,
      timeOff: sortedTimeOff,
      notes,
      files,
      folders,
      locations,
    });
  }

  async loadFiles(employee) {
    const [files, folders] = await Promise.all([
      EmployeeRequests.fetchEmployeeFiles(employee.ID),
      EmployeeRequests.fetchEmployeeFolders(employee.ID),
    ]);

    this.setState({files, folders});
  }

  renderFiles() {
    const {employee, files = [], folders = []} = this.state;

    const filterData =
      folders?.length > 0
        ? [
            {
              id: "folder",
              label: "Filter Tags",
              onFilter: (options, data) =>
                data.filter((item) => {
                  return options.includes(item.FOLDER_ID);
                }),
              options: folders.map(({ID, NAME}) => ({id: ID, label: NAME})),
            },
          ]
        : [];

    return (
      <Card
        label="Files"
        buttons={[
          {
            label: "Upload File",
            onClick: () => this.fileModal.open(employee),
          },
          {
            type: "dropdown",
            label: "Manage Files",
            sections: [
              {
                items: [
                  {
                    label: "Create Tag",
                    onClick: () => this.folderModal.open(),
                  },
                  {
                    label: "Manage Files",
                    onClick: () => this.manageFileModal.open(),
                  },
                ],
              },
            ],
          },
        ]}
      >
        <Filter defaultFilters={[]} data={filterData}>
          {(filters, _) => {
            return (
              <EmployeeFilesTable
                employee={employee}
                filters={filters}
                files={files}
                folders={folders}
                loadFiles={() => this.loadFiles(employee)}
              />
            );
          }}
        </Filter>
      </Card>
    );
  }

  render() {
    const {
      employee,
      rates,
      roles,
      shifts,
      breakOptions,
      prefs,
      timeOff,
      unavailability,
      reports,
      filter,
      policies,
      terminations,
      files,
      notes,
      blackoutDates,
      folders,
      locations,
    } = this.state;
    const {permissions: userPermissions} = this.props.user;
    const {ONE_TIME_UNAVAILABILITY: showOneTimeUnavailability} = this.props.shop.settings;
    const {payrollGroup} = this.props.shop;

    const canViewRates =
      userPermissions?.includes("ADMIN_FULL") ||
      userPermissions?.includes("PAY_RATES_FULL");

    const canViewTimeCards =
      userPermissions?.includes(PERMISSIONS.ADMIN.FULL.id) ||
      userPermissions?.includes(PERMISSIONS.TEAM.FULL.id) ||
      userPermissions?.includes(PERMISSIONS.TEAM.TIME_FULL.id);

    const canViewFiles =
      userPermissions?.includes("ADMIN_FULL") ||
      userPermissions?.includes("EMPLOYEE_FILES_FULL");

    let hasPayRatesEnabled =
      employee?.TYPE === COMPENSATION_TYPES.HOUR.VALUE && !employee?.RATE;

    let tabs = [
      {label: "Roles", id: "roles"},
      {label: "Policies", id: "policies"},
      {
        label: "Shifts",
        id: "shifts",
      },
      {label: "Shift Preferences", id: "prefs"},
      {label: "Time Off", id: "time"},
      {
        label: "Direct Reports",
        id: "reports",
      },
    ];

    if (canViewTimeCards) {
      tabs.splice(3, 0, {label: "Time Cards", id: "cards"});
    }

    if (canViewRates && hasPayRatesEnabled) {
      tabs.splice(1, 0, {label: "Pay Rates", id: "rates"});
    }

    if (canViewFiles) {
      tabs.splice(tabs.length, 0, {label: "Files", id: "files"});
      tabs.splice(tabs.length, 0, {label: "Notes", id: "notes"});
    }

    if (employee === null) {
      return <Loading />;
    }

    const activeTerminations = terminations.filter((item) => {
      return item.DATE_REINSTATED === null;
    });

    let informationArr = [
      {
        label: "Name",
        value: employee.FULL_NAME,
      },
      {
        label: "Home Location",
        value: employee.LOCATION_NAME,
      },
      {
        label: "Department",
        link: employee.DEPARTMENT_ID && "/department/" + employee.DEPARTMENT_UNIQUE_ID,
        value: employee.DEPARTMENT_NAME ?? "None",
      },
      {
        label: "Manager",
        link:
          employee.MANAGER_EMPLOYEE_ID &&
          "/employee/" + employee.MANAGER_EMPLOYEE_UNIQUE_ID,
        value: employee.MANAGER_EMPLOYEE_NAME ?? "None",
      },
      {
        label: "Email",
        value: employee.EMAIL,
      },
      {
        label: "Phone",
        value: getFormattedPhone(employee.PHONE),
      },
      {
        label: "Start Date",
        value: employee.DATE_STARTED ?? "Not Added",
        type: employee.DATE_STARTED ? "date" : "",
      },
      {
        label: "Pin",
        value: employee.PIN,
      },
      {
        label: "Additional Home Locations",
        value: fetchEmployeeHomeLocationsString(
          employee.LOCATIONS,
          employee.LOCATION_NAME
        ),
        tooltip:
          "Gives employee permissions access to the following locations, in addition to their home location.",
      },
      {
        label: "Additional Scheduling Locations",
        value: fetchEmployeeHomeLocationsString(
          employee.SCHEDULE_LOCATIONS,
          employee.LOCATION_NAME
        ),
        tooltip: "Allows employee to be scheduled on the following locations.",
      },
      {
        label: "Notification Locations",
        value: fetchEmployeeHomeLocationsString(
          employee.NOTIFICATION_LOCATIONS,
          employee.LOCATION_NAME
        ),
        tooltip:
          "Sends employee notifications from the following locations (trade requests and late clock in reminders).",
      },
      {
        label: "Compensation Type",
        value:
          COMPENSATION_TYPES[employee.TYPE]?.LABEL ?? COMPENSATION_TYPES["HOUR"].LABEL,
      },
    ];

    if (employee.TYPE === COMPENSATION_TYPES.SAL.VALUE && canViewRates) {
      informationArr.push({
        label: "Salary",
        value: `${toDollars(employee.RATE, true)}`,
      });
    }

    if (employee.TYPE === COMPENSATION_TYPES.HOUR.VALUE && canViewRates) {
      informationArr.push({
        label: "Hourly Pay Type",
        value: `${employee.RATE ? "Employee Specific Rate" : "Role Based Rate"}`,
      });

      if (employee.RATE) {
        informationArr.push({
          label: "Default Rate",
          value: toDollars(employee.RATE, true),
        });
      }
    }

    return (
      <div>
        <EmployeeNotesModal
          ref={(e) => (this.notesModal = e)}
          employee={employee}
          syncState={() => this.syncState()}
        />

        <ShowTextModal ref={(e) => (this.showTextModal = e)} />

        <EmployeeFileModal
          ref={(e) => {
            this.fileModal = e;
          }}
          employee={employee}
          syncState={() => this.loadFiles(employee)}
          folders={folders}
        />

        <EmployeeFolderModal
          ref={(e) => {
            this.folderModal = e;
          }}
          employee={employee}
          updateState={() => this.loadFiles(employee)}
        />

        <EmployeeFileManageModal
          ref={(e) => {
            this.manageFileModal = e;
          }}
          employee={employee}
          files={files}
          folders={folders}
          updateState={() => this.loadFiles(employee)}
          userGuides={this.props.user.userGuideEntries}
        />

        <EmployeeDetailsModal
          updateState={(employee) => this.setState({employee})}
          ref={(e) => (this.detailModal = e)}
        />

        <EmployeePolicyModal
          employee={employee}
          policies={policies ?? []}
          ref={(e) => (this.policyModal = e)}
          addState={(policy) => this.setState({policies: [...policies, policy]})}
          updateState={(id, policy) => {
            this.setState({
              policies: updateStateDelegator(policies, id, policy),
            });
          }}
        />

        {/*<ContactSupportModal ref={(e) => (this.detailModal = e)} />*/}

        <TerminateEmployeeModal
          updateState={() => this.props.router.navigate("/team")}
          ref={(e) => (this.terminateModal = e)}
        />

        <ReinstateEmployeeModal
          updateState={() => this.props.router.navigate("/team")}
          ref={(e) => (this.reinstateModal = e)}
        />

        <EmployeeRoleModal
          roles={roles}
          employee={employee}
          employees={this.props.shop.employees}
          ref={(e) => (this.roleModal = e)}
          addState={(role) => this.setState({roles: [...roles, role]})}
          removeState={(role) => {
            const roleIndex = roles.findIndex(({Roles}) => Roles.ID === role.ID);

            if (roleIndex !== -1) {
              roles.splice(roleIndex, 1);
            }

            this.setState({roles});
          }}
        />

        <TimeOffModal
          ref={(e) => (this.timeOffModal = e)}
          policies={policies}
          blackoutDates={blackoutDates}
          updateTimeOff={(req) => {
            let {timeOff} = this.state;

            const idx = timeOff.findIndex((_timeOff) => _timeOff.ID === req.ID);

            if (idx === -1) {
              timeOff = [req, ...timeOff];
            } else {
              timeOff.splice(idx, 1, req);
            }

            this.setState({timeOff});
          }}
          deleteTimeOff={(id) => {
            const idx = timeOff.findIndex((_timeOff) => _timeOff.ID === id);

            if (idx !== -1) {
              timeOff.splice(idx, 1);
            }

            this.setState({timeOff});
          }}
        />

        <ShiftModal
          ref={(e) => (this.shiftModal = e)}
          startDay={this.props.shop.settings.SCHEDULER_FIRST_DAY}
        />

        <TimeCardModal
          ref={(e) => (this.timeCardModal = e)}
          onChange={() => {
            this.timeCardsTable.handleRefresh();
          }}
          breakOptions={breakOptions}
          employee={employee}
        />

        <PayRateModal
          ref={(e) => (this.payRateModal = e)}
          employee={employee}
          addState={() => {
            this.syncState();
          }}
          updateState={() => {
            this.syncState();
          }}
          rates={rates}
          showPTO={payrollGroup?.STATUS === "LIVE"}
        />

        <EmployeeTerminationsModal
          employee={employee}
          terminations={terminations}
          ref={(e) => (this.terminationsModal = e)}
        />

        <ShiftPreferencesModal
          showOneTimeUnavailability={showOneTimeUnavailability}
          ref={(e) => (this.shiftPreferencesModal = e)}
          onChange={() => this.syncState()}
        />

        <PageHeadings
          logo={getObjectImage(
            employee,
            "LOGO",
            "https://dripos-assets.s3.amazonaws.com/default-product-specials.jpg"
          )}
          label={employee.FULL_NAME}
          description={employee.ROLES.map((item) => item.ROLE_NAME).join(", ")}
          breadcrumbs={[
            {label: "Team", url: "/"},
            {label: "My Team", url: "/team"},
            {
              label: "Employees Page",
              url: "/employees",
            },
          ]}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label:
                        employee.ACTIVE === 1
                          ? "Terminate Employee"
                          : "Reinstate Employee",
                      onClick:
                        employee.ACTIVE === 1
                          ? () => this.terminateModal.open(employee)
                          : () => this.reinstateModal.open(employee),
                    },
                  ],
                },
              ],
            },
          ]}
        />

        {employee.ACTIVE === 0 && (
          <Banner
            type="error"
            className="mt-4"
            label="Employee is inactive"
            detailsLabel="Learn more"
            detailsOnClick={() => {
              this.terminationsModal.open();
            }}
            description={
              employee.FULL_NAME +
              " is inactive or terminated. Click to the right to learn more."
            }
          />
        )}

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.detailModal.open(employee),
            },
          ]}
          label="Employee Information"
          description="Information about this employee"
          data={informationArr}
        />

        <Tab className="mt-2" data={tabs}>
          {(id) => {
            if (id === "rates" && !canViewRates) {
              id = "roles";
            }

            if (id === "reports") {
              return (
                <Card
                  label="Direct Reports"
                  description={
                    "The employees that are reporting to " + employee.FULL_NAME
                  }
                >
                  <EmployeeTable data={reports} pagination striped />
                </Card>
              );
            }

            if (id === "roles") {
              return (
                <Card
                  label="Roles"
                  button={{
                    label: "Add Role",
                    onClick: () => this.roleModal.open(),
                  }}
                >
                  <RolesTable
                    pagination
                    roles={
                      roles
                        ? roles.map((item) => ({
                            EMPLOYEE_ROLE_ID: item.Employee_Roles.ID,
                            ...item.Roles,
                          }))
                        : null
                    }
                    onClick={(row) => this.roleModal.open(row)}
                  />
                </Card>
              );
            }

            if (id === "policies") {
              return (
                <Card
                  label="Policies"
                  button={{
                    label: "Add Policy",
                    onClick: () => this.policyModal.open(),
                  }}
                >
                  <Table
                    pagination
                    data={policies}
                    ref={(e) => (this.tableRef = e)}
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (row) => {
                          this.policyModal.open(row);
                        },
                      },
                    ]}
                    columns={[
                      {
                        value: "POLICY_NAME",
                        label: "Name",
                      },
                      {
                        value: "BALANCE",
                        label: "Amount",
                        format: (balance, policy) => {
                          return policy.UNLIMITED ? "Unlimited" : balance;
                        },
                      },
                      {
                        value: "TOTAL",
                        label: "Total",
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (id === "rates" && canViewRates) {
              return (
                <Card
                  label="Pay Rates"
                  buttons={[
                    {
                      label: "Add Rate",
                      onClick: () => {
                        this.payRateModal.open(null, employee);
                      },
                    },
                    {
                      theme: "gray",
                      selectable: true,
                      type: "list",
                      icon: "eye",
                      defaultValue: "active",
                      onChange: ({id: filter}) => this.setState({filter}),
                      items: [
                        {
                          id: "all",
                          label: "View all rates",
                          description: "View both active and archived pay rates.",
                        },
                        {
                          id: "active",
                          label: "Viewing active rates",
                          description: "View only active pay rates.",
                        },
                      ],
                    },
                  ]}
                >
                  <Table
                    pagination
                    columns={[
                      {
                        value: "NAME",
                        label: "Name",
                        format: (value, row) => {
                          return (
                            <div className="flex flex-row">
                              <div className="text-sm font-medium text-gray-900">
                                {value}
                              </div>

                              {payrollGroup?.STATUS === "LIVE" && row.IS_DEFAULT === 1 && (
                                <DangerBadge className={"ml-2"} blue>
                                  PTO
                                </DangerBadge>
                              )}
                            </div>
                          );
                        },
                      },
                      {
                        value: "EMPLOYEE_ROLE_NAME",
                        label: "Attached Role",
                        format: (value) => {
                          return (
                            <div className="text-sm font-medium text-gray-500">
                              {value}
                            </div>
                          );
                        },
                      },
                      {
                        value: "ROLE_NAME",
                        label: "Pay Origin",
                        format: (value) => {
                          return (
                            <div className="text-sm font-medium text-gray-500">
                              {value ? value : "Custom"}
                            </div>
                          );
                        },
                      },
                      {
                        value: "TYPE",
                        label: "Pay Type",
                        format: (_, {TYPE, CONTENT}) => {
                          if (TYPE === "TIME_RANGE") {
                            const timeSplit = CONTENT.split("|");

                            const startTime = moment()
                              .startOf("day")
                              .add(timeSplit[0], "minutes");
                            const endTime = moment()
                              .startOf("day")
                              .add(timeSplit[1], "minutes");

                            return (
                              "Hourly " +
                              startTime.format("hh:mma") +
                              " - " +
                              endTime.format("hh:mma")
                            );
                          }

                          if (TYPE === "SALARY") {
                            return "Salary";
                          }

                          return "Hourly";
                        },
                      },
                      {
                        width: 1,
                        value: "AMOUNT",
                        label: "Amount",
                        format: (value) => {
                          return "$" + toDollars(value);
                        },
                      },
                      {
                        width: 1,
                        value: "DATE_ARCHIVED",
                        label: "Status",
                        format: (value, row) => {
                          return row.DATE_ARCHIVED ? "Archived" : "Active";
                        },
                      },
                    ]}
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (row) => {
                          this.payRateModal.open(row);
                        },
                      },
                    ]}
                    data={
                      filter === "active"
                        ? rates?.filter((item) => !item.DATE_ARCHIVED)
                        : rates
                    }
                  />
                </Card>
              );
            }

            if (id === "shifts") {
              return (
                <Card
                  label="Shifts"
                  buttons={[
                    {
                      label: "Add Shift",
                      onClick: () => {
                        const start = moment().startOf("day").add(12, "h").valueOf();
                        const end = moment().startOf("day").add(13, "h").valueOf();

                        this.shiftModal.openForCreation(start, end, employee.ID, null);
                      },
                    },
                  ]}
                >
                  <Table
                    pagination
                    columns={[
                      {
                        value: "DATE_START",
                        label: "Date",
                        format: (_, row) => {
                          return (
                            <div>
                              <div className="text-sm font-medium text-gray-900">
                                {row.LOCATION_NAME}
                              </div>

                              <div className="text-sm text-gray-500">{row.ROLE_NAME}</div>
                            </div>
                          );
                        },
                      },
                      {
                        width: 1,
                        value: "DATE_START",
                        label: "Date",
                        format: (start) => {
                          return moment(start).format("ddd, MMM Do YYYY");
                        },
                      },
                      {
                        width: 1,
                        value: "DATE_START",
                        label: "Date Start",
                        format: (start) => {
                          return moment(start).format("hh:mma");
                        },
                      },
                      {
                        width: 1,
                        value: "DATE_END",
                        label: "Date End",
                        format: (start) => {
                          return moment(start).format("hh:mma");
                        },
                      },
                      {
                        width: 1,
                        value: "DATE_ARCHIVED",
                        label: "Shift Length",
                        format: (_, row) => {
                          return moment(row.DATE_END)
                            .diff(row.DATE_START, "hours", true)
                            .toFixed(2);
                        },
                      },
                    ]}
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (row) => {
                          this.shiftModal.openForEdit(row);
                        },
                      },
                    ]}
                    data={shifts}
                  />
                </Card>
              );
            }

            if (id === "cards") {
              return (
                <Card
                  label="Time Cards"
                  button={{
                    label: "Add Card",
                    onClick: () => this.timeCardModal.open(),
                  }}
                >
                  <Filter
                    defaultFilters={[]}
                    data={[
                      {
                        id: "location",
                        label: "Location",
                        onFilter: (options, data) => {
                          return data.filter((item) =>
                            options.includes(item.LOCATION_ID)
                          );
                        },
                        options: locations
                          .filter(({TYPE}) => TYPE === "STORE")
                          .map((_loc) => ({
                            id: _loc.ID,
                            label: _loc.NAME,
                          })),
                      },
                    ]}
                  >
                    {(filters, _) => {
                      return (
                        <TimeCardsTable
                          filters={filters}
                          employeeId={employee.ID}
                          onClick={(row) => this.timeCardModal.open(row)}
                          breakOptions={breakOptions}
                          ref={(e) => (this.timeCardsTable = e)}
                        />
                      );
                    }}
                  </Filter>
                </Card>
              );
            }

            if (id === "prefs") {
              return (
                <>
                  <Card
                    label="Unavailability"
                    button={{
                      label: "Add Unavailability",
                      onClick: () => this.shiftPreferencesModal.open(1, employee.ID),
                    }}
                  >
                    <Table
                      actionButtons={[
                        {
                          label: "Edit",
                          onClick: (row) =>
                            this.shiftPreferencesModal.open(row.TYPE, null, row),
                        },
                      ]}
                      pagination
                      columns={[
                        {
                          value: "ISO_DAY",
                          label: "Day",
                          format: (iso_day) => {
                            return moment()
                              .isoWeekday(iso_day + 1)
                              .format("dddd");
                          },
                        },
                        {
                          width: 1,
                          value: "DATE_START",
                          label: "Start",
                          format: (dateStart) => {
                            return dateStart === 0
                              ? "-"
                              : moment(dateStart).format("MMMM Do YYYY");
                          },
                        },
                        {
                          width: 1,
                          value: "START",
                          label: "From",
                          format: (start, row) => {
                            if (row.ALL_DAY) {
                              return "All Day";
                            }

                            return getHoursPastMidnight(row.OFFSET).format("h:mm A");
                          },
                        },
                        {
                          width: 1,
                          value: "END",
                          label: "To",
                          format: (end, row) => {
                            if (row.ALL_DAY) {
                              return "";
                            }

                            return getHoursPastMidnight(row.OFFSET + row.DURATION).format(
                              "h:mm A"
                            );
                          },
                        },
                        {
                          width: 1,
                          value: "RECURRENCE_TYPE",
                          label: "Repeats",
                          format: (type, row) => {
                            return fetchRecurrenceString(type, row.RECURRENCE_INTERVAL);
                          },
                        },
                        {
                          width: 1,
                          value: "DATE_EXPIRES",
                          label: "Until",
                          format: (dateExpires, row) => {
                            if (!dateExpires) {
                              return "-";
                            }

                            return moment(dateExpires).format("MMM Do YYYY");
                          },
                        },
                        {
                          width: 1,
                          value: "DATE_UPDATED",
                          label: "Last Updated",
                          format: (date, row) => {
                            return moment(date).format("MMM Do YYYY");
                          },
                        },
                      ]}
                      data={unavailability}
                    />
                  </Card>

                  <Card
                    label="Preferences"
                    button={{
                      label: "Add Preference",
                      onClick: () => this.shiftPreferencesModal.open(0, employee.ID),
                    }}
                  >
                    <Table
                      actionButtons={[
                        {
                          label: "Edit",
                          onClick: (row) =>
                            this.shiftPreferencesModal.open(row.TYPE, null, row),
                        },
                      ]}
                      pagination
                      columns={[
                        {
                          value: "ISO_DAY",
                          label: "Day",
                          format: (iso_day) => {
                            return moment()
                              .isoWeekday(iso_day + 1)
                              .format("dddd");
                          },
                        },
                        {
                          width: 1,
                          value: "DATE_START",
                          label: "Start",
                          format: (dateStart) => {
                            return dateStart === 0
                              ? "-"
                              : moment(dateStart).format("MMMM Do YYYY");
                          },
                        },
                        {
                          width: 1,
                          value: "START",
                          label: "From",
                          format: (start, row) => {
                            if (row.ALL_DAY) {
                              return "All Day";
                            }

                            return getHoursPastMidnight(row.OFFSET).format("h:mm A");
                          },
                        },
                        {
                          width: 1,
                          value: "END",
                          label: "To",
                          format: (end, row) => {
                            if (row.ALL_DAY) {
                              return "";
                            }

                            return getHoursPastMidnight(row.OFFSET + row.DURATION).format(
                              "h:mm A"
                            );
                          },
                        },
                        {
                          width: 1,
                          value: "RECURRENCE_TYPE",
                          label: "Repeats",
                          format: (type, row) => {
                            return fetchRecurrenceString(type, row.RECURRENCE_INTERVAL);
                          },
                        },
                        {
                          width: 1,
                          value: "DATE_EXPIRES",
                          label: "Until",
                          format: (dateExpires, row) => {
                            if (!dateExpires) {
                              return "-";
                            }

                            return moment(dateExpires).format("MMM Do YYYY");
                          },
                        },
                        {
                          width: 1,
                          value: "DATE_UPDATED",
                          label: "Last Updated",
                          format: (date, row) => {
                            return moment(date).format("MMM Do YYYY");
                          },
                        },
                      ]}
                      data={prefs}
                    />
                  </Card>
                </>
              );
            }

            if (id === "time") {
              return (
                <Card
                  label="Time Off"
                  button={{
                    label: "Add Time Off",
                    onClick: () => this.timeOffModal.open(employee.ID),
                  }}
                >
                  <Table
                    pagination
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (row) => this.timeOffModal.open(employee.ID, row),
                      },
                    ]}
                    columns={[
                      {
                        value: "DATE_START",
                        label: "From",
                        format: (start, timeOffObj) => {
                          if (timeOffObj.PARTIAL_DAYS === 1) {
                            return moment(start).format("MM/DD/YYYY hh:mma");
                          }
                          return moment(start).format("MM/DD/YYYY");
                        },
                      },
                      {
                        value: "DATE_END",
                        label: "To",
                        format: (end, timeOffObj) => {
                          if (timeOffObj.PARTIAL_DAYS === 1) {
                            return moment(end).format("MM/DD/YYYY hh:mma");
                          }
                          return moment(end).format("MM/DD/YYYY");
                        },
                      },
                      {
                        value: "POLICY_NAME",
                        label: "Policy",
                      },
                      {
                        value: "AMOUNT",
                        label: "Hours Requested",
                      },
                      {
                        value: "STATUS",
                        label: "Status",
                        format: (value) => {
                          return timeOffStatusToBadge(value);
                        },
                      },
                      {
                        value: "REVIEWER_NAME",
                        label: "Reviewer",
                        format: (value) => {
                          return value ?? "Auto Reviewed";
                        },
                      },
                      {
                        value: "NOTES",
                        label: "Notes",
                        format: (val) => {
                          return val?.length > 0 ? val : "None";
                        },
                      },
                    ]}
                    data={timeOff}
                  />
                </Card>
              );
            }

            if (id === "files") {
              return this.renderFiles();
            }

            if (id === "notes") {
              return (
                <Card
                  label="Notes"
                  buttons={[
                    {
                      label: "Create Note",
                      onClick: () => this.notesModal.open(employee),
                    },
                  ]}
                >
                  <Table
                    pagination
                    actionButtons={[
                      {
                        label: "View",
                        onClick: (row) => this.showTextModal.open(row),
                      },
                      {
                        label: "Edit",
                        onClick: (row) => this.notesModal.open(employee, row),
                      },
                    ]}
                    columns={[
                      {
                        value: "NOTE_NAME",
                        label: "Note Name",
                      },
                      {
                        value: "DATE_UPLOADED",
                        label: "Date Uploaded",
                        format: (end) => moment(parseInt(end)).format("MMM DD, h:mm A"),
                      },
                      {
                        value: "NOTE",
                        label: "Note",
                        format: (value) =>
                          value?.length > 50 ? `${value.substring(0, 50)}...` : value,
                      },
                    ]}
                    data={notes}
                  />
                </Card>
              );
            }

            return <div>Hi</div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["user", "shop"])(withRouter(EmployeePage));
