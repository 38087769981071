import React, {Component} from "react";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {
  CELL_TYPES,
  TAX_CATEGORY_COLUMNS,
} from "../../features/reporting/reports/reporting-constants";
import {Card, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../redux";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class TaxCategoryReportPage extends Component {
  convertDataToRows(reportData) {
    let rows = [];

    if (reportData) {
      let {TAX_RATES, BREAKDOWN} = reportData;

      rows = TAX_RATES.map((_rate) => ({
        label: _rate.NAME,
        numbers: {
          TAXABLE_AMOUNT: BREAKDOWN[_rate.ID + ""]?.TAXABLE_AMOUNT,
          TAX_RATE: _rate.RATE / 100,
          TAX_COLLECTED: BREAKDOWN[_rate.ID + ""]?.AMOUNT_TAX,
        },
        rowType: CELL_TYPES.REGULAR,
      }));

      rows.push({
        label: "Total",
        numbers: {
          TAXABLE_AMOUNT: null,
          TAX_RATE: null,
          TAX_COLLECTED: BREAKDOWN["TOTAL"],
        },
        rowType: CELL_TYPES.REGULAR,
      });
    }

    return rows;
  }

  render() {
    let {REPORTING_MODE, REPORTING_SHOW_LOCATION_CSV, REPORTING_SHOW_DATE_CSV} =
      this.props.shop.settings;

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Taxable Sales Report"} />
        </div>

        <FixedColumnReport
          filenamePrefix="taxes"
          endpoint="report/tax-categories"
          constantColumns={TAX_CATEGORY_COLUMNS}
          convertDataToRows={this.convertDataToRows}
          locationPicker={true}
          showLocationsInDownload={REPORTING_SHOW_LOCATION_CSV === "1"}
          showDateRangeInDownload={REPORTING_SHOW_DATE_CSV === "1"}
          alwaysShowCustomTable={true}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["reporting", "shop"])(TaxCategoryReportPage);
