import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import {
  Loading,
  PageHeadings,
  Tab,
  Tooltip,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import ItemsTab from "../../features/dashboard/items-tab";
import {PERMISSIONS} from "../../utils/constants";
import {
  setupMarketing,
  setupOnboarding,
  setupOperations,
  setupPayroll,
  setupTeam,
} from "../../utils/dashboard-helper";
import {ArrowSmDownIcon, ArrowSmUpIcon} from "@heroicons/react/outline";
import {
  classNames,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment-timezone";
import {formatDateForDayOfWeekDisplay} from "../../utils/payroll-helper";
import EmailModal from "../../modals/general/email-modal";
import {XCircleIcon} from "@heroicons/react/solid";

class Dashboard extends Component {
  state = {
    isLoading: true,
    salesNumbers: null,
    teamItems: null,
    teamBadge: 0,
    marketingItems: null,
    marketingBadge: 0,
    onboardingItems: null,
    onboardingBadge: 0,
    operationsItems: null,
    operationsBadge: 0,
    payrollItems: null,
    currentPayrun: null,
    payrollBadge: 0,
    stripeDeadline: null,
    address: null,
  };

  async componentDidMount() {
    let {location} = this.props.shop;

    await this.syncState();

    if (location.TYPE === "PAYROLL") {
      this.props.router.navigate("/payroll");
    }
  }

  syncState = async () => {
    let {UNIQUE_ID, CHECK_COMPANY_ID} = this.props.shop.location;

    let {SALES, TEAM, MARKETING, ONBOARDING, OPERATIONS, PAYROLL} = await request(
      "dashboard/checklists/" + UNIQUE_ID,
      "GET"
    );

    let {teamItems, teamBadge} = setupTeam(TEAM);
    let {marketingItems, marketingBadge} = setupMarketing(MARKETING);
    let {onboardingItems, onboardingBadge} = setupOnboarding(ONBOARDING, this.syncState);
    let {operationsItems, operationsBadge} = setupOperations(OPERATIONS);

    let payrollItems = {},
      payrollBadge = 0;

    if (CHECK_COMPANY_ID) {
      let {payrollItems: payrollItemsObj, payrollBadge: payrollBadgeCount} =
        setupPayroll(PAYROLL);

      payrollItems = payrollItemsObj;
      payrollBadge = payrollBadgeCount;
    }

    this.setState({
      salesNumbers: SALES,
      teamItems,
      teamBadge,
      marketingItems,
      marketingBadge,
      onboardingItems,
      onboardingBadge,
      operationsItems,
      operationsBadge,
      payrollItems,
      payrollBadge,
      currentPayrun: PAYROLL?.CURRENT_PAYRUN,
      stripeRequirements: ONBOARDING?.STRIPE_REQUIREMENTS,
      isLoading: false,
      address: ONBOARDING?.BILLING_ADDRESS,
    });
  };

  userHasPermission(permissions = []) {
    const {permissions: userPermissions} = this.props.user;

    if (userPermissions.includes(PERMISSIONS.ADMIN.FULL.id)) {
      return true;
    }

    for (const perm of permissions) {
      if (userPermissions.includes(perm)) {
        return true;
      }
    }
    return false;
  }

  getPayrollStat() {
    let {CHECK_COMPANY_ID} = this.props.shop.location;

    if (!CHECK_COMPANY_ID) {
      return {
        name: "End of Pay Period",
        stat: "Use Payroll to Track",
        change: false,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id, PERMISSIONS.FINANCE.FULL.id],
      };
    }

    if (!this.state.currentPayrun) {
      return {
        name: "Payroll Not Run",
        stat: "First payroll has run",
        change: false,
        permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id, PERMISSIONS.FINANCE.FULL.id],
      };
    }

    let {PERIOD_END, APPROVAL_DEADLINE, PAYROLL_STATUS, PAYDAY} =
      this.state.currentPayrun;

    let endOfPeriodDaysAhead = moment(PERIOD_END).diff(moment().startOf("day"), "days");
    let approvalDaysAhead = moment(APPROVAL_DEADLINE).diff(
      moment().startOf("day"),
      "days"
    );

    let endOfPeriodSecondary = `In ${endOfPeriodDaysAhead} days`;
    let needBy = moment(1632182400000).format("h:mm A");

    let name, stat, secondary;

    //period_open, needs_run, missed, pending, processing, paid

    if (!this.state.currentPayrun) {
      name = "Run Payroll";
      stat = "Payrun Notifications Will Appear Here";
    } else if (PAYROLL_STATUS === "approved" || PAYROLL_STATUS === "paid") {
      name = "Run Payroll";
      stat = "Completed";
    } else if (PAYROLL_STATUS === "period_open") {
      name = "End of Pay Period";

      stat = formatDateForDayOfWeekDisplay(PERIOD_END);
      secondary = endOfPeriodDaysAhead > 1 ? endOfPeriodSecondary : "";
    } else if (PAYROLL_STATUS === "needs_run") {
      name = "Run Payroll";
      stat = `By ${moment(APPROVAL_DEADLINE).format("h:mm A")} on ${moment(
        APPROVAL_DEADLINE
      ).format("dddd")}`;
    }

    return {
      name,
      stat,
      secondary,
      change: false,
      permissions: [PERMISSIONS.FINANCE.PAYROLL_FULL.id, PERMISSIONS.FINANCE.FULL.id],
    };
  }

  renderTooltip(yesterdayNumber, lastWeekNumber) {
    return (
      <div className="w-full flex-1">
        <div className="flex flex-col">
          <div>Yesterday: {yesterdayNumber}</div>
          <div>
            {`Last ${moment().subtract(1, "day").format("dddd")}`}: {lastWeekNumber}
          </div>
        </div>
      </div>
    );
  }

  renderSales() {
    let {
      YESTERDAY_GROSS_SALES,
      ONE_WEEK_AGO_GROSS_SALES,
      YESTERDAY_LABOR_SALES,
      ONE_WEEK_AGO_LABOR_SALES,
    } = this.state.salesNumbers;

    let isSalesIncrease = YESTERDAY_GROSS_SALES > ONE_WEEK_AGO_GROSS_SALES;
    let isLaborSalesIncrease = YESTERDAY_LABOR_SALES > ONE_WEEK_AGO_LABOR_SALES;

    let salesChange, yesterdaySales, yesterdayLaborSales, laborSalesChange;

    if (!YESTERDAY_GROSS_SALES) {
      yesterdaySales = "No Sales";
      salesChange = "";
    } else {
      yesterdaySales = toDollars(YESTERDAY_GROSS_SALES, true);
      salesChange = `${(
        ((YESTERDAY_GROSS_SALES - ONE_WEEK_AGO_GROSS_SALES) / ONE_WEEK_AGO_GROSS_SALES) *
        100
      )?.toFixed(1)}%`;
    }

    if (!YESTERDAY_LABOR_SALES) {
      yesterdayLaborSales = "Use Pay Rates to See Insight";
      laborSalesChange = "";
    } else {
      let laborSalesDiff = Math.abs(YESTERDAY_LABOR_SALES - ONE_WEEK_AGO_LABOR_SALES);
      yesterdayLaborSales = `${YESTERDAY_LABOR_SALES?.toFixed(1)}%`;
      laborSalesChange = `${laborSalesDiff?.toFixed(1)}%`;
    }

    let stats = [
      {
        name: `Yesterday's Sales`,
        stat: yesterdaySales,
        previousStat: toDollars(ONE_WEEK_AGO_GROSS_SALES, true),
        change: salesChange,
        numberIncrease: isSalesIncrease,
        good: isSalesIncrease,
        permissions: [PERMISSIONS.REPORTS.SALES_REPORT.id, PERMISSIONS.REPORTS.FULL.id],
      },
      {
        name: `Yesterday's Labor/Cost %`,
        stat: yesterdayLaborSales,
        previousStat: `${ONE_WEEK_AGO_LABOR_SALES?.toFixed(1)}%`,
        change: laborSalesChange,
        numberIncrease: isLaborSalesIncrease,
        good: !isLaborSalesIncrease,
        permissions: [PERMISSIONS.REPORTS.LABOR_VS_SALES.id, PERMISSIONS.REPORTS.FULL.id],
      },
    ];

    stats.push(this.getPayrollStat());

    stats = stats.filter((item) => this.userHasPermission(item?.permissions));

    return (
      <div className="py-4">
        <dl
          className={`grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-${
            stats.length || 1
          } md:divide-y-0 md:divide-x lg:grid-cols-${stats.length || 1}`}
        >
          {" "}
          {stats.map((item) => (
            <div key={item.name} className="px-4 py-3">
              <dt className="text-sm font-normal text-gray-600">{item.name}</dt>
              <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="flex flex-row items-baseline">
                  <div className="flex items-baseline text-lg font-semibold text-indigo-600">
                    {item.stat}
                  </div>
                  <div className="text-sm ml-2 text-gray-500">{item.secondary}</div>
                </div>

                {item.change && (
                  <div className="flex flex-row items-center">
                    <div
                      className={classNames(
                        item.good && "bg-green-100 text-green-800",
                        !item.good && "bg-red-100 text-red-800",
                        "inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0"
                      )}
                    >
                      {item.numberIncrease ? (
                        <ArrowSmUpIcon
                          className={classNames(
                            item.good && "text-green-500",
                            !item.good && "text-red-500",
                            "-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5"
                          )}
                          aria-hidden="true"
                        />
                      ) : (
                        <ArrowSmDownIcon
                          className={classNames(
                            item.good && "text-green-500",
                            !item.good && "text-red-500",
                            "-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5"
                          )}
                          aria-hidden="true"
                        />
                      )}

                      <span className="sr-only">
                        {item.changeType === "increase" ? "Increased" : "Decreased"} by
                      </span>
                      {item.change}
                    </div>

                    <Tooltip
                      className="ml-2"
                      label={this.renderTooltip(item.stat, item.previousStat)}
                    />
                  </div>
                )}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    );
  }

  renderBillingAddressAlert() {
    const {address} = this.state;

    if (address) {
      return <></>;
    }

    return (
      <div className="rounded-md bg-red-50 p-4 my-4">
        <div className="flex">
          <div className="flex-shrink-0"></div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Billing Address Required</h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                Click{" "}
                <span
                  class="underline font-bold cursor-pointer"
                  onClick={() => this.props.router.navigate("/billing?tab=address")}
                >
                  here
                </span>{" "}
                to fill out the required billing information{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPayoutAlert() {
    const {stripeRequirements} = this.state;

    if (
      stripeRequirements?.CURRENT_REQUIREMENT_COUNT +
        stripeRequirements?.FUTURE_REQUIREMENT_COUNT ===
      0
    ) {
      return <div></div>;
    }

    if (!stripeRequirements?.PAYOUTS_ENABLED) {
      return (
        <div className="rounded-md bg-red-50 p-4 shadow-sm">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Payouts have been disabled
              </h3>
              <div className="mt-2 text-sm text-red-700">
                <ul role="list" className="list-disc space-y-1 pl-5">
                  <li>
                    Please go to Payouts Settings to fill out the required information
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0"></div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">
              Payouts will be disabled soon
            </h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>Please go to Payouts Settings to fill out the required information</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let {FULL_NAME} = this.props.user.account;
    const {account} = this.props.user;
    let {NAME, CHECK_COMPANY_ID} = this.props.shop.location;
    let {router} = this.props;
    let {
      isLoading,
      teamBadge,
      marketingBadge,
      operationsBadge,
      onboardingBadge,
      payrollBadge,
    } = this.state;

    if (isLoading) {
      return <Loading />;
    }

    let timeOfDay = "evening";
    let hours = moment().hours();

    if (hours < 12) {
      timeOfDay = "morning";
    } else if (hours < 18) {
      timeOfDay = "afternoon";
    }

    let firstName = FULL_NAME.split(" ")[0];

    let data = [
      {
        id: "team",
        label: "Team",
        badge: teamBadge,
        permissions: [PERMISSIONS.TEAM.FULL.id],
      },
      {
        id: "marketing",
        label: "Marketing",
        badge: marketingBadge,
        permissions: [PERMISSIONS.MARKETING.FULL.id],
      },
      {
        id: "operations",
        label: "Operations",
        badge: operationsBadge,
        permissions: [PERMISSIONS.OPERATIONS.FULL.id],
      },
      {
        id: "admin",
        label: "Admin",
        permissions: [PERMISSIONS.ADMIN.FULL.id],
      },
    ];

    if (onboardingBadge > 0) {
      data.unshift({
        id: "onboarding",
        label: "Onboarding",
        badge: onboardingBadge,
        permissions: [PERMISSIONS.ADMIN.FULL.id],
      });
    }

    if (CHECK_COMPANY_ID) {
      data.push({
        id: "payroll",
        label: "Payroll",
        permissions: [PERMISSIONS.FINANCE.FULL.id],
        badge: payrollBadge,
      });
    }

    data = data.filter((item) => this.userHasPermission(item?.permissions));

    return (
      <div className="w-full lg:w-3/4 px-5 lg:px-0 m-auto mt-10">
        <EmailModal
          ref={(e) => (this.emailModal = e)}
          refresh={(EMAIL) => {
            this.props.updateUserAccount({...account, EMAIL});
          }}
          account={account}
        />

        <PageHeadings
          className="py-4"
          label={`Good ${timeOfDay}, ${firstName}`}
          description={"Here's what's going on at " + NAME}
        />

        <div className="mt-6">
          {this.renderSales(data)}

          {this.renderPayoutAlert()}

          {this.renderBillingAddressAlert()}

          <div className="font-semibold text-gray-700 mt-6 text-lg">Things to do</div>
          <Tab data={data} className={"mt-2"}>
            {(id) => {
              let {
                marketingItems,
                teamItems,
                operationsItems,
                onboardingItems,
                payrollItems,
              } = this.state;

              let items = [];

              if (id === "marketing") {
                items = marketingItems;
              }

              if (id === "team") {
                items = teamItems;
              }

              if (id === "operations") {
                items = operationsItems;
              }

              if (id === "onboarding") {
                items = onboardingItems;
              }

              if (id === "payroll") {
                items = payrollItems;
              }

              if (items.length === 0) {
                return (
                  <div
                    className={"text-gray-600 font-semibold text-lg text-center mt-16"}
                  >
                    No {id?.charAt(0).toUpperCase() + id?.slice(1) || ""} Tasks
                  </div>
                );
              }

              return (
                <ItemsTab
                  items={items}
                  router={router}
                  updateSum={(sum) => {
                    this.setState({teamBadge: sum});
                  }}
                />
              );
            }}
          </Tab>
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["user", "shop"])(withRouter(Dashboard));
