import React, {Component} from "react";
import PercentageBadge from "../../percentage-badge";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class ReportingToolTip extends Component {
  render() {
    let {formatLabel, payload, rows, datasets} = this.props;

    if (!payload || !payload[0]) {
      return <div></div>;
    }

    payload = payload[0].payload;

    return (
      <div
        className={
          "bg-white border border-solid border-gray-300 p-2 rounded-sm"
        }
      >
        <div className={"flex flex-row justify-between"}>
          <div className={"font-semibold text-sm"}>{formatLabel(payload)}</div>

          {datasets?.length === 2 && (
            <PercentageBadge
              current={payload.baseValue}
              past={payload.compareValue}
            />
          )}
        </div>
        <div className="w-full my-2 h-0.5 bg-gray-300" />
        <div className={"flex flex-col space-y-2"}>
          {rows.map(({label, value, formatValue, id, stroke, stopNow}) => {
            return (
              <div
                style={{minWidth: 150}}
                className={"w-44 flex flex-row items-center justify-between"}
              >
                <div className={"flex flex-row items-center"}>
                  {stroke && (
                    <div
                      className={"h-3 w-3 mr-1"}
                      style={{backgroundColor: stroke}}
                    />
                  )}

                  <div className={"text-xs text-gray-700 font-semibold"}>
                    {label}
                  </div>
                </div>

                <div className={"text-xs font-semibold"}>
                  {formatValue ? formatValue(payload) : value}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ReportingToolTip;
