import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import UnitDropdown from "../../../../dropdowns/operations/recipes/unit-dropdown";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../../utils/notification-helper";
import {getStore} from "../../../../redux";
import {updateIngredient} from "../../../../redux/supply";
import {StockRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";

class StockIngredientModal extends Component {
  state = {ingredient: null};

  open(ingredient = null) {
    this.setState({ingredient}, () => this.modal.open());
  }

  fetchInitialValues() {
    return {
      quantity: 0,
      unit: null,
    };
  }

  fetchValidationSchema() {
    return Yup.object().shape({
      quantity: Yup.number()
        .nullable()
        .required("Please enter the quantity you wish to stock."),
      unit: Yup.number().nullable().required("Please select a unit."),
    });
  }

  onRecipeStockSuccess(payload) {
    const {ingredient} = this.state;
    const {QUANTITY: quantity} = payload;

    showSuccessNotification(
      "Successfully stocked " + ingredient.NAME + ".",
      "Stocked " + quantity + " of " + ingredient.NAME
    );

    return getStore().dispatch(updateIngredient(ingredient.ID));
  }

  onRecipeStockFailure(err) {
    const {ingredient} = this.state;

    if (err.error === "OUT_OF_STOCK") {
      showErrorNotification(
        "Ingredient out of Stock.",
        "One or more ingredients is out of stock."
      );
    } else {
      showErrorNotification(
        "Unknown error occurred.",
        "Error stocking " + ingredient.NAME + ". Please try again."
      );
    }
  }

  fetchRecipeStockPayload(values) {
    const {quantity, unit} = values;
    return {QUANTITY: quantity, UNIT_ID: unit};
  }

  async requestRecipeStock(recipeStockPayload) {
    const {ingredient} = this.state;

    try {
      await StockRequests.executeRecipe(ingredient.ID, recipeStockPayload);
      this.onRecipeStockSuccess(recipeStockPayload);
    } catch (e) {
      this.onRecipeStockFailure(e);
    } finally {
      this.modal.close();
    }
  }

  submitRecipeStocking(values) {
    const recipePayload = this.fetchRecipeStockPayload(values);

    return this.requestRecipeStock(recipePayload);
  }

  renderQuantityInput(formikOptions) {
    return (
      <FormInput
        name="quantity"
        label="Quantity"
        options={formikOptions}
        tooltip={`The number of the selected unit being stocked.`}
      />
    );
  }

  renderUnitDropdown(formikOptions) {
    const {ingredient} = this.state;

    return (
      <UnitDropdown
        name="unit"
        label="Unit"
        ingredient={ingredient}
        tooltip="The unit you wish to use"
        options={formikOptions}
        unit={ingredient.UNIT_ID}
      />
    );
  }

  renderFormBody(formikOptions) {
    return (
      <form>
        {this.renderQuantityInput(formikOptions)}
        {this.renderUnitDropdown(formikOptions)}
      </form>
    );
  }

  renderForm() {
    return (
      <Formik
        innerRef={(e) => (this.formikRef = e)}
        initialValues={this.fetchInitialValues()}
        validationSchema={this.fetchValidationSchema()}
        onSubmit={(values) => this.submitRecipeStocking(values)}
      >
        {(formikOptions) => {
          return this.renderFormBody(formikOptions);
        }}
      </Formik>
    );
  }

  render() {
    const {ingredient} = this.state;

    return (
      <Modal
        label={`Stock ${ingredient?.NAME}`}
        buttonLabel="Stock"
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        {this.renderForm()}
      </Modal>
    );
  }
}

export default StockIngredientModal;
