import React, {Component} from "react";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {CUSTOMIZATION_TYPES} from "@frostbyte-technologies/frostbyte-tickets/dist/constants/product-constants";
import {Tab} from "@frostbyte-technologies/frostbyte-tailwind";

class Product extends Component {
  state = {modifier: null};

  renderModifier(modifier) {
    const {selectedOption, selectedVariant} = this.props;

    const selected =
      modifier.TYPE === CUSTOMIZATION_TYPES.VARIANT ? selectedVariant : selectedOption;

    return (
      <div className="mt-4">
        <div value={selectedOption}>
          <div className="grid-cols-3 grid items-start">
            {modifier.OPTIONS.map((option) => {
              const active = option.ID === selected?.ID;

              return (
                <div
                  key={option.NAME}
                  onClick={() => {
                    if (modifier.TYPE === CUSTOMIZATION_TYPES.VARIANT) {
                      this.props.onVariantChange(option);
                    } else {
                      this.props.onOptionChange(option);
                    }
                  }}
                  className={classNames(
                    active ? "ring-2 ring-indigo-500" : "",
                    "flex mb-4 mr-10 relative w-40 h-20 align-middle justify-center cursor-pointer rounded-lg border border-gray-300 p-4 focus:outline-none"
                  )}
                >
                  <>
                    <div
                      as="p"
                      className="flex text-base font-medium align-middle items-center text-center text-gray-900"
                    >
                      {option.NAME}
                    </div>

                    <div
                      className={classNames(
                        active ? "border" : "border-2",
                        active ? "border-indigo-500" : "border-transparent",
                        "pointer-events-none absolute -inset-px rounded-lg"
                      )}
                      aria-hidden="true"
                    />
                  </>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {modifier} = this.state;
    const {product} = this.props;

    const variant = product.CUSTOMIZATIONS.find(
      (modifier) => modifier.TYPE === CUSTOMIZATION_TYPES.VARIANT
    );

    return (
      <div>
        <div className="mx-auto max-w-2xl px-4 pb-24 sm:px-6 sm:pb-32 pt-12 lg:max-w-7xl lg:gap-x-8 lg:px-8">
          <div className="lg:max-w-lg lg:self-end">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {product.NAME}
            </h1>
          </div>

          <div className="mt-4 flex">
            <section aria-labelledby="options-heading">
              <h2 id="options-heading" className="sr-only">
                Product options
              </h2>

              <form className="flex flex-col ">
                {!!variant && this.renderModifier(variant)}

                <Tab
                  data={product.CUSTOMIZATIONS.filter(
                    (_modifier) => _modifier.TYPE !== CUSTOMIZATION_TYPES.VARIANT
                  ).map((_modifier) => ({
                    id: _modifier.ID,
                    label: _modifier.NAME,
                  }))}
                >
                  {(tab) => {
                    const modifier = product.CUSTOMIZATIONS.find((c) => c.ID === tab);

                    return !!modifier && this.renderModifier(modifier);
                  }}
                </Tab>
              </form>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default Product;
