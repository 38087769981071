import React, {Component} from "react";
import {Filter, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment";
import {setupReduxConnection} from "../../../redux";
import {INGREDIENT_TYPES} from "../../../modals/operations/supply-chain/recipes/ingredient-modal";

class SupplyChainInventoryTable extends Component {
    fetchNameColumn() {
        return {width: 1, value: "NAME", label: "Name"};
    }

    fetchCurrentStockColumn() {
        return {
            width: 1,
            value: "DISPLAY_STOCK",
            label: "Current Stock",
            format: (val, row) => {
                return `${val} ${row.DISPLAY_UNIT.NAME}`;
            },
        };
    }

    fetchCategoriesColumn() {
        return {
            width: 1,
            value: "CATEGORIES",
            sortable: false,
            label: "Groups",
            format: (categories) => {
                return categories.length === 0
                    ? "-"
                    : categories.map((category) => category.NAME).join(", ");
            },
        };
    }

    fetchCreatedColumn() {
        return {
            width: 1,
            value: "DATE_CREATED",
            label: "Created On",
            format: (val) => moment(val).format("MMM Do hh:mmA"),
        };
    }

    fetchTypeColumn() {
        return {
            width: 1,
            value: "TYPE",
            label: "Type",
            format: (val) => INGREDIENT_TYPES[val],
        }
    }

    fetchColumns() {
        return [
            this.fetchNameColumn(),
            this.fetchCurrentStockColumn(),
            this.fetchCategoriesColumn(),
            this.fetchCreatedColumn(),
            this.fetchTypeColumn()
        ];
    }

    fetchCsvFormat() {
        return {
            file: "ingredients",
            headers: [
                {label: "Name", key: "NAME"},
                {label: "Current Stock", key: "CURRENT_STOCK"},
                {label: "Created", key: "DATE_CREATED", type: "datetime"},
            ],
        };
    }

    filterCategory(options, data) {
        if (options.length === 0) {
            return data;
        }

        return data.filter((item) => {
            return item.CATEGORIES.find((c) => options.includes(c.ID));
        });
    }

    filterType(options, data) {
        if (options.length === 0) {
            return data;
        }

        return data.filter((item) => {
            return options.includes(item.TYPE);
        });
    }

    fetchFilters() {
        const {categories} = this.props.supply;

        return [
            {
                id: "category",
                label: "Groups",
                onFilter: (options, data) => this.filterCategory(options, data),
                options: categories.map((item) => ({
                    id: item.ID,
                    label: item.NAME,
                    value: item.ID,
                })),
            },
            {
                id: "type",
                label: "Type",
                onFilter: (options, data) => this.filterType(options, data),
                options: Object.values(INGREDIENT_TYPES).map((item) => ({
                    id: item,
                    label: item,
                    value: item
                }))
            }
        ];
    }

    render() {
        return (
            <Filter
                csv={this.fetchCsvFormat()}
                searchable
                searchParams
                data={this.fetchFilters()}
            >
                {(filters, search) => {
                    return (
                        <Table
                            sorting
                            pagination
                            searchParams
                            searchFields={["NAME"]}
                            search={search}
                            filters={filters}
                            rowsPerPage
                            defaultSort="DATE_CREATED"
                            ref={(e) => (this.tableRef = e)}
                            columns={this.fetchColumns()}
                            {...this.props}
                        />
                    );
                }}
            </Filter>
        );
    }
}

export default setupReduxConnection(["supply"])(withRouter(SupplyChainInventoryTable));
