import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import React, {Component} from "react";
import SingleReportingTooltip from "./tooltips/single-reporting-tooltip";
import {StripedMask} from "../../accounting/main-blocks/profit-loss-block";

class ReportingProductProfitabilityGraph extends Component {
  getLeftMargin = () => {
    const {data} = this.props;

    return (
      data.reduce(
        (mostCharacters, value) =>
          Math.max(mostCharacters, this.yTickFormatter(value).length),
        0
      ) * LEFT_MARGIN_MULTIPLIER
    );
  };

  yTickFormatter = (value) => Math.round(value) + "%";

  render() {
    const {data} = this.props;

    const allIngredients = [];

    const graphData = data.map(
      ({COST, DAY, HOUR, LINE_ITEM_NAME, TOTAL, INGREDIENTS, QUANTITY}) => {
        const dict = {
          day: DAY,
          dayAbbreviation: HOUR,
          lineItemName: LINE_ITEM_NAME,
          grossSales: Math.round(TOTAL / QUANTITY),
          grossMargin: Math.round((TOTAL - COST) / QUANTITY),
          grossMarginPercentage: Math.round(
            (Math.round((TOTAL - COST) / QUANTITY) / Math.round(TOTAL / QUANTITY)) * 100
          ),
          costOfGoods: Math.round(COST / QUANTITY),
          costOfGoodsPercentage:
            100 -
            Math.round(
              (Math.round((TOTAL - COST) / QUANTITY) / Math.round(TOTAL / QUANTITY)) * 100
            ),
        };

        return dict;
      }
    );

    return (
      <div>
        <ResponsiveContainer width="100%" height={150}>
          <BarChart data={graphData} margin={{left: this.getLeftMargin(), top: 10}}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />

            {/*<Bar dataKey="grossSales" fill="#4e46e5" barSize={20} label="Average Sale" />*/}

            <pattern
              id="pattern-stripe"
              width="8"
              height="8"
              patternUnits="userSpaceOnUse"
              patternTransform="rotate(45)"
            >
              <rect width="4" height="8" transform="translate(0,0)" fill="white"></rect>
            </pattern>

            <mask id="mask-stripe">
              <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe)" />
            </mask>

            <Bar
              dataKey="costOfGoodsPercentage"
              fill="#4e46e5"
              barSize={20}
              label="Average Cost of Goods"
              stackId="a"
              shape={<StripedMask fill="#4e46e5" />}
            />

            <Bar
              dataKey="grossMarginPercentage"
              fill="#4e46e5"
              barSize={20}
              label="Average Gross Margin"
              stackId="a"
            />

            <XAxis
              dataKey="lineItemName"
              axisLine={{stroke: "#cccccc"}}
              tickMargin={10}
              tickLine={{stroke: "#cccccc"}}
              tick={{fontSize: "0.75rem", fontWeight: "bold"}}
            />

            <YAxis
              tick={{fontSize: "0.75rem", fontWeight: "bold"}}
              tickMargin={10}
              tickFormatter={this.yTickFormatter}
              axisLine={{stroke: "#cccccc"}}
              tickLine={{stroke: "#cccccc"}}
              tickCount={3}
            />

            <Tooltip
              cursor={false}
              offset={40}
              content={
                <SingleReportingTooltip
                  formatLabel={(payload) => {
                    return payload.lineItemName;
                  }}
                  rows={[
                    {
                      label: "Average Sale",
                      formatValue: (payload) => {
                        return toDollars(payload.grossSales, true);
                      },
                    },
                    {
                      label: "Average Cost of Goods",
                      formatValue: (payload) => {
                        return toDollars(payload.costOfGoods, true);
                      },
                    },
                    {
                      label: "Average Gross Margin",
                      formatValue: (payload) => {
                        return toDollars(payload.grossMargin, true);
                      },
                    },
                  ]}
                />
              }
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

const LEFT_MARGIN_MULTIPLIER = 5;

export default ReportingProductProfitabilityGraph;
